import { AppService } from './../services/app.service';
import { ProjetoService } from './../services/projeto.service';
import { ModulosService } from './../services/modulos.service';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { DataService } from '../services/data.service';
import { StorageService } from '../services/storage.service';

@Injectable()
export class ModulosResolver implements Resolve<any> {
    
    private title;
    public data = null;

    constructor(
        private DataService:DataService,
        private ModulosService: ModulosService,
        private AppService: AppService
    ){

        this.onData();

    }
    /**
     * 
     * Ouvintes
     * 
     */
    onData(){
              
        this.ModulosService.getModulosEmitter.subscribe(d =>{
            this.ModulosService.updateModulosEmitter.emit(this.data);
        });

    }  
    /**
     * 
     * Resolve
     * 
     * @param route 
     * @param state 
     * 
     */
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
        ): Observable<any>|Promise<any>|any {
           
            let modulos      = this.DataService.getModulos();
            let configuracao = this.DataService.getConfiguracao();
            
            if(this.data == null){ 

                return this.ModulosService.getModulos().subscribe(response =>{
                    
                    if(response.status == 1){
                        this.data = response.data;
                        this.DataService.setModulos(response.data);
                        this.DataService.setTotalCart(response.totalCart);
                        this.DataService.setConfiguracao(response.configuracao);
                        this.DataService.setCategorias(response.categorias);
                        this.DataService.setComboProdutos(response.combo_produtos);
                        this.AppService.setMetaDadosInit(response.configuracao);
                        this.DataService.setRegiaoMenu(response.regiao_menu);
                        this.AppService.setStatusServidorEmitter.emit(1);
                    }
                    
                    this.AppService.emitChangePage.emit(route.params);

                    return response;
                   
                }, (err) => {
                    this.AppService.setStatusServidorEmitter.emit(500);
                });

           }else{
               this.ModulosService.updateModulosEmitter.emit(this.data);
               this.AppService.setMetaDadosInit(configuracao);
               this.AppService.setStatusServidorEmitter.emit(1);
               this.AppService.emitChangePage.emit(route.params);
               return this.data
           }
    }

 }
