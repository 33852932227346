<div class="cart-item">
  <div class="cart-item-content">
    <div class="nome">
      <h3>{{item.nome}}</h3>
    </div>  
    <div class="content">
      <div class="row">
        <div class="col-md-4">
          <div class="item">
            <span class="text">REGIÃO</span>
            <span class="value">{{item.macro_regiao}}</span>
          </div>  
          <div class="item">
            <span class="text">TIPO DO SERVIÇO</span>
            <span class="value">{{item.tipo_servico_id | tipoServico}}</span>
          </div>
          <div class="item">
            <span class="text">HOTEL</span>
            <span class="value">{{item.nome_hotel}}</span>
          </div> 
          <div class="item data-servico">
            <span class="text">DATA DO SERVIÇO</span>
            <span class="value">{{item.data_utilizacao | date: "dd-MM-yyyy"}}</span>
          </div> 
        </div> 
        <div class="col-md-4">
          <div class="totais" *ngIf="item.tipo_servico_id == 1">
            <div class="text">  
              <span>Adultos: {{item.valor_adt | currency:"R$ "}} x {{item.qtd_adt}} = </span> <strong>{{(item.valor_adt*item.qtd_adt) | currency:"R$ "}}</strong><br>
              <span>Melhor Idade: {{item.valor_snr | currency:"R$ "}} x {{item.qtd_snr}} = </span> <strong>{{(item.valor_snr*item.qtd_snr) | currency:"R$ "}}</strong><br>
              <span>Criança Pagante: {{item.valor_chd | currency:"R$ "}} x {{item.qtd_chd}} = </span> <strong>{{(item.valor_chd*item.qtd_chd) | currency:"R$ "}}</strong><br>
              <span>Criança Cortesia: </span><strong>{{item.qtd_inf}}</strong><br>
              <div class="total">
                <span>TOTAL = </span><strong>{{(item.valor_snr*item.qtd_snr)+(item.valor_adt*item.qtd_adt)+(item.valor_chd*item.qtd_chd) | currency: "R$ "}}</strong>
              </div>  
            </div>  
          </div> 
          <div class="totais" *ngIf="item.tipo_servico_id == 2">
            <div class="text">  
              <span>Adultos: </span> <strong>{{item.qtd_adt}}</strong><br>
              <span>Melhor Idade: </span> <strong>{{item.qtd_snr}}</strong><br>
              <span>Criança Pagante: </span> <strong>{{item.qtd_chd}}</strong><br>
              <span>Criança Cortesia: </span><strong>{{item.qtd_inf}}</strong><br>
              <div class="total">
                <span>TOTAL SERVIÇO = </span><strong>{{item.valor_servico | currency: "R$ "}}</strong> 
              </div>  
            </div>  
          </div> 
        </div>
        <div class="col-md-4">
          <btn-delete-cart-item [id]="item.id" [nome]="item.nome"></btn-delete-cart-item>
        </div>   
      </div>  
    </div>  
  </div>  
</div>
