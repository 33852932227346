import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

declare var $:any;

@Component({
  selector: 'dm-cart-add-success-modal',
  templateUrl: './dm-cart-add-success-modal.component.html',
  styleUrls: ['./dm-cart-add-success-modal.component.scss']
})
export class DmCartAddSuccessModalComponent implements OnInit {

  @Input("title")     title         = "Seviço adicionado ao Carrinho";
  @Input("show")      show          = false;
  @Output("hide")     hide          = new EventEmitter();
  @ViewChild("modal") modal:ElementRef;
  @Input("nome")      nome          = "";

  constructor(
    private router: Router
  ){ }
  
  /**
   * 
   * Fecha o modal
   * 
   */
  hideModal(){
    this.hide.emit();
    $(this.modal.nativeElement).modal("hide");
  }
  /**
   * 
   * Abre o Modal
   * 
   */
  showModal(){
    $(this.modal.nativeElement).modal("show");
  }
  /**
   * 
   * To Cart
   * 
   */
  toCart(){

    this.router.navigateByUrl("/carrinho");
    this.hideModal();

  }
  /**
   * 
   * To Cart
   * 
   */
  toHome(){

    this.router.navigateByUrl("/");
    this.hideModal();

  }
  /**
   * 
   * To Cart
   * 
   */
  toFinish(){

    this.router.navigateByUrl("/carrinho/finalizar-compra");
    this.hideModal();

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    
  }
  /**
   * 
   * Verifica a alteração de estado das variáveis
   * 
   * @param changes 
   * 
   */
  ngOnChanges(changes:any): void {
    
    if(changes.show){

      if(changes.show.currentValue == true || changes.show.currentValue == 'true'){
        this.showModal();
      }

    }

  }

}
