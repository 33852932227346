import { AppService } from './services/app.service';
import { Router, NavigationStart, NavigationEnd, Event as NavigationEvent } from '@angular/router';
import { Component, Input, OnInit, ElementRef } from '@angular/core';
import { DataService } from './services/data.service';

var base64 = require('base-64');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  

  public ativo:boolean = true;
  public loader     = false;
  public statusCode = null; 

  constructor(
    public router: Router,
    private App: AppService,
    private elementRef:ElementRef,
    private data: DataService
  ) {
    
    this.onEvents()
    let ativo  = this.elementRef.nativeElement.getAttribute('ativo') ? this.elementRef.nativeElement.getAttribute('ativo') : true;
    this.ativo = ativo == "false" || ativo == false ? false : true;

     
  }
  /**
   * 
   * Events  
   * 
   */
  onEvents(){

    this.router.events.forEach((event: NavigationEvent) => {
      
      if(event instanceof NavigationStart){
        this.loader = true
      }
      
      if(event instanceof NavigationEnd) {

        setTimeout(()=>{
          this.loader = false;
        },200)

      }

    });
    this.App.setStatusServidorEmitter.subscribe(code => {

      this.statusCode = code;
    
    });

  }
  ngOnInit() {
  
  }

}
