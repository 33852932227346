import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ModalConfirmEsvaziarCartComponent } from '../modal-confirm-esvaziar-cart/modal-confirm-esvaziar-cart.component';
import { CartService } from '../../../services/cart.service';

@Component({
  selector: 'btn-esvaziar-cart',
  templateUrl: './btn-esvaziar-cart.component.html',
  styleUrls: ['./btn-esvaziar-cart.component.scss']
})
export class BtnEsvaziarCartComponent implements OnInit {
  
   @Output("done") done = new EventEmitter(); 
   public modalDel; 
 
   constructor(
     public modal: MatDialog,
     private CartService: CartService
   ) { }
   
   updateCart(){
     
     this.done.emit();
     this.modalDel.close()
  
   }
   /**
    * 
    * Modal Delete all Cart
    * 
    * 
    */
   modalDelete(){
     
     this.modalDel = this.modal.open(ModalConfirmEsvaziarCartComponent,{
       width: "400px",
         data: {
           updateCart:()=>{
             return this.updateCart()
           }
         }
     });
     
   }
   /**
    * 
    * Inicializa as Funções
    * 
    */
   ngOnInit() {
   }
 
 }
