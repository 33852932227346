<div *ngFor="let m of modulos" [ngSwitch]="m.tag">
  <div *ngSwitchCase="'logo'">
    <modulo-logo [data]="m"></modulo-logo>
  </div>
  <div *ngSwitchCase="'sobre'">
    <modulo-sobre [data]="m" [template]="template"></modulo-sobre>
  </div>
  <div *ngSwitchCase="'contato'">
    <modulo-contato [data]="m" [template]="template"></modulo-contato>
  </div>
  <div *ngSwitchCase="'rede-social'">
    <modulo-rede-social [data]="m" [template]="template"></modulo-rede-social>
  </div>
  <div *ngSwitchCase="'endereco'">
    <modulo-endereco [data]="m" [template]="template"></modulo-endereco>
  </div>
  <div *ngSwitchCase="'banner-slider'">
    <modulo-banner-slider [data]="m"></modulo-banner-slider>
  </div>
  <div *ngSwitchCase="'newsletter'">
    <modulo-newsletter [data]="m" [template]="template"></modulo-newsletter>
  </div>
  <div *ngSwitchCase="'selo-trip-advisor'">
    <modulo-selo-trip [data]="m" [template]="template" [classCustom]="classCustom"></modulo-selo-trip>
  </div>  
  <div *ngSwitchCase="'produto-categoria-tickets'">
    <modulo-produtos-categoria [data]="m" [template]="template" classCustom="tickets" classBtn="btn-custom degrade-yellow"></modulo-produtos-categoria>
  </div>
  <div *ngSwitchCase="'produto-categorias'"> 
    <modulo-produto-lista-categorias [data]="m" [template]="template"></modulo-produto-lista-categorias>
  </div>
  <div *ngSwitchCase="'servicos-oferecidos'">
    <modulo-servicos-oferecidos [data]="m" [template]="template"></modulo-servicos-oferecidos>
  </div>
  <div *ngSwitchCase="'whatsapp'">
    <modulo-whatsapp [data]="m" [template]="template"></modulo-whatsapp>
  </div>
  <div *ngSwitchCase="'trip-advisor'">
    <modulo-trip-advisor [data]="m" [template]="template"></modulo-trip-advisor>
  </div>
  <div *ngSwitchCase="'produto-destaques'">
    <modulo-produto-destaques [data]="m" [template]="template" classCustom="passeios"></modulo-produto-destaques>
  </div>
  <div *ngSwitchCase="'traslado-destaques'">
    <modulo-produto-destaques [data]="m" [template]="template" classCustom="traslados"></modulo-produto-destaques>
  </div>
  <div *ngSwitchCase="'produto-categoria-destaque'">
    <modulo-produto-categoria-destaque [data]="m" [template]="template"></modulo-produto-categoria-destaque>
  </div>
  <div *ngSwitchCase="'parceiro'">
    <modulo-parceiros [data]="m" [template]="template"></modulo-parceiros>
  </div>
  <div *ngSwitchCase="'destinos'">
    <modulo-destinos [data]="m" [template]="template"></modulo-destinos> 
  </div>
  <div *ngSwitchCase="'horarios'">
    <modulo-horarios [data]="m" [template]="template"></modulo-horarios> 
  </div>
  <div *ngSwitchCase="'produto-regioes-destaques'">
    <modulo-produto-regiao-destaque [data]="m" [template]="template"></modulo-produto-regiao-destaque> 
  </div>
</div>


