<div [ngSwitch]="template">
  <div class="modulo modulo-produto-destaques" *ngSwitchCase="null">
    <div class="modulo-title" *ngIf="data.mostrar_titulo">
      <div class="container">
        <h2>{{data.titulo}}</h2>
        <h3>{{data.params.subtitulo}}</h3> 
      </div>  
    </div> 
    <div class="modulo-content">
      <div class="content-passeios">
        <div class="container">
          <div *ngIf="data.produtos.length > 0">
            <div class="row">
              <div class="col-md-4" *ngFor="let produto of produtos">
                <div class="item">
                  <produto-item [data]="produto"></produto-item> 
                </div> 
              </div>  
            </div>   
          </div>  
          <div class="alert alert-info" *ngIf="produtos.length == 0">
            <span>
              Nenhum passeio para mostrar por enquanto.
            </span>
          </div>  
        </div>
        <div class="see-all">
          <a href="#" title="Ver todos os Passeios">
            VER TODOS
          </a>  
        </div>  
      </div>
    </div> 
  </div>
  <div class="modulo modulo-traslados-destaques" *ngSwitchCase="'traslados'">
    <div class="modulo-title" *ngIf="data.mostrar_titulo">
      <div class="container">
        <h2>{{data.titulo}}</h2>
        <h3>{{data.params.subtitulo}}</h3> 
      </div>  
    </div> 
    <div class="modulo-content">
      <div class="content-passeios">
        <div class="container">
          <div *ngIf="data.produtos.length > 0">
            <div class="row">
              <div class="col-md-4" *ngFor="let produto of produtos">
                <div class="item">
                  <produto-item [data]="produto"></produto-item> 
                </div> 
              </div>  
            </div>   
          </div>  
          <div class="alert alert-info" *ngIf="produtos.length == 0">
            <span>
              Nenhum passeio para mostrar por enquanto.
            </span>
          </div>  
        </div>
        <div class="see-all">
          <a href="#" title="Ver todos os Passeios">
            VER TODOS
          </a>  
        </div>  
      </div>
    </div> 
    <div class="arrow-content">
      <div class="arrow arrow-down"></div>
    </div>  
  </div>
</div>  
