<div class="modulo modulo-parceiros">
  <div class="container">
    <div class="modulo-title" *ngIf="data.mostrar_titulo">
      <h3>{{data.titulo}}</h3>
      <h4 *ngIf="data.mostrar_subtitulo">{{data.subtitulo}}</h4>
    </div>
    <div class="modulo-content"> 
      <div class="parceiros">
        <ul>
          <li *ngFor="let parceiro of parceiros;let i=index;let l=last;">
            <div class="item" *ngIf="!parceiro.ativar_link_parceiro">
              <figure>
                <img [src]="parceiro.logo" [title]="parceiro.nome" />
              </figure>
            </div>
            <div class="item" *ngIf="parceiro.ativar_link_parceiro">
              <a [href]="parceiro.link_parceiro" [title]="parceiro.nome" target="_blank">
                <figure>
                  <img [src]="parceiro.logo" [title]="parceiro.nome" />
                </figure>
              </a>  
            </div> 
            <div class="divisor" [hidden]="l"></div>   
          </li>  
        </ul>  
      </div>  
    </div>  
  </div>  
</div>
