<div class="error-custom">
  <div class="error-custom-content">
    <h3>{{title}}</h3>
    <hr>
    <article class="description">
      <p class="text">
        {{description}}
      </p>  
      <p class="details" *ngIf="details != null">
        <strong>DETALHES:</strong><br>
        <span>{{details}}</span>
      </p>  
    </article>
    <button type="button" class="btn-one btn-icon" *ngIf="activeButton" (click)="reloadData()">
      <i class="material-icons">refresh</i>
      <span>{{textButton}}</span>
    </button>  
  </div>
</div>  
  