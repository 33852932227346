import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../../services/api.service';
import { AppService } from '../../../../services/app.service';
import { DataService } from '../../../../services/data.service';

@Component({
  selector: 'dm-cart-pagamento-confirmacao-email',
  templateUrl: './dm-cart-pagamento-confirmacao-email.component.html',
  styleUrls: ['./dm-cart-pagamento-confirmacao-email.component.scss']
})
export class DmCartPagamentoConfirmacaoEmailComponent implements OnInit {

  @Input("email") email                    = null;
  @Input("sendAutomatico") sendAutomatico  = true;
  @Input("carrinhoId") carrinhoId          = null;
  public loader                            = false;
  public messageLoader                     = "";
  public message                  = {
    show: false,
    data: "Testing...",
    class: ""
  }
  public subscribeMessage = null;

  constructor(
    private _api: ApiService,
    private _app: AppService,
    private _data: DataService
  ){ }
  /***
   * 
   * Enviar confirmação por email
   * 
   * 
   */
  enviarConfirmacaoEmail(){

    try{

      this.messageLoader = "Enviando confirmação. Aguarde.";
      this.loader        = true;
      this.subscribeMessage = null;
      
      this._api.pagamento().enviarConfirmacaoEmail(this.email,this.carrinhoId).subscribe(response => {

        this.loader = false;

        if(response.status == 1){
          this.subscribeMessage   = "A confirmação da compra foi enviada para o seu EMAIL.";
          this._data.setConfirmacaoEmail();
        }else{

          this.subscribeMessage   = response.data;

        }

      },(response) => {

        this.loader = false;

        let error          = this._app.formatError(response);
        this.subscribeMessage   = error.message;

      });

    }catch(e){

      this.loader = false;

    }


  }
  /**
   * 
   * Incializa as Funções
   * 
   */
  ngOnInit(){

    let confirmacao_email = this._data.getConfirmacaoEmail();

    if(this.sendAutomatico && !confirmacao_email){
      this.enviarConfirmacaoEmail(); 
    }

  }

}
