<div class="page page-sobre" itemtype="https://schema.org/AboutPage">
  <div class="section-breadcrumbs" *ngIf="itensBreadcrumbs.length > 0"> 
    <div class="container">
      <breadcrumbs [itens]="itensBreadcrumbs"></breadcrumbs>
    </div>  
  </div> 
  <div *ngIf="response.status == 1" itemprop="mainContentOfPage" itemscope itemtype="https://schema.org/WebPageElement">
    <div class="page-title" *ngIf="page.mostrar_titulo">
      <div class="container">
        <h1 itemprop="name">{{page.titulo}}</h1>
        <h2 *ngIf="page.mostrar_subtitulo">{{page.subtitulo}}</h2>
      </div>  
      <div class="linha"></div>
    </div>  
    <div class="page-content">
      <section class="section descricao" *ngIf="page.mostrar_texto">
        <div class="container">
          <article itemprop="text" [innerHTML]="page.texto | safeHtml"></article>
        </div>  
      </section> 
      <section class="section fotos" *ngIf="fotos.length > 0">
        <div class="container">
          <galeria-one [fotos]="fotos" classCustom="page-sobre-fotos"></galeria-one>
        </div>  
      </section>
    </div> 
  </div> 
  <div *ngIf="response.status == 500">
    <div class="container">
      <error-custom title="Não foi possível carregar os dados da Página" [details]="response.data" (reload)="getData()"></error-custom>
    </div>  
  </div>  
</div>
<modulo-loader message="Carregando Conteúdo" [hidden]="!loader"></modulo-loader> 
    