<div [ngSwitch]="template">
  <div class="produto-form-search" *ngSwitchCase="'one'">
    <div class="titulo">
      <h3>O que você está procurando?</h3>
    </div>  
    <div class="content">
      <form class="form" autocomplete="off">
        <div class="row">
          <div class="col-md-6">
            <div class="content-input">
              <label>Informe o nome do serviço:</label>
              <input type="text" [(ngModel)]="dataItem.nome" name="nome" class="form-control input-cancel" (input)="_changeInput($event)" [formControl]="myControl" [matAutocomplete]="auto" />
              <img src="/images/icons/sao-paulo-ecoturismo/cancel.svg" class="cancel" [hidden]="dataItem.nome.length == 0" (click)="dataItem.nome=''" />
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                  {{option}}
                </mat-option>
              </mat-autocomplete>
            </div>  
          </div> 
          <div class="col-md-6">
            <label>Informe a Categoria:</label>
            <mat-select [(ngModel)]="dataItem.produto_categoria_id" name="produto_categoria_id" (change)="_changeCategoria($event)">
              <mat-option value="">
                ---
              </mat-option>  
              <mat-option [value]="value.id" *ngFor="let value of categorias">
                {{value.titulo}}
              </mat-option>
            </mat-select>  
          </div>  
        </div>  
      </form>  
    </div>  
  </div>
  <div class="produto-form-search" *ngSwitchCase="'two'">
    <div class="titulo">
      <h3>O que você está procurando?</h3>
    </div>  
    <div class="content">
      <form class="form" autocomplete="off">
        <div class="content-input">
          <label>Informe o nome do serviço:</label>
          <input type="text" [(ngModel)]="dataItem.nome" name="nome" class="form-control input-lupa" (input)="_changeInput($event)" [formControl]="myControl" [matAutocomplete]="auto" />
          <img src="/images/icons/sao-paulo-ecoturismo/cancel.svg" class="cancel" [hidden]="dataItem.nome.length == 0" (click)="dataItem.nome=''" />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
              {{option}}
            </mat-option>
          </mat-autocomplete>
        </div>
      </form>  
    </div>  
  </div>
</div>  
  