<nav class="pagination-content" *ngIf="total > pageSize">
  <div class="content">
    <ul class="pagination">
      <li class="page-item">
        <a class="page-link" aria-label="Previous" (click)="onPrev()">
          <span aria-hidden="true">&laquo;</span>
          <span class="sr-only">Previous</span>
          <span class="text">Anterior</span>
        </a>
      </li>
      <li *ngFor="let p of pages;" class="page-item" [ngClass]="{'active': pageNumber == p.number}">
        <a class="page-link" *ngIf="displayLi(p)" (click)="onClick(p.number)">
          {{p.text}}
        </a>
      </li>
      <li class="page-item">
        <a class="page-link" aria-label="Next" (click)="onNext()">
          <span class="text">Próximo</span>
          <span aria-hidden="true">&raquo;</span>
          <span class="sr-only">Next</span>
        </a>
      </li>
    </ul>
  </div>  
</nav>
  
  