import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'dm-cart-pagamento-formas-pagamento',
  templateUrl: './dm-cart-pagamento-formas-pagamento.component.html',
  styleUrls: ['./dm-cart-pagamento-formas-pagamento.component.scss']
})
export class DmCartPagamentoFormasPagamentoComponent implements OnInit {

  @Input("total") total                      = 0;
  @Input("formasPagamento") formasPagamento  = [];
  @Input("carrinho") carrinho                = [];
  @Input("sendPagamento") sendPagamento      = new EventEmitter();
  @Output("onSuccess") onSuccess             = new EventEmitter();
  @Output("updateResponse") _updateResponse  = new EventEmitter();
  public indexSelected                       = 0; 

  constructor(){ 

  }
  /**
   * 
   * Set Index
   * 
   */
  setIndex(index){

    this.indexSelected = index;  

  }
  /**
   * 
   * On Sucesso pagamento.
   * 
   * @param response 
   * 
   */
  onSuccessPagamento(response){

    this.onSuccess.emit(response);

  }
  /**
   * 
   * On Sucesso pagamento.
   * 
   * @param response 
   * 
   */
  updateResponse(response){

    this._updateResponse.emit(response);

  }
   /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    
  }

}
