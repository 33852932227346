<div class="dm-cart-pagamento-tipo" [ngSwitch]="apelido">
  <div class="dm-cart-pagamento-tipo-content" *ngSwitchCase="'rede_pay'">
    <dm-cart-pagamento-rede-pay (onSuccess)="onSuccess($event)" [sendPagamento]="_sendPagamento" [carrinho]="carrinho" [formaPagamento]="formaPagamento"></dm-cart-pagamento-rede-pay>
  </div>  
  <div class="dm-cart-pagamento-tipo-content" *ngSwitchCase="'gerencia_net_boleto'">
    <dm-cart-pagamento-gerencia-net-boleto (onSuccess)="onSuccess($event)" [sendPagamento]="_sendPagamento" [carrinho]="carrinho" [formaPagamento]="formaPagamento"></dm-cart-pagamento-gerencia-net-boleto>
  </div>  
  <div class="dm-cart-pagamento-tipo-content" *ngSwitchCase="'cielo'"> 
    <dm-cart-pagamento-cielo (updateResponse)="updateResponse($event)" (onSuccess)="onSuccess($event)" [sendPagamento]="_sendPagamento" [carrinho]="carrinho" [formaPagamento]="formaPagamento"></dm-cart-pagamento-cielo>
  </div>  
  <div class="dm-cart-pagamento-tipo-content" *ngSwitchCase="'ipag'"> 
    <dm-cart-pagamento-ipag (onSuccess)="onSuccess($event)" [sendPagamento]="_sendPagamento" [carrinho]="carrinho" [formaPagamento]="formaPagamento"></dm-cart-pagamento-ipag>
  </div>  
</div>
