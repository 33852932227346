import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiComponentesSettings } from '../class/api-componentes-settings';

@Injectable()
export class ApiService {

  constructor(
    private http: HttpClient
  ){ }

  /**
   * 
   * Serviço
   * 
   */
  produto(){

    return {
      destino_produtos: (apelido):any=> {

        let url = ApiComponentesSettings.PRODUTO.destino_produtos+"?apelido="+apelido; 
        return this.http.get(url,{}).map(response => response); 
      
      },
      regiao_produtos: (apelido):any=> {

        let url = ApiComponentesSettings.PRODUTO.regiao_produtos+"?apelido="+apelido; 
        return this.http.get(url,{}).map(response => response); 
      
      },
      produtos: (apelido):any=> {

        let url = ApiComponentesSettings.PRODUTO.produtos+"?apelido="+apelido; 
        return this.http.get(url,{}).map(response => response); 
      
      },
      send_contato: (dataItem):any=> {

        let url = ApiComponentesSettings.PRODUTO.send_contato; 
        return this.http.post(url,dataItem).map(response => response); 
      
      },
      categorias: (apelido):any=> {

        let url = ApiComponentesSettings.PRODUTO.categorias; 
        return this.http.get(url,{}).map(response => response); 
      
      },
      produto: (apelido):any=> {

        let url = ApiComponentesSettings.PRODUTO.produto+"?apelido="+apelido; 
        return this.http.get(url,{}).map(response => response); 

      },
      solicitacao_reserva: (dataItem):any=> {

        let url = ApiComponentesSettings.PRODUTO.solicitacao_reserva; 

        return this.http.post(url,dataItem).map(response => response); 

      },
      aeroporto:  {
        combo: ():any => {
          
          let url = ApiComponentesSettings.PRODUTO.aeroporto.combo; 
          return this.http.get(url,{}).map(response => response); 
        
        }
      },
      cia_aerea:  {
        combo: ():any => {
          
          let url = ApiComponentesSettings.PRODUTO.cia_aerea.combo; 
          return this.http.get(url,{}).map(response => response); 
        
        }
      }
    }

  }
   /**
   * 
   * Serviço
   * 
   */
  atividade(){

    return {
      get_atividade: (codigo):any=> {
        let url = ApiComponentesSettings.ATIVIDADE.get_atividade+"?codigo="+codigo; 
        return this.http.get(url,{}).map(response => response); 
      },
      get_atividades_pax: (id):any=> {
        let url = ApiComponentesSettings.ATIVIDADE.get_atividades_pax+"/"+id; 
        return this.http.get(url,{}).map(response => response); 
      }
    }

  }
  /**
   * 
   * Cart
   * 
   * 
   */
  cart(){

    return{
      get: ():any => {

        let url = ApiComponentesSettings.CART.base;
        
        return this.http.get(url,{}).map(response => response); 

      },
      insert: (params):any => {
        
        let url = ApiComponentesSettings.CART.base;

        return this.http.post(url,params).map(response=>response);

      },
      delete: (id):any => {
        
        let url = ApiComponentesSettings.CART.base+"/"+id;

        return this.http.delete(url).map(response=>response);

      },
      lista_paxs: ():any => {

        let url = ApiComponentesSettings.CART.lista_paxs;
        
        return this.http.get(url,{}).map(response => response); 

      },
      abandonar_compra: ():any => {

        let url = ApiComponentesSettings.CART.abandonar_compra;
        
        return this.http.post(url,{}).map(response=>response)

      },
      esvaziar: ():any => {

        let url = ApiComponentesSettings.CART.esvaziar;
        
        return this.http.post(url,{}).map(response=>response)

      },
      dados_comprador: (params):any => {

        let url = ApiComponentesSettings.CART.dados_comprador;
        
        return this.http.post(url,params).map(response=>response)

      },
      get_cart_pagamento: ():any => {

        let url = ApiComponentesSettings.CART.get_cart_pagamento;
        
        return this.http.get(url,{}).map(response => response); 

      },
      get_cart_file_gerado: ():any => {

        let url = ApiComponentesSettings.CART.get_cart_file_gerado;
        
        return this.http.get(url,{}).map(response => response); 

      },
      update_passageiro_titular: (id):any => {

        let url    = ApiComponentesSettings.CART.update_passageiro_titular;

        let params = {
          id: id
        }
        
        return this.http.put(url,params).map(response => response);  

      }

    }

  }
  /**
   * 
   * Managetour
   * 
   */
  manage(){

    return{
      gerarVoucher: (file):any => {

        let url = ApiComponentesSettings.MANAGE.gerar_voucher+"/"+file;
          
        return this.http.get(url).map(response => response)

      },
      listaTiposServicosById: (id,idMacroRegiao):any => {

        let url = ApiComponentesSettings.MANAGE.lista_tipo_servicos_by_id+"/"+id+"/"+idMacroRegiao; 
        return this.http.get(url).map(response => response);

      },
      hoteis: (id=null): any => {

        let url  = ApiComponentesSettings.MANAGE.hoteis;
        if(id != null){
          url += "/"+id; 
        }

        return this.http.get(url).map(response => response)

      },
      regioes: (): any => {

        let url  = ApiComponentesSettings.MANAGE.regioes;
        return this.http.get(url).map(response => response)

      },
      consultarReserva: (data): any => {

        let url  = ApiComponentesSettings.MANAGE.consultar_reserva;
        
        let params = {
          file: data.file,
          ultimonome: data.ultimonome
        } 
        
        return this.http.get(url,{params}).map(response => response)

      },
      autenticar: (usuario,senha,tipo):any => {

        let url = ApiComponentesSettings.MANAGE.autenticar;    
        
        let params = {
          usuario: usuario,
          senha: senha,
          tipo: tipo
        }

        return this.http.post(url,params).map(response => response);

      },
      logout: ():any => {

        let url = ApiComponentesSettings.MANAGE.logout;
        
        return this.http.post(url,{}).map(response => response);

      },
      disponibilidade: (
        idServico,
        idTipoServico,
        idMacroRegiao,
        idTipoTransporte,
        mes,
        ano,
        qtdPaxs
      ):any => {

        let url   = ApiComponentesSettings.MANAGE.disponibilidade;
        let params = {
          macro_regiao_id: idMacroRegiao,
          servico_id: idServico,
          tipo_servico_id: idTipoServico,
          tipo_transporte_id: idTipoTransporte,
          mes: mes,
          ano: ano,
          qtd_paxs: qtdPaxs
        }
        return this.http.get(url,{params}).map(response => response);

      }

    }

  }
  /**
   * 
   * Pagamento
   * 
   */
  pagamento(){

    return {
      finalizarReserva: (params):any => {

        let url = ApiComponentesSettings.PAGAMENTO.finalizar_reserva;

        return this.http.post(url,params).map(response => response);

      },
      enviarConfirmacaoEmail: (email,id):any => {

        let url = ApiComponentesSettings.PAGAMENTO.enviar_confirmacao_email;
        let params = {
          id: id,
          email: email
        }

        return this.http.post(url,params).map(response => response);

      }

    }

  }

}
