<div [ngSwitch]="template">
  <div class="modulo modulo-newsletter" *ngSwitchCase="null">
    <div class="modulo-content">
      <div class="container">
        <div class="row"> 
          <div class="col-lg-6 col-md-6">
            <h3>{{data.titulo}}</h3>
            <h4>{{data.subtitulo}}</h4>
            <h5>{{data.params.texto}}</h5>
          </div>  
          <div class="col-lg-6 col-md-6">
            <form class="form" (submit)="send()" autocomplete="off" autocapitalize="off">
              <div class="row">
                <div class="col-md-6">
                  <input type="text" [(ngModel)]="dataItem.nome" name="nome" placeholder="informe seu nome*" />
                </div>
                <div class="col-md-6">
                  <input type="text" [(ngModel)]="dataItem.email" name="email" placeholder="informe seu e-mail*" />
                </div>  
              </div>
              <div class="row"> 
                <div class="col-md-6">
                  <input type="text" [(ngModel)]="dataItem.telefone" name="telefone" placeholder="informe seu whatsapp" mask="(99) 99999-9999" />
                </div>
                <div class="col-md-6">
                  <button type="button" (click)="send()" [disabled]="loader">
                    <span>ME CADASTRAR</span>
                  </button>
                </div>  
              </div>  
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group" *ngIf="message.show">
                    <article class="alert-message" [innerHtml]="message.data" [class]="message.class"></article>
                  </div> 
                </div>
              </div>     
            </form>
          </div>    
        </div>
      </div>  
    </div>  
  </div>
  <div class="modulo modulo-newsletter contato" *ngSwitchCase="'page-contato'">
    <div class="modulo-content">
      <h4>receba nossas novidades</h4>
      <form class="form" (submit)="send()" autocomplete="off">
        <div class="form-group">
          <input type="text" [(ngModel)]="dataItem.nome" name="nome" placeholder="SEU NOME" />
        </div>
        <div class="form-group">
          <div class="input-email">
            <div class="input-email-left">
              <input type="text" [(ngModel)]="dataItem.email" name="email" placeholder="SEU EMAIL" />
            </div>
            <div class="input-email-right">
              <button type="button" (click)="send()" [disabled]="loader">
                <span>ENVIAR</span>
              </button>  
            </div>  
          </div> 
          <div class="alert alert-info" *ngIf="loader">
            Enviando. Aguarde!
          </div>  
        </div>  
      </form>
    </div>  
  </div>
</div>  
<!--<modal-info [show]="message.show" [customClass]="message.class" [message]="message.data" (hide)="message.show=false"></modal-info>-->
<modulo-loader [message]="messageLoader" [hidden]="!loader"></modulo-loader>