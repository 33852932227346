<div class="cart-dados-form-update">
  <div class="row">
    <div class="col-md-3">
      <label for="dataItem.nome">Nome</label>
      <input type="text" [(ngModel)]="dataItem.nome" name="dataItem.nome" class="input-custom" uppercase />
    </div> 
    <div class="col-md-3">
        <label for="dataItem.email">Email</label>
        <input type="text" [(ngModel)]="dataItem.email" name="dataItem.email" class="input-custom" /> 
    </div> 
    <div class="col-md-2"> 
      <label for="dataItem.cpf">Cpf</label> 
      <input type="text" [(ngModel)]="dataItem.cpf" name="dataItem.cpf" class="input-custom" mask="999.999.999-99" placeholder="000.000.000-00" /> 
    </div>  
    <div class="col-md-2">
      <label for="dataItem.telefone">Telefone</label>
      <input type="text" [(ngModel)]="dataItem.telefone" name="dataItem.telefone" class="input-custom" placeholder="(00) 00000-0000" mask="(99) 99999-9999" />
    </div> 
    <div class="col-md-2">
      <label for="dataItem.data_nascimento">Data Nascimento</label>
      <input type="text" [(ngModel)]="dataItem.data_nascimento" name="dataItem.data_nascimento" class="input-custom" placeholder="00-00-0000" mask="99-99-9999" />
    </div>   
  </div> 
  <hr>
  <div class="row">
    <div class="col-md-12">
      <button type="button" class="btn-eleven" (click)="save()">
        SALVAR
      </button>  
    </div>  
  </div>  
</div>
<modulo-loader data-message="Salvando os Dados. Aguarde!" *ngIf="loader"></modulo-loader>
<modal-info (hide)="messageResponse.show=false" [show]="messageResponse.show" [message]="messageResponse.message" [customClass]="messageResponse.class"></modal-info>


