<div id="gallery" class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="pswp__bg"></div>
    <div class="pswp__scroll-wrap">
      <div class="pswp__container" style="transform: translate3d(0px, 0px, 0px);">
        <div class="pswp__item" style="display: block; transform: translate3d(-1511px, 0px, 0px);"><div class="pswp__zoom-wrap" style="transform: translate3d(272px, 44px, 0px) scale(1);"><img class="pswp__img pswp__img--placeholder" src="./PhotoSwipe_ Responsive JavaScript Image Gallery_files/15008465772_383e697089_m.jpg" style="width: 805px; height: 537px;"><img class="pswp__img" src="./PhotoSwipe_ Responsive JavaScript Image Gallery_files/15008465772_d50c8f0531_h.jpg" style="width: 805px; height: 537px;"></div></div>
        <div class="pswp__item" style="transform: translate3d(0px, 0px, 0px);"><div class="pswp__zoom-wrap" style="transform: translate3d(304.5px, 239px, 0px) scale(0.43088);"><img class="pswp__img pswp__img--placeholder" src="./PhotoSwipe_ Responsive JavaScript Image Gallery_files/15008518202_b016d7d289_m.jpg" style="width: 557px; height: 557px;"><img class="pswp__img" src="./PhotoSwipe_ Responsive JavaScript Image Gallery_files/15008518202_c265dfa55f_h.jpg" style="display: block; width: 557px; height: 557px;"></div></div>
        <div class="pswp__item" style="display: block; transform: translate3d(1511px, 0px, 0px);"><div class="pswp__zoom-wrap" style="transform: translate3d(272px, 44px, 0px) scale(1);"><img class="pswp__img pswp__img--placeholder" src="./PhotoSwipe_ Responsive JavaScript Image Gallery_files/15008867125_68a8ed88cc_m.jpg" style="width: 804px; height: 537px;"><img class="pswp__img" src="./PhotoSwipe_ Responsive JavaScript Image Gallery_files/15008867125_b61960af01_h.jpg" style="width: 804px; height: 537px;"></div></div>
      </div>
      <div class="pswp__ui pswp__ui--fit pswp__ui--hidden">
        <div class="pswp__top-bar">
          <div class="pswp__counter">1 / 5</div>
            <button class="pswp__button pswp__button--close" title="Close (Esc)"></button>
            <!--<button class="pswp__button pswp__button--share" title="Share"></button>-->
            <button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button>
            <button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button>
          <div class="pswp__preloader">
            <div class="pswp__preloader__icn">
              <div class="pswp__preloader__cut">
                <div class="pswp__preloader__donut"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
          <div class="pswp__share-tooltip"></div>
        </div>
        <button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)"></button>
        <button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)"></button>
        <div class="pswp__caption">
          <div class="pswp__caption__center"></div>
        </div>
      </div>
    </div>
</div>
