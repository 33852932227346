import { ModulosResolver } from './resolvers/modulos.resolver';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContatoPageComponent } from './pages/contato-page/contato-page.component';
import { SobrePageComponent } from './pages/sobre-page/sobre-page.component';
import { ServicosPage } from './pages/servico/servicos-page/servicos-page.component';
import { ServicoPage } from './pages/servico/servico-page/servico-page.component';
import { CartPage } from './pages/cart-page/cart-page.component';
import { ConsultarReservaPage } from './pages/consultar-reserva-page/consultar-reserva-page.component';
import { PagamentoOnePage } from './pages/pagamento-one-page/pagamento-one-page.component';
import { PoliticaDePrivacidadePage } from './pages/politica-de-privacidade-page/politica-de-privacidade-page.component';
import { PoliticaDeCancelamentoPage } from './pages/politica-de-cancelamento-page/politica-de-cancelamento-page.component';
import { DestinoServicosPage } from './pages/destinos/destino-servicos-page/destino-servicos-page.component';

const routes: Routes = [{
    path: '', 
    component: HomePageComponent,
    resolve:{
      modulos: ModulosResolver
    }
  },{
    path: 'sobre',
    component: SobrePageComponent,
    resolve:{
      modulos: ModulosResolver
    }
  },{
    path: 'contato',
    component: ContatoPageComponent,
    resolve:{
      modulos: ModulosResolver
    }
  },{
    path: 'politicas-de-privacidade',
    component: PoliticaDePrivacidadePage,
    resolve:{
      modulos: ModulosResolver
    }
  },{
    path: 'politicas-de-cancelamento',
    component: PoliticaDeCancelamentoPage, 
    resolve:{
      modulos: ModulosResolver
    }
  },{
    path: 'carrinho',
    component: CartPage,
    resolve:{
      modulos: ModulosResolver
    }
  },{
    path: 'carrinho/finalizar-compra',
    component: PagamentoOnePage,
    resolve:{ 
      modulos: ModulosResolver
    }
  },{
    path: 'consultar-reserva', 
    component: ConsultarReservaPage,
    resolve:{ 
      modulos: ModulosResolver
    }
  },{
    path: 'servicos',
    component: ServicosPage,
    resolve:{ 
      modulos: ModulosResolver
    }
  },{
    path: 'servicos/:apelido',
    component: DestinoServicosPage,
    resolve:{
      modulos: ModulosResolver
    }
  },{
    path: 'servicos/:apelido_regiao/:apelido_categoria/:apelido_produto',
    component: ServicoPage,
    resolve:{
      modulos: ModulosResolver
    }
  },{
    path: '**',
    pathMatch: 'full',
    redirectTo: ''
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
