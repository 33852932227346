<div class="dm-cart-add">
  <div class="row">
    <div class="col-lg-12" *ngIf="step != 8">
      <dm-cart-resumo-steps (changeStep)="_changeStep($event)" [dataItem]="dataItem" [step]="step"></dm-cart-resumo-steps>
    </div> 
    <div class="col-lg-12">
      <div class="dm-cart-add-content" [ngSwitch]="step">
        <div class="section-step" *ngSwitchCase="'1'"> 
          <div class="section-step-title">
            <h3>1. ESCOLHA A REGIÃO</h3>
            <hr>
          </div>
          <div class="section-step-content">
            <div class="lista-macro-regiao">
              <div class="item" *ngFor="let item of macroRegiao;let i=index;">
                <div class="dm-check-box">
                  <span class="dm-check-box-input" [ngClass]="{'active':item.id == dataItem.macro_regiao_id}" (click)="dataItem.macro_regiao_id=item.id;dataItem.macro_regiao=item.descricao;"></span>
                  <span class="dm-check-box-value">{{item.descricao}}</span>
                </div>
              </div>  
            </div>  
          </div>
          <div class="section-step-footer">
            <div class="navigation next">
              <a href="javascript::void();" class="btn-custom-three" title="Próximo" (click)="next()">
                PRÓXIMO
              </a>  
            </div> 
          </div>
        </div>  
        <div class="section-step" *ngSwitchCase="'2'">
          <div class="section-step-title">
            <h3>2. TIPO DE SERVIÇO</h3>
            <hr>
          </div>
          <div class="section-step-content">
            <div class="content-message-loader">
              <span class="alert alert-info alert-message" *ngIf="loaderTipoServico">
                {{messageLoader}}
              </span> 
            </div>
            <div class="content-response" *ngIf="dataTipos.status == 1">      
              <div class="lista-tipo" *ngIf="dataTipos.data.length > 0">
                <div class="lista-tipo-item" *ngFor="let item of dataTipos.data;let i=index;">
                  <div class="dm-check-box">
                    <span class="dm-check-box-input" [ngClass]="{'active':item.idTipoServico == dataItem.tipo_servico_id}" (click)="dataItem.tipo_servico_id=item.idTipoServico;dataItem.tipo_servico=item.tipoServico;"></span>
                    <span class="dm-check-box-value">{{item.tipoServico}} <span class="dm-check-box-info">- A partir de: {{item.lista | getTarifaMinimaLista | currency: "R$ "}}</span></span>
                  </div>
                  <div class="lista-tipo-veiculos" *ngIf="item.idTipoServico == 2 && dataItem.tipo_servico_id == 2">
                    <hr>
                    <div class="lista-veiculos">
                      <div class="veiculo" *ngFor="let veiculo of item.lista;">
                        <div class="dm-check-box">
                          <span class="dm-check-box-input" [ngClass]="{'active':veiculo.idTipoTransporte == dataItem.tipo_transporte_id}" (click)="dataItem.tipo_transporte_id=veiculo.idTipoTransporte;dataItem.tipo_transporte=veiculo.tipoTransporte;dataItem.lotacao_maxima=veiculo.lotacaoMaxima;"></span>
                          <span class="dm-check-box-value">
                            {{veiculo.tipoTransporte}} 
                            <span class="dm-check-box-info"> 
                              - Valor: {{veiculo.tarifaMinima | formatValorManager | currency:"R$ "}} - Lotação Máxima: {{veiculo.lotacaoMaxima}} lugares
                            </span>  
                          </span>
                        </div>   
                      </div>  
                    </div>  
                  </div>  
                </div>  
              </div> 
              <div class="subscribe-message" *ngIf="dataTipos.data.length == 0"> 
                <span>Esse serviço não está disponível para venda.</span>
              </div>  
            </div>  
          </div>
          <div class="section-step-footer" *ngIf="dataTipos.success">
            <div class="navigation prev">
              <a href="javascript::void();" class="btn-custom-three" title="Anterior" (click)="prev()">
                ANTERIOR
              </a>  
            </div> 
            <div class="navigation next">
              <a href="javascript::void();" class="btn-custom-three" title="Próximo" (click)="next()">
                PRÓXIMO
              </a>  
            </div>  
          </div>  
        </div>  
        <div class="section-step" *ngSwitchCase="'3'">
          <div class="section-step-title">
            <h3>3. ONDE VAI SE HOSPEDAR?</h3>
            <hr>
          </div>
          <div class="section-step-content">
            <combo-manager-hotel [id]="servico.id" label="Selecionar Hospedagem:" (changeValue)="changeHotel($event)" [value]="dataItem.hotel_id" [text]="dataItem.nome_hotel"></combo-manager-hotel>
            <div class="subscribe-message animated fadeIn" *ngIf="subscribeMessage != null" [innerHtml]="subscribeMessage"></div>
          </div>  
          <div class="section-step-footer">
            <div class="navigation prev">
              <a href="javascript::void();" class="btn-custom-three" title="Anterior" (click)="prev()">
                ANTERIOR
              </a>  
            </div> 
            <div class="navigation next">
              <a href="javascript::void();" class="btn-custom-three" title="Próximo" (click)="next()">
                PRÓXIMO
              </a>  
            </div> 
          </div>  
        </div>
        <div class="section-step" *ngSwitchCase="'4'" [hidden]="!pedirDadosVoo">
          <div class="section-step-title">
            <h3>4. INFORME OS DADOS DO VÔO</h3>
            <hr>
          </div> 
          <div class="section-step-content">
            <dm-cart-dados-voo [dataItem]="dataItem" (changeCiaAerea)="changeCia($event)" (changeAeroporto)="changeAeroporto($event)"></dm-cart-dados-voo>
            <div class="subscribe-message animated fadeIn" *ngIf="subscribeMessage != null" [innerHtml]="subscribeMessage"></div>
          </div>
          <div class="section-step-footer">
            <div class="navigation prev">
              <a href="javascript::void();" class="btn-custom-three" title="Anterior" (click)="prev()">
                ANTERIOR
              </a>  
            </div> 
            <div class="navigation next">
              <a href="javascript::void();" class="btn-custom-three" title="Próximo" (click)="next()">
                PRÓXIMO
              </a>  
            </div> 
          </div>   
        </div>
        <div class="section-step" *ngSwitchCase="'5'">
          <div class="section-step-title">
            <h3>5. INFORME A QUANTIDADE DE PASSAGEIROS</h3>
            <hr>
          </div>  
          <div class="section-step-content">
            <dm-cart-passageiros-qtd [dataItem]="dataItem" [servico]="servico" [configuracaoLoja]="configuracaoLoja" [qtd_adt]="dataItem.qtd_adt" [qtd_chd]="dataItem.qtd_chd" [qtd_snr]="dataItem.qtd_snr" [qtd_inf]="dataItem.qtd_inf" (changeValue)="changeQtds($event)"></dm-cart-passageiros-qtd>
          </div> 
          <div class="section-step-footer">
            <div class="navigation prev">
              <a href="javascript::void();" class="btn-custom-three"  title="Anterior" (click)="prev()">
                ANTERIOR
              </a>  
            </div> 
            <div class="navigation next">
              <a href="javascript::void();" class="btn-custom-three"  title="Próximo" (click)="next()">
                PRÓXIMO
              </a>  
            </div> 
          </div> 
        </div>
        <div class="section-step" *ngSwitchCase="'6'">  
          <div class="section-step-title">
            <h3>4. ESCOLHA A DATA</h3>
            <hr>
          </div>  
          <div class="section-step-content">
              <dm-cart-data-servico [snr]="dataItem.qtd_snr" [adt]="dataItem.qtd_adt" [value]="dataItem.data_utilizacao" [disponibilidadeArray]="disponibilidadeArray" (changeValue)="changeDataServico($event)" [idServico]="dataItem.servico_id" [idTipoServico]="dataItem.tipo_servico_id" [idMacroRegiao]="dataItem.macro_regiao_id" [idTipoTransporte]="dataItem.tipo_transporte_id"></dm-cart-data-servico> 
              <div class="subscribe-message animated fadeIn" *ngIf="subscribeMessage != null" [innerHtml]="subscribeMessage"></div>
              <div [hidden]="dataItem.data_utilizacao == null"> 
                <dm-cart-tarifas-resumo [dataItem]="dataItem"></dm-cart-tarifas-resumo>  
              </div>
          </div> 
          <div class="section-step-footer">
            <div class="navigation prev">
              <a href="javascript::void();" class="btn-custom-three"  title="Anterior" (click)="prev()">
                ANTERIOR
              </a>  
            </div> 
            <div class="navigation next">
              <a href="javascript::void();" class="btn-custom-three"  title="Próximo" (click)="next()">
                PRÓXIMO
              </a>  
            </div> 
          </div>  
        </div>
        <div class="section-step" *ngSwitchCase="'7'">
          <div class="section-step-title">
            <h3>6. INFORME OS PASSAGEIROS</h3>
            <hr>
          </div>  
          <div class="section-step-content">
            <dm-cart-passageiro-lista-informacao [configuracaoLoja]="configuracaoLoja" [dataItem]="dataItem" (changeValue)="changeListaPassageiros($event)"></dm-cart-passageiro-lista-informacao>
            <div class="subscribe-message animated fadeIn" *ngIf="subscribeMessage != null" [innerHtml]="subscribeMessage"></div>
          </div> 
          <div class="section-step-footer">
            <div class="navigation prev">
              <button type="button" class="btn-custom-three" [disabled]="loader" (click)="prev()">
                <span>ANTERIOR</span>
              </button>  
            </div> 
            <div class="navigation next">
              <button type="button" class="btn-custom-three" [disabled]="loader" (click)="addCart()">
                <span *ngIf="!loader">INSERIR NO CARRINHO</span>
                <span *ngIf="loader">INSERIRINDO. AGUARDE!</span>
              </button>  
            </div> 
          </div>
        </div>
        <div class="section-step section-step-sucesso" *ngSwitchCase="'8'">
          <span class="titulo">
            <i class="fa fa-check"></i>
            O serviço foi adicionado ao carrinho.
          </span>
          <a routerLink="/carrinho" class="btn-custom-three i-arrow" title="Carrinho de Compras">
            <span>Ir para o carrinho</span>
            <i class="fal fa-angle-right"></i>
          </a>
          <a routerLink="/servicos" class="btn-custom-five i-arrow" title="Continuar Comprando">
            <span>Continuar comprando</span>
            <i class="fal fa-angle-right"></i>
          </a>
          <a routerLink="/carrinho/finalizar-compra" class="btn-custom-four i-arrow" title="Finalizar a Compra">
            <span>Finalizar a compra</span>
            <i class="fal fa-angle-right"></i>
          </a>  
        </div>  
      </div> 
    </div> 
  </div>     
</div> 
