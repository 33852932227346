<div class="produto-form-contato">
  <div class="content">
    <form (submit)="send()" autocomplete="off" class="form">
      <div class="form-group">
        <input type="text" [(ngModel)]="dataItem.nome" placeholder="Informe seu nome*" name="nome" uppercase />
      </div>  
      <div class="form-group">
        <input type="text" [(ngModel)]="dataItem.email" placeholder="Informe seu e-mail*" name="email" />
      </div>
      <div class="form-group"> 
        <input type="text" [(ngModel)]="dataItem.celular" placeholder="Informe seu telefone" name="celular" mask="(99) 99999-9999" />
      </div> 
      <div class="form-group"> 
        <textarea [(ngModel)]="dataItem.mensagem" placeholder="Informe a sua dúvida*" name="mensagem" rows="5"></textarea>
      </div> 
      <div class="footer">
        <button type="submit" class="btn-one">
          ENVIAR
        </button>  
      </div>   
    </form>  
  </div>  
</div>
<modulo-loader [message]="messageLoader" [hidden]="!loader"></modulo-loader>
<modal-info [show]="message.show" [customClass]="message.class" [message]="message.data" (hide)="message.show=false"></modal-info>

