<div class="cart-item">
  <div class="cart-item-capa">
    <figure>
      <img [src]="item.imagem" [alt]="item.nome" class="img-fluid" />
    </figure>  
  </div> 
  <div class="cart-item-content">
    <h3>{{item.nome}}</h3>
    <div class="resumo">
      <ul>
        <li class="location">
          <div class="icon">
            <img src="/images/icons/carrinho/resumo/location.svg" alt="Location" />
          </div>
          <div class="text">
            <h4>{{item.macro_regiao}}</h4>
          </div>  
        </li>
        <li class="tipo">
          <div class="icon">
            <img src="/images/icons/carrinho/resumo/car.svg" alt="Tipo" />
          </div>
          <div class="text">
            <h4>{{item.tipo_servico_id | tipoServico}}</h4>
          </div>  
        </li>
        <li class="hotel">
          <div class="icon">
            <img src="/images/icons/carrinho/resumo/hotel.svg" alt="Hotel" />
          </div>
          <div class="text">
            <h4>{{item.nome_hotel}}</h4>
          </div>  
        </li>
        <li class="data">
          <div class="icon">
            <img src="/images/icons/carrinho/resumo/calendar.svg" alt="Calendário">
          </div>
          <div class="text">
            <h4>{{item.data_utilizacao | date: "dd-MM-yyyy"}}</h4>
          </div>  
        </li>
        <li>
          <div class="item totais">
            <div class="text">  
              <span>ADT: {{item.valor_adt | currency:"R$ "}} x {{item.qtd_adt}} = </span> <strong>{{(item.valor_adt*item.qtd_adt) | currency:"R$ "}}</strong><br>
              <span>CHD: {{item.valor_chd | currency:"R$ "}} x {{item.qtd_chd}} = </span> <strong>{{(item.valor_chd*item.qtd_chd) | currency:"R$ "}}</strong><br>
              <span>TOTAL: </span><strong>{{(item.valor_adt*item.qtd_adt)+(item.valor_chd*item.qtd_chd) | currency: "R$ "}}</strong>
            </div>  
          </div> 
        </li>  
      </ul>  
    </div>
    <div class="footer">   
      <btn-delete-cart-item [id]="item.id" [nome]="item.nome"></btn-delete-cart-item>
    </div>  
  </div> 
</div>
