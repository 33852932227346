<section class="modulo-loader">
  <div class="loader">
    <div class="estrela animated infinite pulse">
      <img src="/images/icons/alltour/mascote/carro.png" alt="Loader - Mascote" width="100px" />
    </div>  
    <strong class="message">
      {{message}}
    </strong>
  </div>
  <div class="overlay"></div> 
</section>
  