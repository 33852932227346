import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from '../../../services/cart.service';
import { DataService } from '../../../services/data.service';

@Component({
  selector: 'dm-cart-total',
  templateUrl: './total-carrinho.component.html',
  styleUrls: ['./total-carrinho.component.scss']
})
export class TotalCarrinhoComponent implements OnInit {

  public total  = 0;
  public qtd    = 0;
  public valor1 = 0;
  public valor2 = 0;

  constructor(
    private route: Router,
    private _cart: CartService,
    private _data: DataService
  ) { }

  /**
   * 
   * 
   * 
   */
  toCart(){

    this.route.navigateByUrl("/carrinho"); 

  }
  /**
   * 
   * 
   * 
   */
  setValor(){

    let number:any = this.total.toString();

    number = number.split(".");

    this.valor1 = number[0];

    if(typeof(number[1]) != "undefined"){

      this.valor2 = number[1];

    }

  }
  /**
   * 
   * Ouvintes
   * 
   */
  onData(){

    this._cart.emitirUpdateTotalCart.subscribe(data => {
      this.setTotal(data);
      this.setValor();
    })

  }
  /**
   * 
   * Seta o total
   * 
   * @param data 
   * 
   */
  setTotal(data){
    this.total = data.total;
    this.qtd   = data.qtd; 
    this._data.setTotalCart(data);
  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){

    let dataTotal = this._data.getTotalCart();
    
    if(dataTotal != null){
      this.setTotal(dataTotal);
      this.setValor();
    }
    this.onData();
  }

}
