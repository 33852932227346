import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'modulo-menu-footer',
  templateUrl: './menu-footer.component.html',
  styleUrls: ['./menu-footer.component.scss']
})
export class MenuFooterComponent implements OnInit {

  constructor() { }

  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){

  }

}
