<div class="mat-modal-content">
  <div class="mat-modal-header">
    <h5 mat-dialog-title>
      Atenção!
    </h5>
  </div>  
  <mat-dialog-content>
    Deseja retirar {{nome}} da sua mochila?
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="mat-dialog-actions-content">
      <button (click)="delete()" class="btn-one">
        Sim
      </button>
      <button class="btn-one" type="button"  mat-dialog-close>
        Não
      </button>
    </div>  
  </mat-dialog-actions>
</div>  
<modulo-loader data-message="Excluindo..." *ngIf="loader"></modulo-loader>