import { Pipe, PipeTransform } from '@angular/core';
import { DateService } from '../services/date.service';

@Pipe({
  name: 'eventoDateFormat'
})
export class EventoDateFormatPipe implements PipeTransform {

  constructor(
    private DateService: DateService
  ){

  }

  transform(evento: any, args?: any): any {
    
    let data_inicial  = evento.data_inicial;
    let data_final    = evento.data_final;
    let month_inicial = this.DateService.getMonth(data_inicial);
    let month_final   = this.DateService.getMonth(data_final);
    let year_inicial  = this.DateService.getYear(data_inicial);
    let year_final    = this.DateService.getYear(data_final);
    let day_inicial   = this.DateService.getDay(data_inicial);
    let day_final     = this.DateService.getDay(data_final);
    let month_inicial_str = this.DateService.getMonthString(data_inicial);

    if(data_inicial == data_final){
      return day_inicial+" de "+this.DateService.getMonthString(data_inicial)+" de "+year_inicial;
    }else if((month_inicial == month_final) && (year_inicial == year_final)){
      return day_inicial+" a "+day_final+" de "+month_inicial_str+" de "+year_inicial;
    }else if(year_inicial == year_final){
      return day_inicial+"/"+month_inicial+" a "+day_final+"/"+month_final+" de "+year_inicial;
    }else{
      return day_inicial+"/"+month_inicial+" de "+year_inicial+" a "+day_final+"/"+month_final+" de "+year_final;
    }

  }

}
