import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'modulo-destinos',
  templateUrl: './destinos.component.html',
  styleUrls: ['./destinos.component.scss']
})
export class DestinosComponent implements OnInit {

  @Input("data") data;
  @Input("template") template;
  public dataItem:any = {};
  public loader       = false;
  public destinos     = [];

  constructor() { }

  /**
   * 
   * Seta os destinos
   * 
   */
  setDestinos(){

    this.destinos = this.data.destinos; 

  }
  /**
   * 
   * Incializa as Funções
   * 
   */
  ngOnInit(){
    this.setDestinos();
  }

}
