<div class="dm-cart-passageiro-titular">
  <div class="titulo">
    <h3>TITULAR DA COMPRA</h3>
  </div> 
  <div class="content"> 
    <form class="form form-custom">
      <div class="row">
        <div class="col-md-8 col-7">
          <mat-select [(ngModel)]="passageiro_id" name="passageiro_titular">
            <mat-option [value]="passageiro.id" *ngFor="let passageiro of passageiros;let i=index;">
              {{passageiro.nome}} {{passageiro.sobrenome}}
            </mat-option>
          </mat-select>  
        </div>
        <div class="col-md-4 col-5">
          <button type="button" class="btn-custom-three" (click)="update()">
            ALTERAR
          </button>  
        </div>  
      </div>
      <div class="subscribe-message" *ngIf="subscribeMessage != null" [innerHtml]="subscribeMessage"></div>   
    </form>  
  </div>  
</div>
<modal-info [show]="message.show" [customClass]="message.class" [message]="message.data" (hide)="message.show=false"></modal-info>
<modulo-loader [message]="messageLoader" *ngIf="loader"></modulo-loader>
