<div class="modulo-footer-contato">
  <div class="container">
    <div class="row">
      <div class="col-lg-7 col-md-6">
        <modulo-position position="contatos" template="footer-contato"></modulo-position>
      </div> 
      <div class="col-lg-5 col-md-6 border-on">
        <div class="content-redes-sociais">
          <modulo-position class="redes-sociais" position="contatos" template="footer-contato-redes-sociais"></modulo-position>
          <modulo-position position="selo-trip" classCustom="footer-contato"></modulo-position>
        </div>  
      </div>  
    </div>  
  </div>  
</div>
