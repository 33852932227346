<div class="modulo">
  <div class="modulo-titulo"> 
    <h3>FORMAS DE PAGAMENTO</h3>
  </div>
  <div class="modulo-content"> 
    <ul>
      <li>
        <figure>
          <img defaultImage="/images/pixel/bg.png" lazyLoad="/images/icons/alltour/formas-de-pagamento/visa.svg" alt="Visa" />
        </figure>  
      </li> 
      <li>
        <figure>
          <img defaultImage="/images/pixel/bg.png" lazyLoad="/images/icons/alltour/formas-de-pagamento/mastercard.svg" alt="Mastercard" />
        </figure>  
      </li>
      <li>
        <figure>
          <img defaultImage="/images/pixel/bg.png" lazyLoad="/images/icons/alltour/formas-de-pagamento/american-express.svg" alt="American Express" />
        </figure>  
      </li> 
      <li>
        <figure>
          <img defaultImage="/images/pixel/bg.png" lazyLoad="/images/icons/alltour/formas-de-pagamento/dinners-club.svg" alt="Dinners Club" />
        </figure>  
      </li> 
      <li>
        <figure>
          <img defaultImage="/images/pixel/bg.png" lazyLoad="/images/icons/alltour/formas-de-pagamento/cabal.png" alt="Cabal" />
        </figure>  
      </li> 
      <li>
        <figure>
          <img defaultImage="/images/pixel/bg.png" lazyLoad="/images/icons/alltour/formas-de-pagamento/elo.png" alt="Elo" />
        </figure>  
      </li> 
      <li>
        <figure>
          <img defaultImage="/images/pixel/bg.png" lazyLoad="/images/icons/alltour/formas-de-pagamento/union-pay.svg" alt="Union Pay" />
        </figure>  
      </li>
      <li>
        <figure>
          <img defaultImage="/images/pixel/bg.png" lazyLoad="/images/icons/alltour/formas-de-pagamento/hiper.jpg" alt="Hiper" />
        </figure>  
      </li>
      <li>
        <figure>
          <img defaultImage="/images/pixel/bg.png" lazyLoad="/images/icons/alltour/formas-de-pagamento/mais.png" alt="Mais" />
        </figure>  
      </li> 
      <li>
        <figure>
          <img defaultImage="/images/pixel/bg.png" lazyLoad="/images/icons/alltour/formas-de-pagamento/hipercard.png" alt="Hipercard" />
        </figure>  
      </li> 
      <li>
        <figure>
          <img defaultImage="/images/pixel/bg.png" lazyLoad="/images/icons/alltour/formas-de-pagamento/sorocred.png" alt="Sorocred" />
        </figure>  
      </li>
      <li>
        <figure>
          <img defaultImage="/images/pixel/bg.png" lazyLoad="/images/icons/alltour/formas-de-pagamento/jbc.png" alt="Jbc" />
        </figure>  
      </li>
      <li>
        <figure>
          <img defaultImage="/images/pixel/bg.png" lazyLoad="/images/icons/alltour/formas-de-pagamento/banes-card.png" alt="Banes Card" />
        </figure>  
      </li>
      <li>
        <figure>
          <img defaultImage="/images/pixel/bg.png" lazyLoad="/images/icons/alltour/formas-de-pagamento/creaoz.png" alt="Creaoz" />
        </figure>  
      </li>  
    </ul>  
  </div>  
</div>
