import { Component, OnInit } from '@angular/core';
import { ConteudoService } from '../../services/conteudo.service';
import { AppService } from '../../services/app.service';
import { ApiService } from '../../services/api.service';
import { StorageService } from '../../services/storage.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'consultar-reserva-page',
  templateUrl: './consultar-reserva-page.component.html',
  styleUrls: ['./consultar-reserva-page.component.scss']
})
export class ConsultarReservaPage implements OnInit { 

  public loader   = false;
  private apelido = "consultar-reserva";
  public response:any = {
    data: "",
    status: null
  };
  public page         = null;
  public fotos        = []; 
  public params       = {};
  public dataItem:any = {};
  public message = {
    data: "",
    class: "",
    show: false
  };
  public dataFile = null;
  public dataResponse = {
    file: null,
    passageiros: [],
    programacao: []
  }
  public messageLoader = "Carregando Conteúdo";

  constructor(
    private conteudo:ConteudoService,
    private app: AppService,
    private api: ApiService,
    private storage: StorageService,
    private route: ActivatedRoute
  ){ 

  }
  /**
   * 
   * 
   * 
   * 
   */
  getData(){

    try{
      
      this.loader = true;
      
      this.conteudo.getPageByApelido(this.apelido).subscribe(response => {
      
        this.loader = false;
      
        if(response.status == 1){
                
          if(typeof(response.data.page) != "undefined"){
                  
            this.page      = typeof(response.data.page.data[0]) != "undefined" ? response.data.page.data[0] : response.data.page.data;
            this.params    = JSON.parse(this.page.params);
            this.fotos     = response.data.page.fotos;
            this.app.setMetaDados(this.page.meta_title,this.page.meta_description,this.page.meta_keywords);
            this.verificaParams();
          }
          this.response = {
            data: "",
            status: 1
          }
      
      
        }else{
          this.response = {
            data: response.data,
            status: 500
          }
        }
      
      },(err) =>{
      
        this.loader = false;
        this.response = err;
      
      });
    
    }catch(e){
      
    }

  }
  getDataFile(){

    try{

      this.loader        = true;
      this.messageLoader = "Buscando dados da sua reserva.";

      this.api.manage().consultarReserva(this.dataItem).subscribe(response => {

        this.loader = false;
        let errors  = null;

        switch(response.status){
          case 1:
            this.dataFile = response.data;
            this.setParams(this.dataFile);
            this.storage.setDataFile(this.dataItem,this.dataFile);
          break;
          case 0:

            errors = this.app.getErrors([response.data]);
    
            this.message.data  = errors;
            this.message.class = "alert alert-warning";
            this.message.show  = true;

          break;
          case 3:

            this.message.data  = response.data;
            this.message.class = "alert alert-warning";
            this.message.show  = true;

          break;
          default:

            this.message.data  = "Houve um erro interno. Se o erro permanecer informe nossa equipe.";
            this.message.class = "alert alert-danger";
            this.message.show  = true;

          break;
        }

      },(response) =>{

        this.loader = false;
        let error          = this.app.formatError(response);

        this.message.data  = error.message;
        this.message.class = "alert alert-danger";
        this.message.show  = true;

      });
    

    }catch(e){

      this.loader = false;

    }

  }
  /**
   * 
   * Init dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      file: null,
      ultimonome: null
    }
    let dataStorage = this.storage.getDataFile();

    if(dataStorage != null){
      this.dataItem = dataStorage.dataItem;
      this.dataFile = dataStorage.response;
      this.setParams(this.dataFile);
    }

  }
  /**
   * 
   * Seta os parâmetros 
   * 
   */
  setParams(dataFile){

    this.dataResponse.file = { 
      idFile: dataFile.idFile,
      paxTitular: dataFile.paxTitular, 
      qtdPaxs: dataFile.qtdPaxs,
      dataInicio: dataFile.dataInicioServicos,
      dataFim: dataFile.dataFimServicos,
      cidade: dataFile.cidadeFile,
      hotel: dataFile.nomeHotelFile,
    }
    if(typeof(this.dataFile.paxsFile) != "undefined"){
      if(typeof(this.dataFile.paxsFile.paxFile) != "undefined"){
        this.dataResponse.passageiros = typeof(this.dataFile.paxsFile.paxFile[0]) == "undefined" ? [this.dataFile.paxsFile.paxFile] : this.dataFile.paxsFile.paxFile;
      }
    }
    if(typeof(this.dataFile.programacaoFile) != "undefined"){ 
      if(typeof(this.dataFile.programacaoFile.Servico) != "undefined"){
        
        this.dataResponse.programacao = typeof(this.dataFile.programacaoFile.Servico[0]) == "undefined" ? [this.dataFile.programacaoFile.Servico] : this.dataFile.programacaoFile.Servico;
        
        for (let index = 0;index < this.dataResponse.programacao.length;index++) {
          
          this.dataResponse.programacao[index].show_passageiros = false;
          
        }

      }
    }

  }
  /***
   * 
   * Verifica se tem parametros e pesquisa automaticamente
   * 
   */
  verificaParams(){

    this.route.queryParams.subscribe(params => {
      
      let hasFile = false;
      let hasNome = false;

      if(params.file){
        this.dataItem.file      = params.file;
        hasFile = true;
      }
      if(params.ultimonome){
        this.dataItem.ultimonome = params.ultimonome;
        hasNome = true;
      }
      if(hasFile && hasNome){
        this.getDataFile(); 
      }

    });

  }
  /**
   * 
   * Retorna os passageiros do serviço
   * 
   */
  getPassageirosServico(servico){

    let passageiros = [];

    if(typeof(servico.paxsServicos) != "undefined"){

      if(typeof(servico.paxsServicos.paxServico) != "undefined"){

        passageiros = typeof(servico.paxsServicos.paxServico[0]) == "undefined" ? [servico.paxsServicos.paxServico] : servico.paxsServicos.paxServico;

      }

    }

    return passageiros;

  }
  showPassageiros(servico){

    servico.show_passageiros = servico.show_passageiros == true ? false : true; 

  }
  /**
   * 
   * Iniciliza as Funções
   * 
   */
  ngOnInit(){
    this.initDataItem();
    this.getData();
  }

}
