<section class="modulo modulo-trip-advisor">
  <div class="row">
    <div class="col-md-6">
      <div class="modulo-title" *ngIf="data.mostrar_titulo">
        <h3>{{data.titulo}}</h3>
        <h4>{{data.params.info}}</h4>
      </div>  
    </div>
    <div class="col-md-6">  
      <div class="modulo-content">
        <div id="TA_certificateOfExcellence707" class="TA_certificateOfExcellence"><ul id="4bI6SL7" class="TA_links zFsypxas6sb"><li id="syhFwuhx" class="7SQo3Vcp"><a target="_blank" href="https://www.tripadvisor.com.br/Attraction_Review-g671558-d7116812-Reviews-Sao_Paulo_Ecoturismo-Barreirinhas_State_of_Maranhao.html"><img src="https://www.tripadvisor.com.br/img/cdsi/img2/awards/CoE2017_WidgetAsset-14348-2.png" alt="TripAdvisor" class="widCOEImg" id="CDSWIDCOELOGO"/></a></li></ul></div><script async src="https://www.jscache.com/wejs?wtype=certificateOfExcellence&amp;uniq=707&amp;locationId=7116812&amp;lang=pt&amp;year=2019&amp;display_version=2" data-loadtrk onload="this.loadtrk=true"></script>
      </div>  
    </div>
  </div>    
</section>
  