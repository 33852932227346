<div class="modal" tabindex="-1" role="dialog" #modal>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{title}}</h5>
      </div>
      <div class="modal-body">
        O serviço {{nome}}, foi adicionado ao carrinho :)
      </div>
      <div class="modal-footer">
        <div class="modal-footer-content">
          <button type="button" class="btn-one" (click)="toFinish()">
            Finaliza a Compra
          </button>
          <button type="button" class="btn-one" (click)="toCart()">
            Ir para o Carrinho
          </button>
          <button type="button" class="btn-one" (click)="toHome()">
            Continuar Comprando
          </button>
        </div>  
      </div>
    </div>
  </div>
</div>