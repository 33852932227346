import { AppSettings } from './../../class/app-settings';
import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router, NavigationStart,Event as NavigationEvent } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from '../../services/data.service';

declare var $:any;

@Component({
  selector: 'modulo-menu',    
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit,AfterViewInit {
  

  @Input("classCustom") classCustom; 
  @ViewChild("menu") menu: ElementRef;
  public classAnimate = "";
  public classColor   = ""; 
  public name         = "";
  public categorias   = [];
  public regiao_menu  = [];
  
  constructor(
    private router: Router,
    public modal: MatDialog,
    private _data: DataService
  ) { 
    this.name = AppSettings.NAME;
  }

  /**
   * 
   * Fecha o Menu Mobile
   * 
   */
  close(){
    
    this.classAnimate = "slideOutLeft";
    setTimeout(() =>{
      this.classColor   = ""; 
    },100);
  
  }
  /**
   * 
   * Abre o Menu Mobile
   * 
   */
  open(){
    this.classAnimate = "slideInLeft";
    this.classColor   = "hide";
  }
  onEvents(){
    
    this.router.events.forEach((event:NavigationEvent) =>{
      
      if(this.classCustom == "menu-mobile"){
        if(event instanceof NavigationStart){
          this.close()
        }
      }
        
    });

  }
  sectionScroll(){
    
   let router = this.router;

    let classSelf = this;
    var lastId,
    topMenu = $("header"),
    topMenuHeight = topMenu.outerHeight();
    var item;
    var menuItems = $(this.menu.nativeElement).find("a.on");
    
    var scrollItems = menuItems.map(function(){
      
      let href = $(this).attr("href");
      if(href.substring(0,1) == "#"){
        item = $(href);
        if (item.length) { return item; }
      }
    
    });
    $(window).scroll(function(){
      
      var fromTop = $(this).scrollTop()+topMenuHeight;
      var cur = scrollItems.map(function(){
        if ($(this).offset().top < fromTop)
          
          return this;
      });
 
      cur = cur[cur.length-1];
      var id = cur && cur.length ? cur[0].id : "";
          
      if (lastId !== id) {
        lastId = id;

        if(router.url == "/"){
          
          menuItems.removeClass("active")
          .filter('[href="#'+id+'"]')
          .addClass("active");

        }else{
          
          setTimeout(() => {
            $(menuItems).removeClass("active");
          },500); 

        }  
        
      }
  

    });

    $(this.menu.nativeElement).find("a.on").click(function(e){
      
    
      var el = $(this);
      
      if(router.url != "/"){
        
        router.navigate(["/"]);
        setTimeout(()=>{
          classSelf.toSection(el,topMenuHeight);
        },500);

      }else{
        classSelf.toSection(el,topMenuHeight);
      }
      e.preventDefault();
    });

  }
  toSection(e,topMenuHeight){
   
    var id = e.attr("href");
    var offsetTop = $(id).offset().top;
    var offset = e.offset();
    
    $('html, body').stop().animate({ 
      scrollTop: offsetTop-(topMenuHeight+85)
    }, 500);

  }
  setInitActive(){
    
    if(this.router.url == "/"){
      $(this.menu.nativeElement).find("a").first().addClass("active");
    }

  }
  /**
   * 
   * 
   * 
   */
  onClickShowSubitens(){
    
    $(this.menu.nativeElement).find(".has-sub-itens").find(".a-first").click(function(e){
      
      let ul = $(this).parent().find("ul.sub-itens").first();  

      if(!$(ul).hasClass("show")){
        $(ul).show().addClass("show");
      }else{
        $(ul).hide().removeClass("show");
      }
        
      e.preventDefault(); 
      return false;

    });

  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit() {

      
  }
  ngAfterViewInit() {

    this.setInitActive();
    setTimeout(() =>{
      this.sectionScroll();
    },200);  

    this.onEvents();
    this.regiao_menu = this._data.getRegiaoMenu();

    if(this.classCustom == "menu-mobile"){
      this.classAnimate = "hide";
      this.onClickShowSubitens();
    }

  }

}
