import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { EventoService } from '../../../services/evento.service';
import { AppService } from '../../../services/app.service';

@Component({
  selector: 'evento-search',
  templateUrl: './evento-search.component.html',
  styleUrls: ['./evento-search.component.scss']
})
export class EventoSearchComponent implements OnInit {

  @Output("updateEventos") updateEventos = new EventEmitter();
  @Input("reload") reload  = new EventEmitter();

  public dataItem:any = {
    titulo: "",
    status: 1
  }
  public comboStatus = [{
    text: "PRÓXIMOS EVENTOS",
    value: 1
  },{
    text: "EVENTOS ANTERIORES",
    value: 2
  }];
  public loader  = false;
  public eventos = [];
  public total   = 0;


  constructor(
    private evento: EventoService,
    private app: AppService
  ){ }

  /**
   * 
   * 
   * 
   */
  getData(page=null){

    try{

      this.loader = true;

      if(page != null){
        this.dataItem.page = page;
      }

      this.evento.getEventos(this.dataItem).subscribe(response => {

        this.loader = false;

        if(response.status == 1){
          
          this.eventos = response.data.data;
          this.total   = response.data.total;
          this.evento.setEventosLocal(
            this.eventos,
            response.data.total,
            response.data.params
          );
          this.updateEventos.emit({
            eventos: this.eventos,
            total: this.total,
            page: response.data.params.page,
            limit: response.data.params.limit,
            status: this.dataItem.status
          });

          if(page != null){
            this.app.toSection("#page-eventos-lista-de-eventos");
          }

        }else{

        }

      },(response) => {

        this.loader = false;

      });

    }catch(e){

      this.loader = false;

    } 

  }
  verificaDataLocal(){

    let data:any = this.evento.getEventosLocaL();

    if(data == null){
     
      this.getData();

    }else{

      this.dataItem = data.params;
      this.eventos  = data.eventos;
      this.total    = data.total;
      
      this.updateEventos.emit({
        eventos: this.eventos,
        total: this.total,
        page: data.params.page,
        limit: data.params.limit,
        status: data.params.status
      });

    }

  }
  onData(){

    this.reload.subscribe(page => {

      this.getData(page);

    });

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit() {
    this.verificaDataLocal();
    this.onData();
  }

}
