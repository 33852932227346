import { AppService } from './../../../services/app.service';
import { element } from 'protractor';
import { ModulosService } from './../../../services/modulos.service';
import { AppSettings } from './../../../class/app-settings';
import { Component, OnInit, Input, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'modulo-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.scss']
})
export class ContatoComponent implements OnInit,AfterViewInit {
  
  @Input("data") data;
  @Input("template") template;
  @ViewChild("messageContato") messageContato: ElementRef;
  @ViewChild("telefoneNumero") telefoneNumero: ElementRef;
  public loader = false;
  public dataItem:any = {};
  public name = AppSettings.NAME; 
  public message = { 
    data: "",
    class: "",
    show: false
  };

  constructor(
    private ModulosService: ModulosService,
    private AppService: AppService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) { 
    this.initDataItem();
  }

  /**
   * 
   * Envia o contato por email
   * 
   * 
   */
  send(){

    try{

      this.loader        = true;
      this.message.show  = false;

      this.recaptchaV3Service.execute('importantAction')
        .subscribe((token) => {

          this.dataItem.token = token; 

          this.ModulosService.sendContato(this.dataItem).subscribe(response => {

            this.loader = false;

            switch(response.status){
              case 1:

                this.initDataItem();
                this.message.data  = "Sua mensagem foi enviada! Em breve entraremos em contato.";
                this.message.class = "alert alert-success";
                this.message.show  = true;

              break;
              case 0:

                let errors = this.AppService.getErrors([response.data]);
        
                this.message.data  = errors;
                this.message.class = "alert alert-warning";
                this.message.show  = true;

              break;
              default:

                this.message.data  = "Houve um erro interno. Se o erro permanecer informe nossa equipe.";
                this.message.class = "alert alert-danger";
                this.message.show  = true;

              break;
            }

          }, (err) =>{

            this.loader = false;
            this.message.data  = "Houve um erro interno. Se o erro permanecer informe nossa equipe.";
            this.message.class = "alert alert-danger";
            this.message.show  = true;

          });      

        },(response) => { 

          this.loader   = false;
          this.AppService.info("Token de validação inválido. Recarregue a página para continuar.");
  
        });

    }catch(e){ 

    }

  }
  /**
   * 
   * Inicializa o dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      nome: "",
      email: "",
      telefone_ddd: "",
      telefone_numero: "",
      assunto: "",
      mensagem: ""
    }

  }
  onInputDDD($event){

    if(this.dataItem.telefone_ddd.length == 2){
      this.telefoneNumero.nativeElement.focus(); 
    }

  } 
  /**
   * 
   * Inicializa s Funções
   * 
   * 
   */
  ngOnInit() {
    
  }
  ngAfterViewInit(){
    
  }

}
