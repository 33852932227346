<div *ngIf="ativo">
    <modulo-loader *ngIf="loader"></modulo-loader> 
    <section class="app-container" [hidden]="loader" *ngIf="statusCode == 1">
        <modulo-header></modulo-header>
        <router-outlet id="page-main"></router-outlet>
        <modulo-position position="newsletter"></modulo-position>
        <modulo-footer></modulo-footer>
    </section>  
    <section class="app-container" *ngIf="statusCode == 500">
        <modulo-error-servidor></modulo-error-servidor>
    </section>   
</div> 
<div *ngIf="!ativo"> 
    <site-desabilitado-page></site-desabilitado-page>
</div>   
