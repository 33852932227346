<div class="md-select-custom not-placeholder" (click)="_getData()">
  <label>{{label}}</label>
  <mat-select [(ngModel)]="value" name="value" #select (change)="_change()">
    <mat-option [value]="value"  *ngIf="response.data.length == 0 && value != null">
      {{text}}
    </mat-option>
    <mat-option [value]="d.idMacroRegiao" *ngFor="let d of response.data"> 
      {{d.macroRegiao}}
    </mat-option>
  </mat-select>
  <div class="alert alert-info alert-message" *ngIf="loader">
    Carregando... Aguarde!
  </div>  
  <div class="alert alert-danger alert-message" *ngIf="response.status == 500">
    <span>{{response.message}}</span>  
  </div>
</div>