import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ApiService } from '../../../../services/api.service';
import { AppService } from '../../../../services/app.service';

@Component({
  selector: 'dm-cart-pagamento-dados-comprador',
  templateUrl: './dm-cart-pagamento-dados-comprador.component.html',
  styleUrls: ['./dm-cart-pagamento-dados-comprador.component.scss']
})
export class DmCartPagamentoDadosCompradorComponent implements OnInit {

  @Input("dataItem") dataItem:any = {};
  @Input("onSave") onSave         = new EventEmitter();
  @Output("onSuccess") onSuccess  = new EventEmitter(); 
  public loader                   = false;
  public message                  = "Salvando os Dados. Aguarde!"
  public messageInfo = {
    show: false,
    class: "",
    data: ""
  }
  public subscribeMessage = null;

  constructor(
    private _api: ApiService,
    private _app: AppService
  ){ }
  
  /**
   * 
   * Salva os dados do comprador
   * 
   */
  save(){

    try{

      this.loader           = true;
      this.subscribeMessage = null;

      this._api.cart().dados_comprador(this.dataItem).subscribe(response => {

        this.loader = false;
        
        switch(response.status){
          case 1:
            this.onSuccess.emit(this.dataItem);
          break;
          default:
            let messageError = this._app.formatError(response);
            this.subscribeMessage = messageError.message;
          break;
        }
        
      },(response) => {

        this.loader      = false;
        let messageError = this._app.formatError(response); 
        
        this._app.info(messageError.message);
        


      });

    }catch(e){

      this.loader = false;

    }

  }
  /**
   * 
   * Ouvintes
   * 
   */
  onData(){

    this.onSave.subscribe(data => {
      this.save();
    });

  }
  /**
   * 
   * Iniciliza as Funções
   * 
   */
  ngOnInit(){
    this.onData();
  }

}
