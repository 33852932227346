<div class="dm-cart-dados-voo">
  <div class="dm-cart-dados-voo-content">
    <form class="dm-form">
      <div class="row">
        <div class="col-md-6">
          <combo-aeroporto [text]="dataItem.aeroporto" [value]="dataItem.aeroporto" (changeValue)="changeAeroporto($event)"></combo-aeroporto>
        </div>   
        <div class="col-md-6">
          <combo-cia-aerea [text]="dataItem.cia_aerea_text" [value]="dataItem.cia_aerea_codigo" (changeValue)="changeCia($event)"></combo-cia-aerea>
        </div>  
      </div> 
      <div class="row"> 
        <div class="col-md-6">
          <div class="dm-form-item">
            <label>Nº DO VÔO:</label>
            <input type="text" [(ngModel)]="dataItem.voo" name="voo" integer />
          </div> 
        </div>  
      </div>  
    </form>  
  </div>  
</div>
