import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class CartService {

  public emitirDeleteItem      = new EventEmitter<any>();
  public emitirUpdateTotalCart = new EventEmitter<any>(); 

  constructor(private http: HttpClient){ }

  /**
   * 
   * Retorna os dados do carrinho
   * 
   */
  getCart(){

    //let url = ApiComponentesSettings;
    //return this.http.get(url).map(response=>response.json())

  }
  /**
   * 
   * Deleta um item do carrinho
   * 
   * @param id 
   * 
   */
  delete(id){

    //let url = CartSettings.BASE+"/"+id;
    //return this.http.delete(url).map(response=>response.json())

  }
  /**
   * 
   * Retorna a Lista de Passageiros
   * 
   * @param tipo 
   * 
   */
  getListPaxs(tipo=null){
    
    //let url = CartSettings.LIST_PAXS+"/"+tipo;
    //return this.http.get(url).map(response=>response.json())
    
  }
  /**
   * 
   * Finaliza a Venda
   * 
   * 
   * @param params 
   *  
   */
  finishSales(params){
    
    //let url = CartSettings.FINISH_SALES;
    //return this.http.post(url,params).map(response=>response.json())
    
  }
  /**
   * 
   *  Inseri um item no carrinho
   * 
   * @param params
   * 
   */
  insert(params){
    
    //let url = CartSettings.BASE;

    //return this.http.post(url,params).map(response=>response.json())
  }
  deleteItemInCartEmit(id){
    
    this.emitirDeleteItem.emit(id);
  
  }
  /**
   * 
   * Esvazia o Carrinho
   * 
   */
  esvaziar(){
    
    //let url = CartSettings.CLEAR_CART;
        
    //return this.http.post(url,{}).map(response=>response.json())
    
  }
  /**
   * 
   * Retorna do carrinho com pagamento
   * 
   */
  getCartPagamento(){

    //let url = CartSettings.GET_CART_PAGAMENTO;
   // return this.http.get(url).map(response=>response.json())

  }
  /**
   * 
   *  Inseri um item no carrinho
   * 
   * @param params
   * 
   */
  saveDadosComprador(params){
    
      //let url = CartSettings.SAVE_DADOS_COMPRADOR;

     // return this.http.post(url,params).map(response=>response.json())
  }
  /**
   * 
   *  Gera o boleto das taxas de inscrição
   * 
   * @param params
   * 
   */
  gerarBoletoTaxas(){
    
     // let url = CartSettings.GERAR_BOLETO_TAXAS;

     // return this.http.post(url,{}).map(response=>response.json())
  }
  /**
   * 
   * Esvazia o Carrinho
   * 
   */
  abandonarCompra(){
    
    //let url = CartSettings.ABANDONAR_COMPRA;
        
    //return this.http.post(url,{}).map(response=>response.json())
    
  }

}
