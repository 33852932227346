<div class="alert alert-warning" role="alert">
  <h4>Houve um erro inesperado!</h4> 
  <p>
    Caso o erro persista entre em contato com a nossa equipe.
  </p>  
  <p>
    <a title="Recarregar" href="javascript:void()" class="btn-one btn-icon btn-reload" (click)="reloadPage()">
      <i class="material-icons">&#xE042;</i>
      <span>RECARREGAR</span>
    </a>  
  </p>  
</div>