<div class="dm-cart-pagamento-cielo">
  <div class="dm-cart-pagamento-cielo-content">
    <form class="form dm-form" autocomplete="off">
      <div class="row">
        <div class="col-md-6">
          <div class="dm-form-item">
            <label for="dataItem.cartao.numero_cartao">Número do Cartão de Crédito</label>
            <input type="text" [(ngModel)]="dataItem.cartao.numero_cartao" name="dataItem.cartao.numero_cartao" class="input-cartao-numero" integer maxlength="19" (input)="setBrand()" [ngStyle]="{'background-image': 'url(' + dataItem.cartao.brand_logo + ')'}" />
          </div>  
        </div>
        <div class="col-md-6">
          <div class="dm-form-item">
            <label for="dataItem.cartao.nome_portador">Titular do Cartão</label>
            <input type="text" [(ngModel)]="dataItem.cartao.nome_portador" name="dataItem.cartao.nome_portador" uppercase />
          </div>
        </div>  
      </div> 
      <div class="row">
        <div class="col-md-3">
          <div class="dm-form-item">
            <label for="dataItem.cartao.data_validade">Validade</label>
            <input type="text" [(ngModel)]="dataItem.cartao.data_validade" name="dataItem.cartao.validade" placeholder="MM/AA" class="input-custom-two" mask="99/99"/>
          </div>  
        </div>
        <div class="col-md-3">
          <div class="dm-form-item">
            <label for="dataItem.cartao.codigo_seguranca">Cód. segurança</label>
            <input type="text" [(ngModel)]="dataItem.cartao.codigo_seguranca" name="dataItem.cartao.codigo_seguranca" class="input-custom-two" integer />
          </div>  
        </div>
        <div class="col-md-6">
          <combo-parcelas-cartao [total]="total" (changeValue)="changeValueParcela($event)" [max]="formaPagamento.numero_max_parcelas"></combo-parcelas-cartao>
        </div>  
      </div>  
    </form>  
  </div>  
</div>
<modulo-loader [message]="message" [hidden]="!loader"></modulo-loader>
<modal-info [title]="messageInfo.title" [show]="messageInfo.show" [customClass]="messageInfo.class" [message]="messageInfo.data" (hide)="messageInfo.show=false"></modal-info>
  
  