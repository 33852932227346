<div class="valor {{classCustom}}" itemprop="offers" itemscope itemtype="http://schema.org/Offer">
  <div class="valor-number" *ngIf="valor > 0">
    <span class="a-partir">a partir de</span>
    <span class="cifrao" itemprop="priceCurrency">R$</span>
    <span class="valor" itemprop="price">{{valor}}</span>
  </div>
  <div class="valor-number" *ngIf="valor == 0">
    <span class="a-partir">a partir de</span>
    <span style="display:none;" class="cifrao" itemprop="priceCurrency">R$</span>
    <span style="display:none;" class="valor" itemprop="price">{{valor}}</span>
    <span class="consulte">CONSULTE!</span>
  </div>
</div>
