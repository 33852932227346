<div class="dm-cart-pagamento-confirmacao-download">
  <div class="titulo">
    <h3>Para fazer o download do seu comprovante, clique no link abaixo:</h3>
  </div>
  <div class="content">  
    <button type="submit" class="btn-custom-four" (click)="_click()">
      <i class="fa fa-download"></i>
      <span>Download do Comprovante</span>
    </button>
  </div>  
</div>
