import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, NgZone } from '@angular/core';
import { ModulosService } from '../../services/modulos.service';
import { ConteudoService } from '../../services/conteudo.service';
import { AppService } from '../../services/app.service';

declare var $:any;

@Component({
  selector: 'contato-page',
  templateUrl: './contato-page.component.html',
  styleUrls: ['./contato-page.component.scss']
})
export class ContatoPageComponent implements OnInit,AfterViewInit {
  
  public loader   = false;
  private apelido = "contato";
  public response:any = {
    data: "",
    status: null
  };
  public page     = null;
  public fotos    = []; 
  public unidades = []; 
  @ViewChild("messageContato") messageContato: ElementRef;
  @ViewChild("telefoneNumero") telefoneNumero: ElementRef;
  @ViewChild("dataInicio") dataInicio: ElementRef;
  @ViewChild("dataFim") dataFim: ElementRef;
  public dataItem:any = {};
  public message = {
    data: "",
    class: "",
    show: false
  };
  public messageLoader = "";

  constructor(
    private conteudo:ConteudoService,
    private app: AppService,
    private modulo: ModulosService,
    private zone: NgZone
  ) { 
    this.initDataItem();
  }

  /**
   * 
   * Envia o contato por email
   * 
   * 
   */
  send(){

    try{

      this.loader        = true;
      this.message.show  = false;
      this.messageLoader = "Enviando os dados..."; 
      let message        = "";

      this.modulo.sendContato(this.dataItem).subscribe(response => {

        this.loader = false;

        switch(response.status){
          case 1:

            this.initDataItem();
            message  = "Sua mensagem foi enviada! Em breve entraremos em contato.";
            
            this.app.info(message,"success");

          break;
          case 0:

            let errors = this.app.getErrors([response.data]);
    
            this.app.info(errors,"danger");

          break;
          default:

            message  = "Houve um erro interno. Se o erro permanecer informe nossa equipe.";
            this.app.info(message,"danger");

          break;
        }

      }, (response) =>{

        this.loader        = false;
        let error          = this.app.formatError(response);

        this.app.info(error.message,"danger");

      });      

    }catch(e){

    }

  }
  /**
   * 
   * Inicializa o dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      nome: "",
      email: "",
      telefone_ddd: "",
      telefone_numero: "",
      assunto: "",
      nome_empresa: "",
      mensagem: "",
    }

  }
  /**
   * 
   * 
   * 
   * 
   */
  getData(){

    try{
      
      this.loader = true;
      this.messageLoader = "Carregando Conteúdo...";

      this.conteudo.getPageByApelido(this.apelido).subscribe(response => {
      
        this.loader = false;
      
        if(response.status == 1){
                
          if(typeof(response.data.page) != "undefined"){
                  
            this.page      = typeof(response.data.page.data[0]) != "undefined" ? response.data.page.data[0] : response.data.page.data;
            this.fotos     = response.data.page.fotos;
            this.unidades  = response.data.page.unidades;
            this.app.setMetaDados(this.page.meta_title,this.page.meta_description,this.page.meta_keywords);
              
          }
          this.response = {
            data: "",
            status: 1
          }
          
          setTimeout(() => {
            this.setDatePicker();
          },200);
      
        }else{
          this.response = {
            data: response.data,
            status: 500
          }
        }
      
      },(err) =>{
      
        this.loader = false;
        this.response = err;
      
      });
    
    }catch(e){
      
    }

  }
  onInputDDD($event){

    if(this.dataItem.telefone_ddd.length == 2){
      this.telefoneNumero.nativeElement.focus(); 
    }

  }
  more(){
    
    if(this.dataItem.quantidade !== "" || this.dataItem.quantidade !== null){
      this.dataItem.quantidade++;
    }else{
      this.dataItem.quantidade = 1;
    }

  } 
  less(){
    
    if(this.dataItem.quantidade !== "" || this.dataItem.quantidade !== null){
      if(this.dataItem.quantidade > 1){
        this.dataItem.quantidade--;
      }
    }else{
      this.dataItem.quantidade = 1;
    } 

  }
  /**
   * 
   * Seta Date Picker
   * 
   * 
   */
  setDatePicker(){

    let dataIn:any  = null;
    let dataOut:any = null;
    let self        = this;

    dataIn = $(this.dataInicio.nativeElement).datepicker({
      dateFormat: "dd-mm-yy",
      onSelect: (data) => {
        
        this.dataItem.data_in = data;
        dataOut.datepicker("option","minDate",data);
        this.zone.run(() => {});
      
      }
    });

    dataOut = $(this.dataFim.nativeElement).datepicker({
      dateFormat: "dd-mm-yy",
      onSelect: (data) => {
        
        this.dataItem.data_out = data;
        dataIn.datepicker("option","maxDate",data);
        this.zone.run(() => {});

      }
    });

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.app.toTop(); 
    this.getData();
  }
  setDataIn(value){

    this.dataItem.data_in = value.target.value;

  }
  setDataOut(value){

    this.dataItem.data_out = value.target.value;

  }
  /**
   * 
   * Iniciliza as Funções depois que a view carregou
   * 
   */
  ngAfterViewInit():void{
    
  }

}
