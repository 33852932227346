import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dm-cart-pagamento-steps',
  templateUrl: './dm-cart-pagamento-steps.component.html',
  styleUrls: ['./dm-cart-pagamento-steps.component.scss']
})
export class DmCartPagamentoStepsComponent implements OnInit {

  @Input("step") step = 1;

  constructor() { }

  /**
   * 
   * 
   * 
   */
  ngOnInit(){
    
  }

}
