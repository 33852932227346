import { AppService } from '../../../services/app.service';
import { CartService } from '../../../services/cart.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../../../services/api.service';

@Component({
  selector: 'cart-dados-form-update',
  templateUrl: './cart-dados-form-update.component.html',
  styleUrls: ['./cart-dados-form-update.component.scss']
})
export class CartDadosFormUpdateComponent implements OnInit {

  @Input("dataItem") dataItem = null;
  @Output("onSaved") onSaved  = new EventEmitter();

  public loader = false;
  public messageResponse = {
    show: false,
    message: "",
    class: "alert alert-success"
  }

  constructor(
    public cart: CartService,
    public app: AppService,
    private api: ApiService
  ){}

  /**
   * 
   * Salva os dados
   * 
   */
  save(){

    try{

      this.loader = true;

      this.api.cart().dados_comprador(this.dataItem).subscribe(response => {

        this.loader = false;

        switch(response.status){
          case 1:

            this.messageResponse.message = "Os dados foram salvos";
            this.messageResponse.class   = "alert alert-success";
            this.messageResponse.show    = true;
            this.onSaved.emit(this.dataItem);

          break;
          case 0:

            let errors = this.app.getErrors([response.data])
            this.messageResponse.message = errors;
            this.messageResponse.class   = "alert alert-danger";
            this.messageResponse.show    = true;

          break;
          default:

            this.messageResponse.message = response.data;
            this.messageResponse.class   = "alert alert-danger";
            this.messageResponse.show    = true;

          break;
        }

      },(response) =>{

        this.loader = false;

        this.messageResponse.message = "Houve um erro interno.";
        this.messageResponse.class   = "alert alert-danger";
        this.messageResponse.show    = true;


      });

    }catch(e){

      this.messageResponse.message = e.message;
      this.messageResponse.class   = "alert alert-danger";
      this.messageResponse.show    = true;

    }

  }
  /**
   * 
   * Retorna a lista de passageiros
   * 
   */
  getListPaxs(){

    try{

      this.loader = true

      this.api.cart().lista_paxs().subscribe(response=>{
        
        this.loader = false
        
        if(response.status == 1){
          //this.pushListPaxs(response.data)
          //this.createInputs()
        }

      },(err)=>{
        this.loader = false
      })

    }catch(err){
      this.loader = false
    }

  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    this.getListPaxs();
  }

}
