import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { CartService } from "../../../services/cart.service";
import { ApiService } from '../../../services/api.service';
import { AppService } from '../../../services/app.service';

@Component({
  selector: 'modal-confirm-delete',
  templateUrl: './modal-confirm-delete.component.html',
  styleUrls: ['./modal-confirm-delete.component.scss']
})
export class ModalConfirmDeleteComponent implements OnInit {
  
  public nome : String
  public id : any
  public loader : boolean = false
  public widthInfo = "300px"
  @Output("updateCartItem") updateCartEmit = new EventEmitter();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private CartService : CartService,
              private modalInfo: MatDialog,
              private api: ApiService,
              private app: AppService
  ) { 
    this.nome = this.data.nome
    this.id   = this.data.id
  }
  
  delete(){
    
    try{
      
      this.loader = true
      
      this.api.cart().delete(this.id).subscribe(response=>{

        this.loader = false

        switch(response.status){
          case 1:
            this.data.updateCart()
          break;
          default:

            this.app.info("<h5>Houve um erro</h5><div>"+response.data+"</div>","danger");
    
          break;
        }

      },(err)=>{
        
        this.loader = false
        this.app.info("<h5>Houve um erro</h5><div>"+err._body+"</div>","danger");
       

      })

    }catch(e){

      this.loader = false
      this.app.info("<h5>Houve um erro</h5><div>"+e.message+"</div>","danger");
    
      
    }
  

  }

  ngOnInit() {
  }

}
