<div class="dm-cartd-passageiros-qtd">
  <form class="dm-form">
    <div class="row">
      <div class="col-md-6 col-6">
        <div class="dm-form-item">
          <label>Adultos</label>
          <span class="info">
            {{configuracaoLoja.passageiro_label_adt}}
          </span>
          <div class="content-input">
            <input type="text" [(ngModel)]="qtd_adt" name="qtd_adt" integer (change)="_changeValue()" />
          </div>  
          <div class="content-buttons">
            <button type="button" class="btn-custom-four menos" (click)="menos('adt')">
              <span>-</span>
            </button> 
            <button type="button" class="btn-custom-four mais" (click)="mais('adt')">
              <span>+</span>
            </button>  
          </div>  
        </div>  
      </div>
      <div class="col-md-6 col-6">
        <div class="dm-form-item">
          <label>Melhor Idade</label>
          <span class="info">
            {{configuracaoLoja.passageiro_label_snr}}
          </span>
          <div class="content-input">
            <input type="text" [(ngModel)]="qtd_snr" name="qtd_snr" integer (change)="_changeValue()" />
          </div>  
          <div class="content-buttons">
            <button type="button" class="btn-custom-four menos" (click)="menos('snr')">
              <span>-</span>
            </button> 
            <button type="button" class="btn-custom-four mais" (click)="mais('snr')">
              <span>+</span>
            </button>  
          </div>  
        </div>  
      </div>  
    </div>
    <div class="row">
      <div class="col-md-6 col-6">
        <div class="dm-form-item">
          <label>Criança Pagante</label>
          <span class="info">
            {{configuracaoLoja.passageiro_label_chd}}
          </span>
          <div class="content-input">
            <input type="text" [(ngModel)]="qtd_chd" name="qtd_chd" integer (change)="_changeValue()" />
          </div>  
          <div class="content-buttons">
            <button type="button" class="btn-custom-four menos" (click)="menos('chd')">
              <span>-</span>
            </button> 
            <button type="button" class="btn-custom-four mais" (click)="mais('chd')">
              <span>+</span>
            </button>  
          </div>  
        </div>  
      </div> 
      <div class="col-md-6 col-6">
        <div class="dm-form-item">
          <label>Criança cortesia</label>
          <span class="info">
            {{configuracaoLoja.passageiro_label_chd_free}}
          </span>
          <div class="content-input">
            <input type="text" [(ngModel)]="qtd_inf" name="qtd_inf" integer (change)="_changeValue()"/>
          </div>  
          <div class="content-buttons">
            <button type="button" class="btn-custom-four menos" (click)="menos('inf')">
              <span>-</span>
            </button> 
            <button type="button" class="btn-custom-four mais" (click)="mais('inf')">
              <span>+</span>
            </button>  
          </div>  
        </div>  
      </div>  
    </div> 
  </form>   
</div>
<modal-info [show]="message.show" [customClass]="message.class" [message]="message.data" (hide)="message.show=false"></modal-info>

