<div [ngSwitch]="template">
  <div *ngSwitchCase="null" class="single">
    <img src="/images/icons/alltour/whatsapp.svg" alt="Whatsapp - Aextour" (click)="open()" />
  </div>
  <div *ngSwitchCase="'topo'" class="whatsapp-topo">
    <div class="content">
      <ul>
        <li>
          <div class="whatsapp-icon">
            <figure>
              <img src="/images/icons/alltour/whatsapp.svg" alt="Whatsapp - All Tour" />
            </figure>
          </div> 
        </li>
        <li>
          <div class="titulo">
            <h4>FALE AGORA</h4>
            <h5>VIA WHATSAPP</h5>
          </div>
          <div class="link">  
            <button type="button" (click)="open()">
              <span>CLIQUE</span>
              <span>AQUI:)</span>
            </button>  
          </div>
          <div class="clearfix"></div>
          <div class="horario"> 
            <h6>{{data.params.whatsapp_horario}}</h6>
          </div>  
        </li>  
      </ul>  
    </div>  
  </div>
  <div *ngSwitchCase="'contato-home'">
    <div class="modulo modulo-contato-home">
      <div class="modulo-content">
        <ul (click)="open()">
          <li>
            <figure>  
              <img src="/images/icons/sao-paulo-ecoturismo/whatsapp.svg" alt="Whatsapp - São Paulo Ecoturismo" />
            </figure>  
          </li>
          <li>
            <span>
              TIRE SUAS DÚVIDAS
            </span>  
            <span>
              pelo whatsapp ;)
            </span> 
            <span> 
              CLIQUE AQUI
            </span>  
          </li>
        </ul>
      </div>  
    </div>  
  </div> 
  <div *ngSwitchCase="'page-contato'"> 
    <div class="modulo modulo-whatsapp-contato">
      <div class="modulo-content">
        <ul (click)="open()">
          <li>
            <figure>
              <img src="/images/icons/aextour/whatsapp.svg" alt="Whatsapp - Aextour" />
            </figure>  
          </li>
          <li>
            <span>
              vamos conversar pelo
            </span>  
            <span>
              Whatsapp ?
            </span>  
            <span>
              CLIQUE AQUI!
            </span>  
          </li>
        </ul>
      </div>  
    </div> 
  </div> 
  <div *ngSwitchCase="'footer'">
    <div class="modulo modulo-whatsapp-footer">
      <div class="modulo-content">
        <ul>
          <li>
            <figure> 
              <img src="/images/icons/alltour/whatsapp-two.svg" alt="Whatsapp - Alltour" /> 
            </figure>  
          </li>
          <li>
            <h4>FALE AGORA</h4>  
            <h5>VIA WHATSAPP</h5> 
            <button type="button" (click)="open()"> 
              CLIQUE AQUI :)
            </button>  
          </li>
        </ul>
        <div class="clearfix"></div>
        <div class="horario"> 
          <h6>{{data.params.whatsapp_horario}}</h6>
        </div>
      </div>  
    </div>  
  </div> 
  <div *ngSwitchCase="'page-servico'">
    <div class="modulo modulo-whatsapp-footer modulo-whatsapp-page-servico">
      <div class="modulo-content">
        <ul>
          <li>
            <figure> 
              <img src="/images/icons/alltour/contato/whatsapp-two.svg" alt="Whatsapp - Alltour" /> 
            </figure>  
          </li>
          <li>
            <h4>FALE AGORA</h4>  
            <h5>VIA WHATSAPP</h5> 
            <button type="button" (click)="open()"> 
              CLIQUE AQUI :)
            </button>  
          </li>
        </ul>
        <div class="clearfix"></div>
        <div class="horario"> 
          <h6>{{data.params.whatsapp_horario}}</h6>
        </div>
      </div>  
    </div>  
  </div>
  <div *ngSwitchCase="'page-pagamento'">
    <div class="modulo modulo-page-pagamento">
      <div class="modulo-content">
        <ul (click)="open()">
          <li>
            <figure> 
              <img src="/images/icons/sao-paulo-ecoturismo/whatsapp-two.svg" alt="Whatsapp - São Paulo Ecoturismo" /> 
            </figure>  
          </li>
          <li>
            <span>
              fala com a gente
            </span>  
            <span>
              pelo whatsapp ;)
            </span>  
          </li>
        </ul>
      </div>  
    </div>  
  </div>  
</div>
