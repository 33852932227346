<section class="modulo-sobre">
  <div class="modulo-title" *ngIf="data.mostrar_titulo">
    <h4>{{data.titulo}}</h4>
    <div class="linha-divisor-title"></div>
  </div>  
  <div class="modulo-content">
    <div class="content-logo">
      <figure class="logo">
        <img src="/images/modulo/sobre/logos/{{data.params.logo}}" alt="Logo" />
      </figure>  
    </div> 
    <article class="texto" [innerHtml]="data.params.texto | safeHtml"></article> 
  </div>   
</section>
