<a href="javascript::void()" class="btn-one btn-icon" (click)="getUrlVoucher()">
  <i class="material-icons">&#xE873;</i>
  <span>ACESSAR VOUCHER</span>
</a>
<div *ngIf="url != null">
  <span class="info-text">Se o Voucher não abrir:</span><br>
  <a href="{{url}}" target="__blank" class="btn-link">
    Clique Aqui
  </a>
</div>    
<div class="alert-inline alert alert-warning" *ngIf="loader">
  Gerando Voucher. Aguarde !
</div>
