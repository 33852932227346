<div class="dm-pagamento-finalizado">
  <div class="dm-pagamento-finalizado-content">
    <article class="message-sucesso">
      <h3>Parabéns :)</h3>
      <p>O seu pagamento foi finalizado com sucesso.</p>
    </article>  
  </div>  
  <div class="linha-divisoria"></div>
  <div class="dm-pagamento-finalizado-informacoes">
    <ul>
      <li>
        <div class="item">
          <span class="text">CÓDIGO DA RESERVA</span>
          <span class="value">
            {{data.carrinho.file}}
          </span>
        </div>  
      </li>
    </ul>  
  </div> 
  <section class="section-actions">
    <ul>
      <li>
        <div class="dm-pagamento-finalizado-confirmacao-email">
          <dm-cart-pagamento-confirmacao-email sendAutomatico="true" [carrinhoId]="data.carrinho.id" [email]="data.cart_dados.email"></dm-cart-pagamento-confirmacao-email> 
        </div> 
      </li>
      <!--<li>   
        <div class="dm-pagamento-finalizado-confirmacao-imprimir">
          <dm-cart-pagamento-confirmacao-imprimir [carrinhoId]="data.carrinho.id" [hash]="hash"></dm-cart-pagamento-confirmacao-imprimir>
        </div>
      </li>-->
      <li>  
        <div class="dm-pagamento-finalizado-confirmacao-imprimir">
          <dm-cart-pagamento-confirmacao-download [carrinhoId]="data.carrinho.id" [hash]="hash"></dm-cart-pagamento-confirmacao-download>
        </div>
      </li> 
      <li *ngIf="info.intermediador_apelido == 'gerencia_net_boleto'">
        <div class="content-action">
          <div class="content">
            <h3>Para imprimir o boleto clique no link abaixo:</h3>
            <a [href]="pagamento.link_boleto" class="btn-custom-two" target="_blank">
              Imprimir Boleto
            </a>  
          </div>
        </div>
      </li>   
    </ul>  
  </section>  
</div>
