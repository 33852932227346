<header #header class="animated-two fadeIn" [ngClass]="{'shadow':isHome == false}">
  <modulo-menu classCustom="menu-mobile" class="container-menu-mobile"></modulo-menu> 
  <div class="content">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-5">
          <modulo-position position="whatsapp" template="topo" class="whatsapp"></modulo-position>
        </div>
        <div class="col-lg-4 col-md-2 col-6">
          <modulo-position position="logo" class="logo"></modulo-position>
        </div>
        <div class="col-lg-4 col-md-5 col-6">
          <dm-cart-total class="total-carrinho"></dm-cart-total>
        </div>
      </div>
    </div>
    <div class="content-menu"> 
      <div class="container">
        <modulo-menu classCustom="menu-desktop"></modulo-menu>
      </div>  
    </div>  
  </div> 
  <div class="bordas"> 
    <ul>
      <li></li> 
      <li></li> 
      <li></li> 
      <li></li> 
      <li></li> 
      <li></li>  
    </ul>  
  </div>  
</header>
