<div class="produto-regiao">
  <div class="capa">
    <figure *ngIf="data.foto_capa == null">
      <img lazyLoad="/images/regiao/sem-foto/alltour.jpg" alt="Alltour" class="img-fluid" />
    </figure>
    <figure *ngIf="data.foto_capa != null">
      <img defaultImage="/images/regiao/sem-foto/alltour.jpg" [lazyLoad]="data.foto_capa" alt="{{data.titulo}}" class="img-fluid" />
    </figure> 
    <div class="overlay"></div>  
  </div>
  <div class="titulo">
    <h3>{{data.titulo}}</h3>
  </div>  
  <div class="saiba-mais">  
    <a routerLink="/servicos/{{data.apelido}}" title="Conheça {{data.titulo}}">
      <span>CONHEÇA</span>
      <div class="bg-overlay"></div>
    </a>  
  </div> 
</div>
