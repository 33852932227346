<div [ngSwitch]="template" itemscope itemtype="https://schema.org/Organization">
  <div class="modulo modulo-endereco" *ngSwitchCase="null">
    <div class="modulo-title" *ngIf="data.mostrar_titulo">
      <h3>{{data.titulo}}</h3>
    </div> 
    <div class="modulo-content"> 
      <div class="icon">
        <i class="material-icons">
          location_on
        </i>
      </div>  
      <article class="endereco" itemprop="address" itemscope itemtype="http://schema.org/PostalAddress">
        <span itemprop="streetAddress">{{data.params.rua}}, nº {{data.params.numero}} | {{data.params.bairro}}</span><br>
        <span itemprop="addressLocality">{{data.params.cidade}}</span><br><span itemprop="postalCode">Cep: {{data.params.cep}}</span>
      </article>  
    </div>  
  </div>
  <div class="modulo modulo-endereco-contato" *ngSwitchCase="'contato'">
    <article class="endereco" itemprop="address" itemscope itemtype="http://schema.org/PostalAddress">
      <h4>
        <span itemprop="streetAddress">{{data.params.rua}}, nº {{data.params.numero}}, {{data.params.bairro}}</span> - <span itemprop="addressLocality">{{data.params.cidade}}</span>
      </h4>
      <h4>
        CEP: <span itemprop="postalCode">{{data.params.cep}}</span>
      </h4>
    </article>
  </div>
  <div class="modulo modulo-endereco-footer" *ngSwitchCase="'footer'">
    <h3>
      {{data.titulo}}
    </h3>
    <div class="endereco" itemprop="address" itemscope itemtype="http://schema.org/PostalAddress">
      <span itemprop="streetAddress">{{data.params.rua}}, {{data.params.numero}}</span><br>
      <span>{{data.params.bairro}}</span>,<span itemprop="addressLocality">{{data.params.cidade}}</span><br>
      <span itemprop="postalCode">CEP: {{data.params.cep}}</span>
    </div> 
    <div class="horario">
      <h3>
        HORÁRIO DE FUNCIONAMENTO
      </h3>
      <span class="dias">{{data.params.horario_funcionamento_dias}}</span><br>
      <span class="horas">{{data.params.horario_funcionamento_horas}}</span>
    </div>  
  </div>
  <div class="modulo modulo-contato-home" *ngSwitchCase="'contato-home'">
    <h3 *ngIf="data.mostrar_titulo">
      <img src="/images/icons/sao-paulo-ecoturismo/location.svg" alt="Onde Estamos - São Paulo Ecoturismo" />
      <span>{{data.titulo}}</span>
    </h3>
    <article class="endereco" itemscope itemtype="http://schema.org/PostalAddress">
      <h4 itemprop="streetAddress">{{data.params.rua}} Número {{data.params.numero}}</h4>
      <h4>{{data.params.bairro}} - <span itemprop="addressLocality">{{data.params.cidade}}</span></h4>
      <h4 itemprop="postalCode">CEP: {{data.params.cep}}</h4>
    </article>
  </div>
  <div class="modulo modulo-page-contato" *ngSwitchCase="'page-contato'" itemscope itemtype="http://schema.org/PostalAddress">
    <div class="content-title">
      <div class="container">
        <ul>
          <li>
            <img src="/images/icons/sao-paulo-ecoturismo/mapa/location.svg" alt="Localização - São Paulo Ecoturismo" />
          </li>
          <li>
            <h4>ONDE ESTAMOS ?</h4>
            <h5><span itemprop="streetAddress">{{data.params.rua}},Nº {{data.params.numero}}, {{data.params.bairro}}</span> - <span itemprop="addressLocality">{{data.params.cidade}}</span></h5>
          </li>  
        </ul>
      </div>    
    </div> 
    <div class="content-mapa">
      <mapa-google [latitude]="data.params.latitude" [longitude]="data.params.longitude"></mapa-google>
    </div>  
  </div>
</div>
