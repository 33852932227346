<div class="md-select-custom not-placeholder" (click)="_getData()">
  <label>{{label}}</label>
  <mat-select [(ngModel)]="value" name="value" #select (ngModelChange)="_change()">
    <mat-select-search [formControl]="comboCtrl"></mat-select-search>  
    <mat-option [value]="value"  *ngIf="response.data.length == 0 && value != null">
      {{text}}
    </mat-option>
    <mat-option [value]="d.idHotelPousada" *ngFor="let d of comboFilter | async"> 
      {{d.hotelPousada}}
    </mat-option>
  </mat-select>
  <div class="alert alert-info alert-message" *ngIf="loader">
    Carregando... Aguarde!
  </div>
  <div class="alert alert-danger alert-message" *ngIf="response.status == 500">
    {{response.message}} 
  </div>  
</div>