import { Component, OnInit, EventEmitter, Output, Input, ViewChild, AfterViewInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';

declare var $: any;

@Component({
  selector: 'dm-cart-data-servico',
  templateUrl: './dm-cart-data-servico.component.html',
  styleUrls: ['./dm-cart-data-servico.component.scss']
})
export class DmCartDataServicoComponent implements OnInit, AfterViewInit{

  @ViewChild('datepicker') datepicker;
  @Input("value") value                               = null;
  @Input("idServico") idServico                       = null;
  @Input("idTipoServico") idTipoServico               = null;
  @Input("idMacroRegiao") idMacroRegiao               = null; 
  @Input("idTipoTransporte") idTipoTransporte         = null;
  @Input("dataInicio") dataInicio                     = null;
  @Input("dataFim") dataFim                           = null;
  @Output("changeValue") changeValue                  = new EventEmitter();  
  public loader                                       = false;
  @Input("disponibilidadeArray") disponibilidadeArray = null;
  public mes = null;
  public ano = null;
  public messageLoader = "";
  @Input("adt") adt:any = 0;
  @Input("snr") snr:any = 0;

  constructor(
    private api: ApiService
  ) { }

  /**
  * 
  * Cria o calendário
  * 
  */
 create(){
   
  let self = this;

  $(this.datepicker.nativeElement).datepicker({
     dateFormat: "dd/mm/yy",
     minDate: new Date(),
     changeMonth: true,
     changeYear: true,
     dayNamesMin: ['Dom','Seg','Ter','Qua','Qui','Sex','Sab'],
     monthNames: ['Janeiro','Fevereiro','Março','Abril','Maio','junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'],
     beforeShowDay: this.habilitarDates,
     onSelect: (value)=>{
      
      let data   = $(this.datepicker.nativeElement).val();
      this.value = data;
      
      this.changeValue.emit({
        data: data,
        disponibilidadeArray: this.disponibilidadeArray
      });
      
      setTimeout(() => {
        self.preencheValoresCalendario(self); 
      });
           

     },
     onChangeMonthYear: (ano,mes) =>{
 
      this.mes = mes;
      this.ano = ano;

      this.getData();

     } 

   });
   setTimeout(() => {
    self.preencheValoresCalendario(self); 
   });
   
 }
  /**
  * 
  * Habilita somente as datas disponíveis
  * 
  */
  habilitarDates = (d: Date) => {
    
    let dataDia = null;

    if(dataDia = this.hasDate(d)){
      return [true,"date-td date-td-valor",dataDia.tarifaADT];
    }else{
      return [false,"date-td",null];
    }

  }
  /**
  * 
  * 
  * 
  */
 hasDate = (d: Date): boolean =>{

    let result:boolean = false
    let dataS    = null
    let dataOut  = null
    let datas    = this.disponibilidadeArray;
    let response:any = false;

    if(typeof(datas) != "string"){
      datas = typeof(datas.length) == "undefined" ? [datas] : datas; 
    }else{
      datas = [];
    }
    datas.forEach(element => {
        
      dataS   = element.dia.split("/"); 
      dataOut = new Date(parseInt(dataS[2]),(parseInt(dataS[1])-1),parseInt(dataS[0]));
        
      if(dataOut.getDate() == d.getDate() && dataOut.getMonth() == d.getMonth() && dataOut.getFullYear() == d.getFullYear()
        ){
        response = element;   
        result = true
      }

    });
    return response;

  }
  /**
   * 
   * Retorna a disponibilidade
   * 
   */
  getData(){

    try{

      this.loader = true;
      this.messageLoader = "Carregando disponibilidade.";

      this.api.manage().disponibilidade(
        this.idServico,
        this.idTipoServico,
        this.idMacroRegiao,
        this.idTipoTransporte,
        this.mes,
        this.ano,
        (this.adt+this.snr)
      ).subscribe(response => {

        this.loader = false;

        if(response.status == 1){
          
          this.disponibilidadeArray = response.data;
          this.create(); 
          $(this.datepicker.nativeElement).datepicker("refresh");
          setTimeout(() => {
            this.preencheValoresCalendario();
          },500);
          
        }

      },(response) => {

        this.loader = false;

      });

    }catch(e){

      this.loader = false;

    }

  }
  /**
   * 
   * Preenche valores
   * 
   */
  preencheValoresCalendario(self=this){

    $(self.datepicker.nativeElement).find(".date-td-valor").map(function(){
      let valor = this.title;
      $(this).append("<span class='valor'>"+valor+"</span>"); 
    });

  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    
    if(this.disponibilidadeArray != null && this.value != null){
      
      this.create();
      $(this.datepicker.nativeElement).datepicker("setDate",this.value);
    
    }else{

      setTimeout(() => {
        this.getData();
      },50);

    }

  }
  /**
   * 
   * Iniciliza as Funções após carregar a VIEW
   * 
   */
  ngAfterViewInit(){

  }

}
