import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dm-cart-pagamento-confirmacao-download',
  templateUrl: './dm-cart-pagamento-confirmacao-download.component.html',
  styleUrls: ['./dm-cart-pagamento-confirmacao-download.component.scss']
})
export class DmCartPagamentoConfirmacaoDownloadComponent implements OnInit {

  @Input("carrinhoId") carrinhoId = null;
  @Input("hash") hash             = null;

  constructor() { }


  /**
   * 
   * Click
   * 
   */
  _click(){

    window.location.assign("/api/site/componentes/pagamento/download-confirmacao/"+this.hash)

  }
  /**
   * 
   * Iicializa as Funções
   * 
   */
  ngOnInit(){
    
  }

}
