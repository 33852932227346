<div class="dm-cart-resumo-steps" [hidden]="step == 1">
  <ul>
    <li [hidden]="step == 1" (click)="setStep(1)">
      <div class="item">
        <div class="icon">
          <img src="/images/icons/alltour/carrinho/resumo/location.svg" alt="Location">
        </div>
        <div class="text"> 
          <span>{{dataItem.macro_regiao}}</span>
        </div>   
      </div>
    </li>  
    <li [hidden]="step <= 2" (click)="setStep(2)">
      <div class="item">
        <div class="icon">
          <img src="/images/icons/alltour/carrinho/resumo/car.svg" alt="Carro">
        </div> 
        <div class="text"> 
          <span>{{dataItem.tipo_servico}}</span>
          <span *ngIf="dataItem.tipo_servico_id == 2" class="info">
            - {{dataItem.tipo_transporte}}
          </span>
        </div>  
      </div>  
    </li>
    <li [hidden]="step <= 3" (click)="setStep(3)">
      <div class="item">
        <div class="icon">
          <img src="/images/icons/alltour/carrinho/resumo/hotel.svg" alt="Hotel">
        </div>
        <div class="text"> 
          <span>{{dataItem.nome_hotel}}</span>
        </div>  
      </div>
    </li>
    <li [hidden]="step <= 5" (click)="setStep(4)">
      <div class="item">
        <div class="icon">
          <img src="/images/icons/alltour/carrinho/resumo/calendar.svg" alt="Calendário">
        </div>
        <div class="text"> 
          <span>{{dataItem.data_utilizacao}}</span>
        </div>  
      </div> 
    </li>
    <li [hidden]="step <= 5" class="step-valores" (click)="setStep(5)">
      <div class="item totais">
        <div class="icon">
          <img src="/images/icons/alltour/carrinho/resumo/money.svg" alt="Money">
        </div>
        <div class="text" *ngIf="dataItem.tipo_servico_id == 1">  
          <!--<span>Adultos: {{dataItem.valor_adt | currency:"R$ "}} x {{dataItem.qtd_adt}} = </span> <strong>{{(dataItem.valor_adt*dataItem.qtd_adt) | currency:"R$ "}}</strong><br>
          <span>Melhor Idade: {{dataItem.valor_snr | currency:"R$ "}} x {{dataItem.qtd_snr}} = </span> <strong>{{(dataItem.valor_snr*dataItem.qtd_snr) | currency:"R$ "}}</strong><br>
          <span>Criança Pagante: {{dataItem.valor_chd | currency:"R$ "}} x {{dataItem.qtd_chd}} = </span> <strong>{{(dataItem.valor_chd*dataItem.qtd_chd) | currency:"R$ "}}</strong><br>-->
          <span>TOTAL: </span><strong>{{(dataItem.valor_snr*dataItem.qtd_snr)+(dataItem.valor_adt*dataItem.qtd_adt)+(dataItem.valor_chd*dataItem.qtd_chd) | currency: "R$ "}}</strong>
        </div>  
        <div class="text" *ngIf="dataItem.tipo_servico_id == 2">  
          <span>Valor Serviço: </span><strong>{{dataItem.valor_servico | currency: "R$ "}}</strong><br>
          <span>Lotação Máxima: </span><strong>{{dataItem.lotacao_maxima}} lugares</strong>
        </div>  
      </div>  
    </li>
    <li [hidden]="step <= 6" class="step-qtd" (click)="setStep(6)">
      <div class="item item-passageiros">
        <div class="icon">
          <img src="/images/icons/alltour/carrinho/resumo/passageiros.svg" alt="Passageiros">
        </div>
        <div class="text"> 
          <span>Adultos: {{dataItem.qtd_adt}}</span>
          <span>Melhor Idade: {{dataItem.qtd_snr}}</span>
          <span>Criança Pagante: {{dataItem.qtd_chd}}</span>
          <span>Criança Cortesia: {{dataItem.qtd_inf}}</span>
        </div>  
      </div>  
    </li>
  </ul>  
</div>  