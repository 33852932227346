<div class="modal" tabindex="-1" role="dialog" #modal (click)="_click()">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{title}}</h5>
      </div>
      <div class="modal-body">
        <div class="{{customClass}} message-text" [innerHtml]="message"></div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-one" (click)="hideModal()">OK</button>
      </div>
    </div>
  </div>
</div>