import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { CartService } from '../../../services/cart.service';
import { ApiService } from '../../../services/api.service';
import { AppService } from '../../../services/app.service';

@Component({
  selector: 'modal-confirm-esvaziar-cart',
  templateUrl: './modal-confirm-esvaziar-cart.component.html',
  styleUrls: ['./modal-confirm-esvaziar-cart.component.scss']
})
export class ModalConfirmEsvaziarCartComponent implements OnInit {
  
    public loader = false; 
  
    constructor( 
      @Inject(MAT_DIALOG_DATA) public data: any,
      private CartService : CartService,
      private modalInfo: MatDialog,
      private api: ApiService,
      private app: AppService
    ){ }
  
    /**
     * 
     * Esvazia o Carrinho
     * 
     */
    esvaziar(){
      
      
      try{
        
        
        this.loader = true
        
        this.api.cart().esvaziar().subscribe(response=>{
  
          this.loader = false
  
          switch(response.status){
            case 1:
              this.data.updateCart()
            break;
            default:
            
              this.app.info("<h5>Houve um erro</h5><div>"+response.data+"</div>","danger");
                          
            break;
          }
  
        },(err)=>{
          
          this.loader = false
          this.app.info("<h5>Houve um erro</h5><div>"+err._body+"</div>","danger");
            
        })
  
      }catch(e){
        
        this.loader = false
        this.app.info("<h5>Houve um erro</h5><div>"+e.message+"</div>","danger");
               
      }
    
  
    }
    /**
     * 
     * Inicializa as Funções
     * 
     */
    ngOnInit() {

    }
  
  }
