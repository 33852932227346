import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

declare var $:any;

@Component({
  selector: 'produto-slide-show-two',
  templateUrl: './produto-slide-show-two.component.html',
  styleUrls: ['./produto-slide-show-two.component.scss']
})
export class ProdutoSlideShowTwoComponent implements OnInit {

  @Input("fotos") fotos     = [];
  @ViewChild("slider") slider:ElementRef;
  @ViewChild("sliderNav") sliderNav:ElementRef;
  public show               = true;

  constructor() { }

  /***
  * 
  * Iniciliza o slider
  * 
  */
  initSlider(){

    $(this.slider.nativeElement).slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: false,
      dots: true,
      asNavFor: this.sliderNav.nativeElement
    });
    $(this.sliderNav.nativeElement).slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      asNavFor: this.slider.nativeElement,
      dots: false,
      arrows: false,
      centerMode: true,
      focusOnSelect: true
    });
    $(this.slider.nativeElement).slick("refresh");

  }
  /**
   * 
   * Prev
   * 
   */
  prevSlide(){

    $(this.slider.nativeElement).slick("slickPrev");

  }
  /**
   * 
   * Next
   * 
   */
  nextSlide(){

    $(this.slider.nativeElement).slick("slickNext"); 
  
  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){


  }
  ngAfterViewInit(): void {
    this.initSlider();
  }
  ngOnChanges(changes:any): void {
    

  }

}
