<div class="dm-cart-formas-pagamento">
  <div class="dm-cart-formas-pagamento-header">
    <ul>
      <li class="item" *ngFor="let item of formasPagamento;let i=index;" [ngClass]="{'active':i==indexSelected}" (click)="setIndex(i)">
        <div class="item-content">
          <div class="icon icon-cartao-de-credito" *ngIf="item.tipo_id == 1">
            <i class="fa fa-credit-card"></i>
          </div>
          <div class="icon icon-boleto" *ngIf="item.tipo_id == 2">
            <i class="fa fa-file"></i>
          </div>
          <div class="text">  
            <span>{{item.titulo}}</span>
          </div>  
        </div>  
      </li>
    </ul>  
  </div>  
  <div class="dm-cart-formas-pagamento-content">
    <div class="formas-de-pagamento">
      <div>
        <div class="item" *ngFor="let item of formasPagamento;let i=index;" [hidden]="i !== indexSelected">
          <dm-cart-pagamento-tipo (updateResponse)="updateResponse($event)" (onSuccess)="onSuccessPagamento($event)" [sendPagamento]="sendPagamento" [carrinho]="carrinho" [formaPagamento]="item" [index]="i" [indexSelected]="indexSelected"></dm-cart-pagamento-tipo>
        </div> 
      </div>  
    </div>  
  </div>  
</div>
