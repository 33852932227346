import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { ApiComponentesSettings } from './../class/api-componentes-settings';

@Injectable()
export class ProjetoService {

  public updateModuloProjetos = new EventEmitter();

  constructor(private http: HttpClient) { }

  /**
   * 
   * Retorna o projeto com base no apelido
   * 
   * @param apelido 
   * 
   */
  getProjetoByApelido(apelido):any{

    let url = ApiComponentesSettings.API_PROJETOS_PROJETO_BY_APELIDO+"/"+apelido;
    
    return this.http.get(url).map(response => response);

  }
  /**
   * 
   * Retorna os projetos
   * 
   * @param skip
   * 
   */
  getProjetos(skip):any{

    let url = ApiComponentesSettings.API_PROJETOS_PROJETO_BY_APELIDO+"/?skip="+skip;
    let params = {
      skip: skip
    }
    return this.http.get(url,{params}).map(response => response);

  }


}
