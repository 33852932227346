import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../../../services/api.service';

@Component({
  selector: 'dm-cart-passageiro-lista-informacao',
  templateUrl: './dm-cart-passageiro-lista-informacao.component.html',
  styleUrls: ['./dm-cart-passageiro-lista-informacao.component.scss']
})
export class DmCartPassageiroListaInformacaoComponent implements OnInit {

  @Output("changeValue") changeValue           = new EventEmitter();
  @Input("dataItem") dataItem                  = null;
  @Input("configuracaoLoja")  configuracaoLoja = {};
  public passageiros                           = [];
  public loader = false;
  public listPaxs     = {
    adt: [],
    chd: [],
    inf: [],
    snr: []
  }

  constructor(
    private api: ApiService
  ){ }

  /**
   * 
   * Inicializa o dataItem de passageiros
   * 
   */
  dataItemPassageiro(isSelect=false,tipo="adt"):object{

    return {
      id:"",
      nome: "",
      sobrenome: "",
      data_nascimento: "",
      telefone: "",
      cpf: "",
      rg: "",
      documento: "",
      tipo: tipo, 
      isSelect: isSelect
    }

  }
  /**
   * 
   * Push Passageiros
   * 
   */
  initDataItemPassageiros():void{

    let passageiros = [];
    let isSelect    = this.passageiros.length > 0 ? true : false;

    for(var index = 0; index < this.dataItem.qtd_adt; index++){
      passageiros.push(this.dataItemPassageiro(isSelect));
    }
    for(var index = 0; index < this.dataItem.qtd_chd; index++){
      passageiros.push(this.dataItemPassageiro(isSelect,"chd"));
    }
    for(var index = 0; index < this.dataItem.qtd_inf; index++){
      passageiros.push(this.dataItemPassageiro(isSelect,"inf"));
    }
    for(var index = 0; index < this.dataItem.qtd_snr; index++){
      passageiros.push(this.dataItemPassageiro(isSelect,"snr"));
    }
    this.passageiros = passageiros;
    this.changeValue.emit(this.passageiros);

  }
  /**
   * 
   * Atualiza a lista de passageiros
   * 
   */
  _changeValue(){

    this.changeValue.emit(this.passageiros);

  }
  /**
   * 
   * Retorna a lista de passageiros
   * 
   */
  getListPaxs(){

    try{

      this.loader = true

      this.api.cart().lista_paxs().subscribe(response=>{
        
        this.loader = false
        
        if(response.status == 1){
          this.pushListPaxs(response.data)
          this.initDataItemPassageiros();
        }

      },(err)=>{
        this.loader = false
      })

    }catch(err){
      this.loader = false
    }

  }
  pushListPaxs(data){

    this.listPaxs     = {
      adt: [],
      chd: [],
      inf: [],
      snr: []
    }

    data.forEach(element=> {
      
      switch(element.tipo){
        case "adt":
          this.listPaxs.adt.push(element)
        break;
        case "chd":
          this.listPaxs.chd.push(element)
        break;
        case "snr":
          this.listPaxs.snr.push(element)
        break;
        case "inf":
          this.listPaxs.inf.push(element)
        break;
      }

    });

  }
  /**
   * 
   * Aciona quando muadr de opção
   * 
   */
  changeIsSelect(passageiro,value){
    
    passageiro.isSelect = value;

    if(value == false){
      passageiro.id = null
    }
      
  }
  /**
   * 
   * Inicializa as Informações
   * 
   */
  ngOnInit(){
    this.getListPaxs();    
  }

}
