<div class="dm-cart-tarifas-resumo">
  <h3>Tarifas para o dia {{dataItem.data_utilizacao}}</h3>
  <ul>
    <li>
      <div class="item">
        <span class="text">Tarifa ADT</span>
        <span class="value">{{dataItem.valor_adt | currency:"R$ "}}</span>
      </div>  
    </li>
    <li>
      <div class="item">
        <span class="text">Tarifa SNR</span>
        <span class="value">{{dataItem.valor_snr | currency: "R$ "}}</span>
      </div>  
    </li>
    <li>
      <div class="item">
        <span class="text">Tarifa CHD</span>
        <span class="value">{{dataItem.valor_chd | currency:"R$ "}}</span>
      </div>  
    </li>
    <li>
      <div class="item">
        <span class="text">Tarifa SERVIÇO</span>
        <span class="value">{{dataItem.valor_servico | currency:"R$ "}}</span>
      </div>  
    </li>
  </ul>  
</div>