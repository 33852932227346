import { Injectable } from '@angular/core';
import { ApiComponentesSettings } from '../class/api-componentes-settings';
import { DataService } from './data.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AtividadeService {

  constructor(
    private http: HttpClient,
    private db: DataService
  ){ }

  /**
   * 
   * Finaliza a Compra
   * 
   * @param params 
   * 
   */
  gerarReserva(params={}){
    
    let url = ApiComponentesSettings.ATIVIDADE.gerar_reserva;
    return this.http.post(url,params).map(response=>response)
    
  }
  /**
   * 
   * Finaliza a Compra
   * 
   * @param params 
   * 
   */
  finalizarCompra(params){
    
    let url = ApiComponentesSettings.ATIVIDADE.pagamento;
    return this.http.post(url,params).map(response=>response)
    
  }
  /**
   * 
   * Finaliza a Compra
   * 
   * @param params 
   * 
   */
  gerarBoletoTaxas(){
    
    let url = ApiComponentesSettings.ATIVIDADE.gerar_boleto_taxas;
    return this.http.post(url,{}).map(response=>response)
    
  }
  getMessageHtmlFileError(messageDetalhes=null){

    let configuracao = this.db.getConfiguracao();

    let message = "<h4>Problema ao gerar a reserva</h4>";
    message  += "<p>";
    message  += "Se o problema persistir entre em contato: <br><br>";
    message  += "<strong>Telefone: </strong><span>"+configuracao.contato_telefone+"</span><br>";
    message  += "<strong>Email: </strong><span>"+configuracao.contato_email+"</span>";
    if(typeof(configuracao.contato_whatsapp) != "undefined"){
      if(typeof(configuracao.contato_whatsapp) != null){
        message  += "<br><strong>Email: </strong><span>"+configuracao.contato_whatsapp+"</span>";
      }  
    }
    message  += "</p>"; 
    if(messageDetalhes != null){
      message  += "<p>";
      message  += "<strong>Detalhes: </strong><br>";
      message  +=  messageDetalhes; 
      message  += "</p>"
    }  

    return message;

  }
  getMessageHtmlPayError(messageDetalhes=null){
    
    let configuracao = this.db.getConfiguracao();

    let message = "<h4>Problemas com o Pagamento</h4>";
    message  += "<p>";
    message  += "A sua reserva foi gerada em nosso sistema, porém houve um problema com o pagamento: <br><br>";
    message  += "<strong>Telefone: </strong><span>"+configuracao.contato_telefone+"</span><br>";
    message  += "<strong>Email: </strong><span>"+configuracao.contato_email+"</span>";
    if(typeof(configuracao.contato_whatsapp) != "undefined"){
      if(typeof(configuracao.contato_whatsapp) != null){
        message  += "<br><strong>Email: </strong><span>"+configuracao.contato_whatsapp+"</span>";
      }  
    }
    message  += "</p>"; 
    if(messageDetalhes != null){
      message  += "<p>";
      message  += "<strong>Detalhes: </strong><br>";
      message  +=  messageDetalhes; 
      message  += "</p>"
    }  
    
    return message;
    
  }
  getMessageHtmlPayError501(data){ 

    let configuracao = this.db.getConfiguracao();

    let message = "<h4>Problemas com o Pagamento</h4>"; 
    message  += "<p>";
    message  += "A sua reserva foi gerada em nosso sistema, porém houve um problema com o pagamento: <br><br>";
    message  += "<strong>Telefone: </strong><span>"+configuracao.contato_telefone+"</span><br>";
    message  += "<strong>Email: </strong><span>"+configuracao.contato_email+"</span>";
    if(typeof(configuracao.contato_whatsapp) != "undefined"){
      if(typeof(configuracao.contato_whatsapp) != null){
        message  += "<br><strong>Email: </strong><span>"+configuracao.contato_whatsapp+"</span>";
      }  
    }
    message  += "</p>"; 
    message  += "<p>";
    message  += "<strong>Detalhes: </strong><br>";
    if(typeof(data.description) != "undefined"){

      if(typeof(data.description.message) != "undefined"){
        message += data.description.message;
      }else{
        message += data.description; 
      }

    }
    message  += "</p>"

    return message;
    
  }

}
