<div class="evento-item">
  <div class="foto">
    <figure *ngIf="evento.foto != null">
      <img [src]="evento.foto" [alt]="evento.foto_alt" />
    </figure>  
    <figure *ngIf="evento.foto == null">
      <img src="/images/fotos/sem-foto.jpg" [alt]="evento.titulo" />
    </figure>
  </div>
  <div class="content">
    <h3>{{evento.titulo}}</h3>
    <div class="data">
      <h4>{{evento | eventoDateFormat}}</h4>
    </div>  
    <div class="local">
      <strong>Local: </strong> <span>{{evento.cidade}} / {{evento.estado}}</span>
    </div>  
    <hr>
    <div class="content-link">
      <a routerLink="/eventos/{{evento.apelido}}" class="btn">
        VER MAIS
      </a>
    </div>    
  </div>  
</div>
