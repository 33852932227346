<div class="modulo modulo-produto-categoria-destaque">
  <div class="modulo-titulo">
    <div class="container">
      <ul>
        <li>
          <h2>{{data.titulo}}</h2>
        </li>
        <li>
          <div class="linha"></div>
        </li>
        <li>
          <h3>{{data.subtitulo}}</h3>
        </li>  
      </ul> 
    </div> 
  </div>  
  <div class="content">
    <div class="section">
      <div class="section-content">
        <div class="produtos">
          <div class="container">
            <div class="row">
              <div class="col-md-4" *ngFor="let produto of produtos;let i=index;">
                <produto-item [data]="produto"></produto-item>
              </div>  
            </div> 
          </div>   
        </div>
      </div>  
      <div class="section-footer">
        <div class="content">
          <ul>
            <li>
              <span>VER MAIS</span>
              <span>PASSEIOS</span>
            </li> 
            <li>
              <div class="linha"></div>
            </li>  
            <li>
              <a routerLink="/servicos" title="Serviços - All Tour">
                CLIQUE AQUI
              </a>  
            </li>  
          </ul>   
        </div>  
      </div> 
    </div>   
  </div>  
</div>
