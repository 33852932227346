<div class="produto-item {{classCustom}}" itemscope itemtype="https://schema.org/Product">
  <a itemprop="url" [routerLink]="link" title="Saiba mais sobre: {{produto.nome}}">
  <div class="capa">
    <figure *ngIf="produto.foto != null">
      <img itemprop="image" defaultImage="/images/produto/sem-foto/alltour.jpg" [lazyLoad]="produto.foto" [alt]="produto.foto_alt" class="img-fluid" />
    </figure> 
    <figure *ngIf="produto.foto == null">
      <img itemprop="image" src="/images/produto/sem-foto/alltour.jpg" alt="Sem Foto" class="img-fluid" />
    </figure> 
  </div>
  <div class="content"> 
    <div class="titulo"> 
      <h3 itemprop="name">{{produto.nome | max:'40'}}</h3>
      <h4 *ngIf="produto.regiao != null">{{produto.regiao}}</h4>
    </div>
    <div class="descricao">
      <article itemprop="description" [innerHTML]="produto.min_descricao"></article>
    </div>
    <div class="menor-tarifa">
      <produto-item-valor [valor]="produto.menor_tarifa"></produto-item-valor>
    </div>  
  </div>  
  <div class="footer">  
    <a itemprop="url" [routerLink]="link" title="Saiba mais sobre: {{produto.nome}}">
      <span>COMPRE</span><span>AQUI :)</span>
    </a>  
  </div>
  <span style="display:none;" itemprop="sku">{{produto.id}}</span>
  </a>
</div>
