import { Component, OnInit, Input } from '@angular/core';
import { type } from 'os';

@Component({
  selector: 'produto-item-valor',
  templateUrl: './produto-item-valor.component.html',
  styleUrls: ['./produto-item-valor.component.scss']
})
export class ProdutoItemValorComponent implements OnInit {

  @Input("valor") valor             = 0;
  @Input("classCustom") classCustom = "";
  public valor1 = 0;
  public valor2 = 0;
  
  constructor(){ }

  /**
   * 
   * 
   * 
   */
  setValor(){

    let number:any = this.valor.toString();

    number = number.split(".");

    this.valor1 = number[0];

    if(typeof(number[1]) != "undefined"){

      this.valor2 = number[1];

    }

  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    this.setValor();
  }

}
