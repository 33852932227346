import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../../../../../services/api.service';
import { AppService } from '../../../../../services/app.service';

@Component({
  selector: 'dm-cart-pagamento-gerencia-net-boleto',
  templateUrl: './dm-cart-pagamento-gerencia-net-boleto.component.html',
  styleUrls: ['./dm-cart-pagamento-gerencia-net-boleto.component.scss']
})
export class DmCartPagamentoGerenciaNetBoletoComponent implements OnInit {

  @Input("sendPagamento") sendPagamento   = new EventEmitter();
  @Output("onSuccess")  onSuccess         = new EventEmitter();
  @Input("carrinho") carrinho             = null;
  @Input("formaPagamento") formaPagamento = null;
  
  public dataItem:any                     = {};
  public loader                           = false;
  public message  = "";
  public messageLoader              = "";
  public messageInfo                = {
    show: false,
    data: "",
    class: "",
    title: "Atenção"
  }
  public total          = 0;
  public vencimentoDias = 1;

  constructor(
    private _api: ApiService,
    private _app: AppService
  ){}

  /**
   * 
   * Envia o pagamento
   * 
   */
  send(){

    try{

      this.message  = "Finalizando a Reserva. Aguarde!";
      this.loader   = true;

      this._api.pagamento().finalizarReserva(this.dataItem).subscribe(response => {

        this.loader = false;

        if(response.status == 1){

          let dataFile = response.data.file;
          let dataPay  = response.data.pay;

          if(dataFile.status == 500){

            this.messageInfo.title = "Atenção: houve um problema ao gerar a reserva.";
            this.messageInfo.data  = dataFile.data; 
            this.messageInfo.show  = true;
            this.messageInfo.class = "alert alert-danger";

          }else if(dataPay.status == 500){

            this.messageInfo.title = "Atenção: houve um problema ao gerar o pagamento.";
            this.messageInfo.data  = dataPay.data; 
            this.messageInfo.show  = true;
            this.messageInfo.class = "alert alert-danger";

          }else{
            this.onSuccess.emit(response);
          }

        }else{

          this.messageInfo.title = "Atenção";
          this.messageInfo.data  = response.data; 
          this.messageInfo.show  = true;
          this.messageInfo.class = "alert alert-danger";

        }

      },(response) => {

        this.loader = false;
        let data    = this._app.formatError(response);

        this.messageInfo.title = "Atenção";
        this.messageInfo.data  = data.message;
        this.messageInfo.show  = true;
        this.messageInfo.class = "alert alert-danger";

      });

    }catch(e){

      this.loader = false;

    }

  }
  /**
   * 
   * Set Data
   * 
   */
  setData(){

    if(this.carrinho != null){
      this.total = this.carrinho.total;
      if(this.formaPagamento.status == 1){
        this.vencimentoDias = this.formaPagamento.params.dev_vencimento_dias;
      }else{
        this.vencimentoDias = this.formaPagamento.params.pro_vencimento_dias;
      }  
    }

  }
  /**
   * 
   * Ouvintes
   * 
   */
  onData(){

    this.sendPagamento.subscribe(response => {

      this.send();

    });

  }
   /**
   * 
   * Inicializa o dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      pagamento_id: this.formaPagamento.id, 
      apelido: this.formaPagamento.intermediador_apelido, 
    }

  }
  /**
   * 
   * Iniciliza as Funções
   * 
   */
  ngOnInit(){
    this.initDataItem();
    this.setData();
    this.onData();
  }

}
