import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dm-cart-pagamento-finalizado-sucesso',
  templateUrl: './dm-cart-pagamento-finalizado-sucesso.component.html',
  styleUrls: ['./dm-cart-pagamento-finalizado-sucesso.component.scss']
})
export class DmCartPagamentoFinalizadoSucessoComponent implements OnInit {

  @Input("carrinho") data = null;
  public pagamento        = null;
  public info             = null;
  public hash             = null;

  constructor(){ 

  }
  /**
   * 
   * Seta a Data
   * 
   */
  setData(){

    if(this.data != null){

      this.pagamento = this.data.pagamento;
      this.info      = this.pagamento.info;
      this.hash      = this.data.hash;

    }

  }
  /***
   * 
   * Iniciliza as Funções
   * 
   * 
   */
  ngOnInit(){
    this.setData();
  }

}
