import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dm-cart-pagamento-confirmacao-imprimir',
  templateUrl: './dm-cart-pagamento-confirmacao-imprimir.component.html',
  styleUrls: ['./dm-cart-pagamento-confirmacao-imprimir.component.scss']
})
export class DmCartPagamentoConfirmacaoImprimirComponent implements OnInit {

  @Input("carrinhoId") carrinhoId = null;
  @Input("hash") hash             = null;

  constructor(){ }

  /**
   * 
   * Click
   * 
   */
  _click(){

    window.open("/api/site/componentes/pagamento/imprimir-confirmacao/"+this.hash,"_blank");

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){

  }

}
