<button type="button" class="{{classCustom}}" (click)="openModal()" title="Clique aqui para abandonar esta compra.">
  Abandonar esta Compra
</button>
<div class="modal" tabindex="-1" role="dialog" #modal>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Abandonar esta Compra</h5>
      </div>
      <div class="modal-body">
       <div class="alert alert-info">
        Tem certeza que deseja abandonar essa compra? 
       </div> 
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-one" (click)="abandonar()">SIM</button>
        <button type="button" class="btn-one" (click)="closeModal()">NÃO</button>
      </div>
    </div>
  </div>
  <modulo-loader *ngIf="loader"></modulo-loader>
</div>