<div class="dm-cart-pagamento-resumo">
  <div class="dm-cart-pagamento-resumo-header">
    <h3>RESUMO DA COMPRA</h3>
    <div class="bordas">
      <ul>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>  
    </div>  
  </div>  
  <div class="dm-cart-pagamento-resumo-content">
    <section class="section section-servico" *ngFor="let servico of servicos">
      <div class="nome">
        <h4>SERVIÇO</h4> 
        <h3>{{servico.nome}}</h3>
      </div>
      <div class="content">
        <div class="resumo-itens">
          <ul>
            <li class="item location">
              <img src="/images/icons/alltour/carrinho/resumo/location.svg" alt="Location" />
              <span class="text">
                {{servico.macro_regiao}}
              </span>
            </li>
            <li class="item tipo-servico">
              <img src="/images/icons/alltour/carrinho/resumo/car.svg" alt="Tipo de Serviço" />
              <span class="text" *ngIf="servico.tipo_servico_id == 1">
                REGULAR
              </span>
              <span class="text" *ngIf="servico.tipo_servico_id == 2">
                PRIVATIVO - {{servico.tipo_transporte}} - LOTAÇÃO MÁXIMA: {{servico.lotacao_maxima}}
              </span>
            </li>
            <li class="item hotel">
              <img src="/images/icons/alltour/carrinho/resumo/hotel.svg" alt="Hotel" />
              <span class="text">
                {{servico.nome_hotel}}
              </span>
            </li>
            <li class="item calendario">
              <img src="/images/icons/alltour/carrinho/resumo/calendar.svg" alt="Calendário" />
              <span class="text">
                {{servico.data_utilizacao | date: "dd-MM-yyyy"}}
              </span>
            </li>
          </ul>
        </div> 
        <div class="resumo-valores" *ngIf="servico.tipo_servico_id == 1"> 
          <ul>
            <li>
              <strong class="text-label">Adultos: </strong>
              <span class="text-qtd">{{servico.valor_adt | currency: "R$ "}} x {{servico.qtd_adt}}</span>
              <span>=</span>
              <strong class="subtotal">{{(servico.valor_adt*servico.qtd_adt) | currency:"R$ "}}</strong>
            </li>
            <li>
              <strong class="text-label">Melhor Idade: </strong>
              <span class="text-qtd">{{servico.valor_snr | currency: "R$ "}} x {{servico.qtd_snr}}</span>
              <span>=</span>
              <strong class="subtotal">{{(servico.valor_snr*servico.qtd_snr) | currency:"R$ "}}</strong>
            </li>
            <li>
              <strong class="text-label">Criança Pagante: </strong>
              <span class="text-qtd">{{servico.valor_chd | currency: "R$ "}} x {{servico.qtd_chd}}</span>
              <span>=</span>
              <strong class="subtotal">{{(servico.valor_chd*servico.qtd_chd) | currency:"R$ "}}</strong>
            </li>
            <li>
              <strong class="text-label">Criança Cortesia: </strong>
              <span class="text-qtd">{{servico.qtd_chd}}</span>
            </li>
            <li>
              <strong class="text-label">Total: </strong>
              <strong>{{((servico.valor_adt*servico.qtd_adt)+(servico.valor_chd*servico.qtd_chd)) | currency:"R$ "}}</strong>
            </li>
          </ul>  
        </div> 
        <div class="resumo-valores" *ngIf="servico.tipo_servico_id == 2"> 
          <ul> 
            <li>
              <span class="text-label">Adultos: </span>
              <strong>{{servico.qtd_adt}}</strong>
            </li> 
            <li>
              <span class="text-label">Melhor Idade: </span>
              <strong>{{servico.qtd_snr}}</strong>
            </li> 
            <li>
              <span class="text-label">Criança Pagante: </span>
              <strong>{{servico.qtd_chd}}</strong>
            </li> 
            <li>
              <span class="text-label">Criança Cortesia: </span>
              <strong>{{servico.qtd_inf}}</strong>
            </li> 
            <li>
              <span class="text-label">Valor Serviço: </span>
              <strong class="subtotal">{{servico.valor_servico | currency:"R$ "}}</strong>
            </li>
          </ul>  
        </div>  
        <div class="linha"></div> 
    </div>  
    </section>
    <section class="section section-total">
      <div class="total">
        <ul>
          <li class="valor">
            <span class="cifrao">R$</span>
            <span class="number">{{total | currency | clearCifrao}}</span>
          </li> 
        </ul>   
      </div>  
    </section>  
  </div>  
</div>
