<div class="evento-search">
  <form class="form" (submit)="getData()">
    <div class="row">
      <div class="col-md-5">
        <div class="form-group">
          <label>NOME DO EVENTO</label>
          <input type="text" [(ngModel)]="dataItem.titulo" name="titulo" />
        </div>  
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>STATUS</label>
          <mat-form-field>
            <mat-select [(ngModel)]="dataItem.status" name="status">
              <mat-option *ngFor="let i of comboStatus" [value]="i.value">
                {{i.text}}
              </mat-option>
            </mat-select>
          </mat-form-field> 
        </div>  
      </div> 
      <div class="col-md-2">
        <button type="submit">
          BUSCAR
        </button>  
      </div>  
    </div>  
  </form>  
</div>
<modulo-loader message="Carregando os Eventos" *ngIf="loader"></modulo-loader>
