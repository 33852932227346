import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ApiService } from '../../../../../services/api.service';
import { AppService } from '../../../../../services/app.service';

declare var CardInfo:any;

@Component({
  selector: 'dm-cart-pagamento-rede-pay',
  templateUrl: './dm-cart-pagamento-rede-pay.component.html',
  styleUrls: ['./dm-cart-pagamento-rede-pay.component.scss']
})
export class DmCartPagamentoRedePayComponent implements OnInit {

  @Input("sendPagamento") sendPagamento   = new EventEmitter();
  @Output("onSuccess")  onSuccess         = new EventEmitter();
  @Input("carrinho") carrinho             = null;
  @Input("formaPagamento") formaPagamento = null;
  
  public dataItem:any                     = {};
  public loader                           = false;
  public message  = "";
  public messageLoader              = "";
  public messageInfo                = {
    show: false,
    data: "",
    class: "",
    title: "Atenção"
  }
  public total = 0;
  public subscribeMessage = null;

  constructor(
    private _api: ApiService,
    private _app: AppService,
    private app: AppService,  
    private recaptchaV3Service: ReCaptchaV3Service
  ){}

  /**
   * 
   * Envia o pagamento
   * 
   */
  send(){

    try{

      this.message  = "Finalizando a Reserva. Aguarde!";
      this.loader   = true;

      this.recaptchaV3Service.execute('importantAction')
        .subscribe((token) => {

          this.dataItem.token = token;

          this._api.pagamento().finalizarReserva(this.dataItem).subscribe(response => {

            this.loader = false;

            if(response.status == 1){

              let dataFile = response.data.file;
              let dataPay  = response.data.pay;
              let error    = "";

              if(dataFile.status == 500){

                error  = "Atenção: houve um problema ao gerar a reserva.<br>";
                error += dataFile.data; 

                //this.subscribeMessage  = error;
                this.app.info(error);

              }else if(dataPay.status == 500){

                error = "Atenção: houve um problema ao gerar o pagamento.<br>";
                error += dataPay.data; 
                
                this.app.info(error);

                //this.subscribeMessage  = error;

              }else{
                this.onSuccess.emit(response);
              }

            }else{

              this.app.info(response.data);
              //this.subscribeMessage  = response.data; 
              

            }

          },(response) => {

            this.loader = false;
            let data    = this._app.formatError(response);

            this.app.info(data.message);
            //this.subscribeMessage = data.message;

            //this.messageInfo.title = "Atenção";
            //this.messageInfo.data  = data.message;
            //this.messageInfo.show  = true;
            //this.messageInfo.class = "alert alert-danger";

          });

        },(response) => { 

          this.loader   = false;
          this.app.info("Token de validação inválido. Recarregue a página para continuar.");
  
        });

    }catch(e){

      this.loader = false;

    }

  }
  /**
   * 
   * Seta a bandeira baseado no número do cartão
   * 
   */
  setBrand(){ 
    
    let info = new CardInfo(this.dataItem.cartao.numero_cartao);

    var cardInfo = new CardInfo(this.dataItem.cartao.numero_cartao,{
      brandsLogosPath: "/images/pagamento/brands/",
      brandLogoPolicy: "colored"
    });

    this.dataItem.cartao.brand      = cardInfo.brandAlias; 
    this.dataItem.cartao.brand_logo = cardInfo.brandLogoPng;

  }
  /**
   * 
   * Inicializa o dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      pagamento_id: this.formaPagamento.id, 
      apelido: this.formaPagamento.intermediador_apelido, 
      cartao: {
        numero_cartao: "",
        data_validade_mes: "",
        data_validade_ano: "",
        data_validade: "",
        nome_portador: "",
        codigo_seguranca: "",
        brand: null,
        brand_logo: null,
        parcelas: null
      }
    }

  }
  /**
   * 
   * Change número de parcelas
   * 
   */
  changeValueParcela(parcela){

    this.dataItem.cartao.parcelas = parcela;

  }
  /**
   * 
   * Set Data
   * 
   */
  setData(){

    if(this.carrinho != null){
      this.total = this.carrinho.total;
    }

  }
  /**
   * 
   * Ouvintes
   * 
   */
  onData(){

    this.sendPagamento.subscribe(response => {

      this.send();

    });

  }
  setDadosTeste(){

    this.dataItem.cartao.nome_portador     = "TESTE";
    this.dataItem.cartao.parcelas          = "1";
    this.dataItem.cartao.numero_cartao     = "5448280000000007";
    this.dataItem.cartao.data_validade_mes = "01";
    this.dataItem.cartao.data_validade_ano = "20";
    this.dataItem.cartao.data_validade     = "01/20";
    this.dataItem.cartao.codigo_seguranca  = "123";

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.initDataItem();
    this.setData();
    this.onData();
  }

}
