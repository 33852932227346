<div class="galeria-fotos">
  <div class="galeria-fotos-content" *ngIf="fotos.length > 0">
    <div class="foto">
      <img [src]="fotos[indexSelected].imagem" [alt]="fotos[indexSelected].alt" class="img-fluid" />
    </div>
    <div class="lista">
      <ul>
        <li (click)="setIndex(i)" [ngClass]="{'active':indexSelected == i}" *ngFor="let foto of fotos;let i=index;">
          <img [src]="foto.imagem" [alt]="foto.alt" class="img-fluid" />
        </li>
      </ul>  
    </div>  
  </div>  
</div>
