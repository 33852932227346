import { CartService } from './../../../services/cart.service';
import { Component, OnInit, Input, Inject, Output,EventEmitter } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ModalConfirmDeleteComponent } from '../modal-confirm-delete/modal-confirm-delete.component';

@Component({
  selector: 'btn-delete-cart-item',
  templateUrl: './btn-delete-cart-item.component.html',
  styleUrls: ['./btn-delete-cart-item.component.scss'],
  outputs: ['updateCartItem']
})
export class BtnDeleteCartItemComponent implements OnInit {

  @Input("id") id; 
  @Input("nome") nome;
  public modalDel;   

  constructor(
    public modal: MatDialog,
    private CartService: CartService
  ){

  }
  /**
   * 
   * Update Cart
   * 
   */
  updateCart(){

    this.CartService.deleteItemInCartEmit(this.id)
    this.modalDel.close()
  }
  /**
   * 
   * Modal Delete
   * 
   */
  modalDelete(){

    this.modalDel = this.modal.open(ModalConfirmDeleteComponent,{
      width: "400px",
      data: {
        nome: this.nome,
        id: this.id,
        updateCart:()=>{
          return this.updateCart()
        }
      }
    })

  }
  /**
   * 
   * Iniciliza as Funções
   * 
   */
  ngOnInit(){
  }

}
