import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, RouterEvent } from '@angular/router';
import { ApiService } from '../../../services/api.service';
import { AppService } from '../../../services/app.service';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'servico-page',
  templateUrl: './servico-page.component.html',
  styleUrls: ['./servico-page.component.scss']
})
export class ServicoPage implements OnInit,OnDestroy {

  public apelido = null;
  public produto = null;
  public categoria = null;
  public fotos   = [];
  public loader  = false;
  public messageLoader = "Carragendo Serviço...";
  public response      = {
    data: "",
    status: null
  }
  public macro_regiao      = [];
  public regiao:any            = {};
  public configuracao_loja = null;
  public ultimoServicoInserido   = null;
  public produtos_relacionados   = [];
  public mySubscription:Subscription;
  public destroyed        = new Subject<any>();
  public destroy          = false;
  public itensBreadcrumbs = [];
  public showAddCarrinho  = false;
  public addCart          = false;

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private router: Router,
    private app: AppService
  ){
    this.apelido = typeof(this.route.params["_value"].apelido_produto) != "undefined" ? this.route.params["_value"].apelido_produto : null;
  }
  /**
   * 
   * Retorna os dados do produto baseado no apelido
   * 
   * 
   */
  getData(){

    try{

      this.loader = true;

      this.api.produto().produto(this.apelido).subscribe(response => {

        this.loader = false;

        if(response.status == 1){

          this.showAddCarrinho = false; 
          if(response.data.produto == null){
            this.router.navigateByUrl("/");
          }
          this.produto               = response.data.produto;
          this.fotos                 = response.data.fotos;
          this.categoria             = response.data.categoria; 
          this.macro_regiao          = response.data.macro_regiao;
          this.regiao                = response.data.regiao;
          this.configuracao_loja     = response.data.configuracao_loja;
          this.ultimoServicoInserido = response.data.servico;
          this.produtos_relacionados = response.data.relacionados;
          this.response.status       = 1;

          this.setItensBreadcrumbs();
 
          let title = "Serviços - "+this.regiao.titulo+" - "+this.categoria.titulo+" - "+this.produto.nome;
          this.app.setMetaDados(title,this.produto.meta_description);

          
          this.app.toTop();

        }else{
          this.response = response;
        }

      },(response) => {

        this.loader = false;
        let error   = this.app.formatError(response);

        this.response = {
          data: error.message,
          status: 500
        }

      });

    }catch(e){

      this.loader = false;

    }

  }
  setItensBreadcrumbs(){

    this.itensBreadcrumbs.push({
      text: "Serviços",
      routerLink: "/servicos"
    });
    if(this.regiao != null){
      
      this.itensBreadcrumbs.push({
        text: this.regiao.titulo, 
        routerLink: "/servicos/"+this.regiao.apelido
      });

    }  
    this.itensBreadcrumbs.push({
      text: this.categoria.titulo,
      routerLink: ""
    });
    this.itensBreadcrumbs.push({
      text: this.produto.nome
    });

  }
  /**
   * 
   * Ouvinte
   * 
   */
  onData(){

    this.app.emitChangePage.subscribe(response => {

      let apelido = typeof(response.apelido) != "undefined" ? response.apelido : null;

      if(apelido != null){

        if(apelido != this.apelido){

          this.apelido = apelido;
          this.getData();

        }

      }

    });

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){

    this.app.toTop();
    this.getData();

    this.mySubscription = this.router.events.pipe(
      filter((event:RouterEvent) => event instanceof NavigationEnd),
      takeUntil(this.destroyed)
    ).subscribe(() => {
      console.log(this.destroy)
      if(!this.destroy){
        this.apelido = this.route.params["_value"].apelido_produto;
        this.getData();
      }  

    });

  }
  /**
   * 
   * Destroy
   * 
   * 
   */
  ngOnDestroy() {
    
    if(this.mySubscription){
      this.destroy         = true;
      this.mySubscription.unsubscribe(); 
      this.addCart         = false;
      this.showAddCarrinho = false; 
    }
  }

}
