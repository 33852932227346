<div class="dm-cart-pagamento-steps">
  <ul>
    <li [ngClass]="{'active':step == 1,'success':step > 1}">
      <div class="item">
        <span class="number">1</span>
        <div class="linha"></div>
        <span class="text">Dados do Comprador</span>
      </div>  
    </li>
    <li [ngClass]="{'active':step == 2,'success':step > 2}">
      <div class="item">
        <span class="number">2</span>
        <div class="linha"></div>
        <span class="text">Formas de Pagamento</span>
      </div>  
    </li>
    <li [ngClass]="{'active':step == 3}">
      <div class="item">
        <span class="number">3</span>
        <div class="linha"></div> 
        <span class="text">Confirmação de Compra</span>
      </div>  
    </li>
  </ul>  
</div>
