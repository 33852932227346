<div class="meios-de-pagamento">
  <div class="titulo">
    <h3>MEIOS DE PAGAMENTO</h3>
  </div>  
  <div class="content">
    <div class="bloco">
      <div class="row">
        <div class="col-4"> 
          <figure>
            <img src="/images/icons/sao-paulo-ecoturismo/meios-de-pagamento/visa.svg" alt="Visa - Meio de Pagamento" />
          </figure>  
        </div>
        <div class="col-4"> 
          <figure>
            <img src="/images/icons/sao-paulo-ecoturismo/meios-de-pagamento/mastercard.svg" alt="Mastercard - Meio de Pagamento" />
          </figure>  
        </div>
        <div class="col-4"> 
          <figure>
            <img src="/images/icons/sao-paulo-ecoturismo/meios-de-pagamento/american-express.svg" alt="American Express - Meio de Pagamento" />
          </figure>  
        </div>
      </div> 
      <div class="row"> 
        <div class="col-4"> 
          <figure>
            <img src="/images/icons/sao-paulo-ecoturismo/meios-de-pagamento/dinners-club.svg" alt="Dinners Club - Meio de Pagamento" />
          </figure>  
        </div>
        <div class="col-4"> 
          <figure>
            <img src="/images/icons/sao-paulo-ecoturismo/meios-de-pagamento/ello.jpg" alt="Ello - Meio de Pagamento" />
          </figure>  
        </div>  
      </div> 
    </div>   
  </div>  
</div>
