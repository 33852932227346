import { Component, OnInit } from '@angular/core';
import { ConteudoService } from '../../../services/conteudo.service';
import { AppService } from '../../../services/app.service';

@Component({
  selector: 'servicos-aeroportuarios-page',
  templateUrl: './servicos-aeroportuarios-page.component.html',
  styleUrls: ['./servicos-aeroportuarios-page.component.scss']
})
export class ServicosAeroportuariosPage implements OnInit {

  public loader       = false;
  private apelido     = "servicos-aeroportuarios";
  public response:any = {
    data: "",
    status: null
  };
  public page    = null;
  public fotos   = []; 
  public params  = {};
  public valores = [];

  constructor(
    private conteudo: ConteudoService,
    private app: AppService
  ){ }
  
  /**
   * 
   * Retorna os dados
   * 
   * 
   */
  getData(){

    try{
      
      this.loader = true;
      
      this.conteudo.getPageByApelido(this.apelido).subscribe(response => {
      
        this.loader = false;
      
        if(response.status == 1){
                
          if(typeof(response.data.page) != "undefined"){
                  
            this.page      = typeof(response.data.page.data[0]) != "undefined" ? response.data.page.data[0] : response.data.page.data;
            this.params    = JSON.parse(this.page.params);
            this.fotos     = response.data.page.fotos; 
            this.app.setMetaDados(this.page.meta_title,this.page.meta_description);
              
          }
          this.response = {
            data: "",
            status: 1
          }
      
      
        }else{
          this.response = {
            data: response.data,
            status: 500
          }
        }
      
      },(err) =>{
      
        this.loader = false;
        this.response = err;
      
      });
    
    }catch(e){
      
    }

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.app.toTop();
    this.getData();
  }

}
