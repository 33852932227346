import { CartService } from './../../../services/cart.service';
import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../services/data.service';

@Component({
  selector: 'total-cart',
  templateUrl: './total-cart.component.html',
  styleUrls: ['./total-cart.component.scss']
})
export class TotalCartComponent implements OnInit {

  public total = 0;
  public qtd   = 0;

  constructor(
    private CartService: CartService,
    private DataService: DataService
  ) { }

  /**
   * 
   * Ouvintes
   * 
   */
  onData(){

    this.CartService.emitirUpdateTotalCart.subscribe(data => {
      this.setTotal(data);
    })

  }
  setTotal(data){
    this.total = data.total;
    this.qtd   = data.qtd; 
    this.DataService.setTotalCart(data);
  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit() {

    let dataTotal = this.DataService.getTotalCart();
    if(dataTotal != null){
      this.setTotal(dataTotal);
    }

    this.onData();
  }

}
