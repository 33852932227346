<div class="produto-categoria-item">
  <div class="capa">
    <figure *ngIf="categoria.foto != null">
      <img [src]="categoria.foto" [alt]="categoria.foto_alt" class="img-fluid" />
    </figure> 
    <figure *ngIf="categoria.foto == null">
      <img src="/images/produto/categoria/sem-foto/sem-foto.jpg" alt="Sem Foto" class="img-fluid" />
    </figure> 
  </div>
  <div class="content">
    <h3>{{categoria.titulo}}</h3>
    <div class="content-link">
      <a routerLink="/servicos/{{categoria.apelido}}" title="{{categoria.titulo}}">
        SAIBA MAIS
      </a>  
    </div>  
  </div>  
</div>
