import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dm-cart-item',
  templateUrl: './dm-cart-item.component.html',
  styleUrls: ['./dm-cart-item.component.scss']
})
export class DmCartItemComponent implements OnInit {

  @Input("item") item = null;

  constructor(){ 
    
  }

  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){

  }

}
