<div class="page page-politicas-de-cancelamento" itemscope itemtype="http://schema.org/Article">
  <div *ngIf="response.status == 1">
    <div class="container">
      <div class="page-title wow fadeIn" data-wow-delay="0.5s" *ngIf="page.mostrar_titulo">
        <h1 itemprop="headline">{{page.titulo}}</h1>
        <div class="linha"></div>
        <h2 *ngIf="page.mostrar_subtitulo">{{page.subtitulo}}</h2>
      </div>
    </div>  
    <div class="page-content">
      <section class="section descricao wow fadeIn" data-wow-delay="0.7s" *ngIf="page.mostrar_texto">
        <div class="container">
          <article itemprop="description" [innerHTML]="page.texto | safeHtml"></article>
        </div>  
      </section> 
    </div> 
  </div> 
  <div *ngIf="response.status == 500">
    <div class="container">
      <error-custom title="Não foi possível carregar os dados da Página" [details]="response.data" (reload)="getData()"></error-custom>
    </div>  
  </div>  
</div>
<modulo-loader message="Carregando Conteúdo" [hidden]="!loader"></modulo-loader> 
      