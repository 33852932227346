import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'produto-slide-show',
  templateUrl: './produto-slide-show.component.html',
  styleUrls: ['./produto-slide-show.component.scss']
})
export class ProdutoSlideShowComponent implements OnInit {

  @Input("fotos") fotos = [];
  public indexSelected  = 0;
  public show           = true;

  constructor() { }

  /***
   * 
   * Set Index
   * 
   */
  setIndexSelected(){

    if(this.fotos.length > 0){

      for(let index = 0; index < this.fotos.length; index++) {

        if(this.fotos[index].capa == true){
          this.indexSelected = index;
        }
        
      }

    }

  }
  /**
   * 
   * Next
   * 
   */
  next(){

    if(this.indexSelected < (this.fotos.length-1)){
      this.indexSelected++;
    }else{
      this.indexSelected = 0;
    } 

  }
  /**
   * 
   * Prev
   * 
   */
  prev(){

    this.show = false;

    if(this.indexSelected >= 1){
      this.indexSelected--;
    }else{
      this.indexSelected = this.fotos.length-1;
    } 
    
    setTimeout(() => {
      this.show = true;
    },0);

  }
  /**
   * 
   * Seta o INDEX
   * 
   */
  setIndex(index){
    this.indexSelected = index;
  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){

    this.setIndexSelected();

  }

}
