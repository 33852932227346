<div class="modulo modulo-menu-o-que-fazemos">
  <div class="modulo-titulo">
    <h3>O que fazemos</h3>
    <h4>conheça um pouco do nosso trabalho :)</h4>
  </div>
  <div class="modulo-content"> 
    <div class="menu">
      <ul>
        <li>
          <a routerLink="o-que-fazemos/viagens-corporativas" title="Viagens Corporativas">
            VIAGENS CORPORATIVAS
          </a>  
        </li>
        <li>
          <a routerLink="o-que-fazemos/grupos-e-eventos" title="Incentivos, Grupos e Eventos">
            INCENTIVOS, GRUPOS E EVENTOS
          </a>  
        </li>
        <li>
          <a routerLink="o-que-fazemos/receptivo" title="Receptivo">
            RECEPTIVO
          </a>  
        </li>
        <li>
          <a routerLink="o-que-fazemos/servicos-aeroportuarios" title="Serviços Aeroportuários">
            SERVIÇOS AEROPORTUÁRIOS
          </a>  
        </li>
      </ul>  
    </div>  
  </div>  
</div>
