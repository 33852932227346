import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'modulo-produto-categoria-destaque',
  templateUrl: './produto-categoria-destaque.component.html',
  styleUrls: ['./produto-categoria-destaque.component.scss']
})
export class ProdutoCategoriaDestaqueComponent implements OnInit {

  @Input("data") data;
  @Input("classCustom") classCustom = "";
  @Input("template") template = null;  
  public produtos             = [];
  public categorias           = [];
  
  constructor() { }
  
  /**
   * 
   * Seta os produtos
   * 
   */
  setProdutos(){

    //this.produtos = this.agruparPorCategoria(this.data.produtos);
    this.produtos = this.data.produtos
    
  }
  /**
   * 
   * Agrupa por categoria
   * 
   */
  agruparPorCategoria(produtos){

    let data   = [];
    let i      = null;

    for (let index = 0; index < produtos.length; index++) {
      
      i = this.hasCategoria(produtos[index].categoria_id);

      if(i != null){

        data[i].produtos.push(produtos[index]);

      }else{

        data.push({
          id: produtos[index].categoria_id,
          titulo: produtos[index].categoria_titulo,
          subtitulo: produtos[index].categoria_subtitulo,
          apelido: produtos[index].categoria_apelido,
          produtos: [produtos[index]]
        });

        this.categorias.push(produtos[index].categoria_id);

      }
      
    }

    return data;
  
  }
  /**
   * 
   * Verifica se já tem 
   * 
   */
  hasCategoria(id){

    let index = null;

    for(let i = 0; i < this.categorias.length;i++){
      
      if(this.categorias[i] == id){
        index = i;
      }
      
    }

    return index;

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.setProdutos();
  }

}
