import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'dm-cart-dados-voo',
  templateUrl: './dm-cart-dados-voo.component.html',
  styleUrls: ['./dm-cart-dados-voo.component.scss']
})
export class DmCartDadosVooComponent implements OnInit {

  @Input("dataItem") dataItem:any                = {}; 
  @Output("changeCiaAerea") _changeCiaAerea   = new EventEmitter();
  @Output("changeAeroporto") _changeAeroporto = new EventEmitter();
  @Input("ciaValue") ciaValue                 = null;
  @Input("ciaText") ciaText                   = null;
  @Input("aeroportoValue") aeroportoValue     = null;
  @Input("aeroportoText") aeroportoText       = null;

  constructor() { }


  /**
   * 
   * Change value
   * 
   */
  changeCia(data){
    this._changeCiaAerea.emit(data);
  }
  /**
   * 
   * Change value
   * 
   */
  changeAeroporto(data){
    this._changeAeroporto.emit(data);
  }
  /**
   * 
   * Change VOO
   * 
   */
  changeVoo(value){

    alert(value) 

  }
  /**
   * 
   * Iniciliza Funções
   * 
   * 
   */
  ngOnInit(){
  
  }

}
