import { Component, OnInit, Input } from '@angular/core';
import { AppService } from '../../../services/app.service';
import { ApiService } from '../../../services/api.service';

@Component({
  selector: 'dm-cart-passageiro-titular',
  templateUrl: './dm-cart-passageiro-titular.component.html',
  styleUrls: ['./dm-cart-passageiro-titular.component.scss']
})
export class DmCartPassageiroTitularComponent implements OnInit {

  @Input("passageiros") passageiros = [];
  public passageiroTitular:any      = {};
  public passageiro_id              = null;
  public loader                     = false;
  public messageLoader              = "Alterando o Passageiro Titular. Aguarde!"
  public message = {
    data: "",
    class: "",
    show: false
  };
  public subscribeMessage = null;

  constructor(
    private api: ApiService,
    private app: AppService
  ){ }

  /***
   * 
   * Filtra os passageiros por tipo
   * 
   * 
   */
  filterPassageirosTipo(){

    let data  = [];

    for(let index = 0; index < this.passageiros.length; index++) {
      
      if(this.passageiros[index].tipo == "adt" || this.passageiros[index].tipo == "snr"){

        data.push(this.passageiros[index]);

      }
      
    }

    this.passageiros = data;

  }
  /**
   * 
   * Seta o passageiro titular
   * 
   */
  setPassageiroTitular(){

    for(let index = 0;index < this.passageiros.length;index++) {
      
      if(this.passageiros[index].titular){
        this.passageiroTitular = this.passageiros[index];
        this.passageiro_id     = this.passageiroTitular.id; 
        break;
      }
      
    }

  }
  /***
   * 
   * Altera os dados
   * 
   * 
   */
  update(){

    try{

      
      this.loader = true;
      this.subscribeMessage = null;

      this.api.cart().update_passageiro_titular(this.passageiro_id).subscribe((response) => {

        this.loader = false;

        switch(response.status){
          case 1:
            this.subscribeMessage = "O titular da reserva foi alterado";
          break;
          case 0:
            
          let errors = this.app.getErrors([response.data]);
            
            this.subscribeMessage = errors;

          break;
          default:
            this.subscribeMessage  = "Houve um erro interno. Tente mais tarde ou informe nossa equipe.";
          break;
        }

      },(response) => {

        this.loader           = false;
        let error             = this.app.formatError(response);
        this.subscribeMessage = error.message;

      });

    }catch(e){

      this.loader = true;

    }


  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.filterPassageirosTipo();
    this.setPassageiroTitular();
  }

}
