import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dm-cart-tarifas-resumo',
  templateUrl: './dm-cart-tarifas-resumo.component.html',
  styleUrls: ['./dm-cart-tarifas-resumo.component.scss']
})
export class DmCartTarifasResumoComponent implements OnInit {

  @Input("dataItem") dataItem:any = {
    data_utilizacao: "",
    valor_adt: 0,
    valor_snr: 0,
    valor_chd: 0,
    valor_servico: 0
  };

  constructor(){ }
  
  /**
   * 
   * Iniciliza as Funções
   * 
   */
  ngOnInit(){
    
  }

}
