import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dm-cart-pagamento-resumo',
  templateUrl: './dm-cart-pagamento-resumo.component.html',
  styleUrls: ['./dm-cart-pagamento-resumo.component.scss']
})
export class DmCartPagamentoResumoComponent implements OnInit {

  @Input("carrinho") carrinho = null;
  public total                = 0;
  public servicos             = [];

  constructor(

  ){ }

  /**
   * 
   * Set Data
   * 
   */
  setData(){

    if(this.carrinho != null){

      this.total    = this.carrinho.total;
      this.servicos = this.carrinho.cart_itens;

    }

  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    this.setData();
  }

}
