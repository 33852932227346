<div class="dm-cart-pagamento-dados-comprador">
  <div class="title">
    <h3>Dados do Comprador</h3>
  </div>  
  <form class="form form-custom dm-form-input-2 dm-form-placeholder-bold" autocomplete="off" autocapitalize="off">
    <div class="row row-custom">
      <div class="col-md-7 _col">
        <input type="text" [(ngModel)]="dataItem.nome" placeholder="NOME COMPLETO*" name="nome" uppercase />
      </div> 
      <div class="col-md-5 _col">
        <input type="text" [(ngModel)]="dataItem.data_nascimento" placeholder="DATA NASCIMENTO*" name="nome" mask="99-99-9999" />
      </div>  
    </div>  
    <div class="row row-custom">
      <div class="col-md-6 _col">
        <input type="text" [(ngModel)]="dataItem.email" placeholder="E-MAIL*" name="email" />
      </div> 
      <div class="col-md-6 _col">
        <input type="text" [(ngModel)]="dataItem.telefone" placeholder="TELEFONE*" name="telefone" mask="(99) 99999-9999"  />
      </div>  
    </div>
    <div class="row row-custom">
      <div class="col-md-6 _col">
        <input type="text" [(ngModel)]="dataItem.cpf" placeholder="CPF*" name="cpf" mask="999.999.999-99" />
      </div> 
      <div class="col-md-6 _col">
        <input type="text" [(ngModel)]="dataItem.rg" placeholder="RG*" name="rg" />
      </div>  
    </div>
  </form>
  <div class="info">
    <span>*Itens Obrigatórios</span>
  </div>
  <div class="subscribe-message" *ngIf="subscribeMessage != null" [innerHtml]="subscribeMessage"></div>  
</div>
<modulo-loader [message]="message" [hidden]="!loader"></modulo-loader>
<!--<modal-info [show]="messageInfo.show" [customClass]="messageInfo.class" [message]="messageInfo.data" (hide)="messageInfo.show=false"></modal-info>-->

