<div class="dm-cart-pagamento-confirmacao-imprimir">
  <div class="titulo">
    <h3>Para imprimir o seu comprovante, clique no link abaixo:</h3>
  </div>  
  <div class="content">
    <button type="submit" class="btn-custom-five" (click)="_click()">
      <i class="fa fa-print"></i>
      <span>Imprimir Comprovante</span>
    </button>
  </div>  
</div>
