<div class="produto produto-template-one {{classCustom}}">
  <div class="capa">
    <produto-foto [produto]="produto"></produto-foto> 
  </div> 
  <div class="content">
    <div class="titulo">
      <h4>{{produto.nome}}</h4>
      <h5>{{produto.mini_descricao}}</h5>
    </div>  
    <div class="content-valor">
      <span class="a-partir">
        A partir de:
      </span>
      <div class="consulte" *ngIf="produto.valor == 0">
        <span>CONSULTE</span>
      </div>  
      <div class="valor" *ngIf="produto.valor > 0">
          <span class="cifrao">R$</span>
          <span class="number-01">{{produto.valor | produtoValor:'0'}}</span>
          <span class="number-02">{{produto.valor | produtoValor:'1'}}</span>
      </div>
      <span class="por-pessoa">por pessoa</span>  
    </div>  
    <div class="clearfix"></div>
    <a href="https://loja.bustour.com.br/" class="compre-agora {{classBtn}}" target="__blank">
      COMPRE AGORA
    </a>  
  </div>  
</div>
