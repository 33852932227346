<div class="modulo modulo-destinos">
  <div class="modulo-title" *ngIf="data.mostrar_titulo">
    <h2>{{data.titulo}}</h2>
    <h3 *ngIf="data.mostrar_subtitulo">
      {{data.subtitulo}}
    </h3>
  </div>  
  <div class="modulo-content">
    <div class="container">
      <div class="destinos">
        <ul>
          <li *ngFor="let destino of destinos;let i=index;">
            <a routerLink="destinos/{{destino.apelido}}" [title]="destino.descricao"> 
              <div class="item">
                <figure class="foto">
                  <img [src]="destino.foto" [alt]="destino.descricao" class="img-fluid" />
                </figure>
                <div class="titulo"> 
                  <h4>{{destino.descricao}}</h4>
                </div>  
                <div class="overlay">
                  <img src="/images/icons/aextour/destino/overlay.png" alt="Overlay" > 
                </div>  
              </div>  
            </a>  
          </li>  
        </ul>  
      </div>  
    </div>  
  </div>  
</div>
