import { AppService } from './../../../services/app.service';
import { ModulosService } from './../../../services/modulos.service';
import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'modulo-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {
  
  @Input("data") data;
  @Input("template") template;
  public dataItem:any = {};
  public loader       = false;
  public message = {
    data: "",
    class: "",
    show: false
  };
  public messageLoader = "Enviando. Aguarde!"; 

  constructor(
    private modulos: ModulosService,
    private app: AppService
  ){ }

  /**
   * 
   * Envia os dados do contato para a news
   * 
   */
  send(){

    try{

      this.loader = true;

      this.modulos.sendNewsletter(this.dataItem).subscribe(response => {

        this.loader = false;

        switch(response.status){
          case 1:
            this.message.data = "Seus dados foram enviados com sucesso.";
            this.message.show = true;
            this.message.class = "alert alert-success";
            this.initDataItem();
          break;
          case 0:
            let errors = this.app.getErrors([response.data]);
            
            this.message.data = errors;
            this.message.show = true;
            this.message.class = "alert alert-danger";
          break;
          default:
            this.message.data  = "Houve um erro interno. Tente mais tarde ou informe nossa equipe.";
            this.message.show  = true;
            this.message.class = "alert alert-danger";
          break;
        }

      },(response) => {

        this.loader        = false;
        this.message.data  = "Houve um erro interno. Tente mais tarde ou informe nossa equipe.";
        this.message.show  = true;
        this.message.class = "alert alert-danger";

      });


    }catch(e){

    }

  }
  /**
   * 
   * Inicializa o dataItem
   * 
   * 
   */
  initDataItem(){

    this.dataItem = {
      nome: "",
      email: ""
    }

  }
  /**
   * 
   * Converte para string o dataItem
   * 
   */
  toString(){

    return JSON.stringify(this.dataItem);
    
  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit() {
    this.initDataItem();
  }
  

}
