import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'menu-o-que-fazemos',
  templateUrl: './menu-o-que-fazemos.component.html',
  styleUrls: ['./menu-o-que-fazemos.component.scss']
})
export class MenuOQueFazemosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
