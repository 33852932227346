<div class="modulo modulo-lista-categorias">
    <div class="modulo-title" *ngIf="data.mostrar_titulo">
      <h3>{{data.titulo}}</h3>
    </div>  
    <div class="modulo-content">
      <div class="item" *ngFor="let i of data.categorias">
        <div class="container">
          <div class="texto-content">
            <section class="texto"> 
              <div class="header">
                <div class="frase-autor">
                  <h4>
                    {{i.frase_autor}}
                    <span class="nome-autor">{{i.nome_autor}}</span>
                  </h4>
                </div>
                <div class="titulo">
                  <h2>{{i.titulo_capa}}</h2>
                  <h3>{{i.subtitulo_capa}}</h3>
                </div>  
              </div>
              <div class="content"> 
                <article [innerHtml]="i.texto | safeHtml"></article>
              </div>  
              <div class="saiba-mais">
                <a routerLink="/servicos/{{i.apelido}}" title="{{i.titulo}}">
                  SAIBA MAIS
                </a>  
              </div>
              <div class="borda-content">
                <div class="borda borda-one"></div>
                <div class="borda borda-two"></div>
              </div>
            </section>
          </div>
        </div>
        <ul class="fotos" *ngIf="i.fotos.length > 0">
          <li *ngFor="let f of i.fotos;let y=index;" [ngStyle]="{'width': largura[y]+'px'}">
            <!--<figure>
              <img [src]="f.imagem" [alt]="f.alt" class="img-fluid"/>
            </figure>-->
            <div class="foto-item" [ngStyle]="{'background-image': 'url('+f.imagem+')'}"></div>
          </li>  
        </ul>  
      </div>  
    </div>  
</div>
