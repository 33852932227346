import { Component, OnInit, EventEmitter } from '@angular/core';
import { AtividadeService } from '../../services/atividade.service';
import { AppService } from '../../services/app.service';
import { CartService } from '../../services/cart.service';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'pagamento-one-page',
  templateUrl: './pagamento-one-page.component.html',
  styleUrls: ['./pagamento-one-page.component.scss']
})
export class PagamentoOnePage implements OnInit {
  
  public onSaveDadosComprador = new EventEmitter();
  public onSendPagamento      = new EventEmitter();
  public step     = 1;
  public loader   = false;
  public message  = "";
  public response = {
    data: "",
    status: null 
  }
  public responsePagamento:any = {
    dataFile: {
      data: null,
      status: null
    },
    dataPay: {
      data: null,
      status: null
    }
  }
  public carrinho           = null;
  public dadosComprador:any = {};
  public formasPagamento    = [];
  public fileSession        = null;
  public isPagamento        = false;
  public pagamentoSuccessId = null;
  
  constructor(
    private _atividade: AtividadeService,
    private _app: AppService,
    private _cart: CartService,
    private _router: Router,
    private _api: ApiService,
    private _db: DataService
  ){ }

  /**
   * 
   * Seta o Step
   * 
   * @param step 
   * 
   */
  setStep(step){

    if(step == 2){
      this.onSaveDadosComprador.emit();
      return false;
    }
    if(step == 3){
      this.onSendPagamento.emit();
      return false;
    }
    this.step = step;

  }
  /**
   * 
   * Busca os dados do Carrinho
   * 
   * 
   */
  getCart(){
    
    try{
    
      this.loader   = true;
      this.message  = "";

      this._api.cart().get().subscribe(response=>{
    
        this.loader   = false;
        this.response = response;

        if(response.status == 1){
          
          if(response.data != null){

            this._cart.emitirUpdateTotalCart.emit({
              total: response.data.total,
              qtd: response.data.cart_itens.length
            });
            this.carrinho        = response.data;
            this.formasPagamento = response.data.formas_pagamento;
            this.dadosComprador  = response.data.cart_dados;
            
            if(this.dadosComprador.nome !== null && this.dadosComprador.nome !== ""){
              setTimeout(() => {
                this.setStep(2);
              },100);
            }
          }else if(response.fileSession){

            this.carrinho        = response.cart_pagamento;
            this.formasPagamento = response.cart_pagamento.formas_pagamento;
            this.dadosComprador  = response.cart_pagamento.cart_dados;
            this.fileSession     = response.fileSession;
            this.step            = 2;
            
          }else if(response.pagamentoSucessoId){
            
            this.carrinho            = response.cart_pagamento;
            this.formasPagamento     = response.cart_pagamento.formas_pagamento;
            this.dadosComprador      = response.cart_pagamento.cart_dados;
            this.pagamentoSuccessId  = response.pagamentoSuccessId;
            this.isPagamento         = true;
            this.step                = 3;

          }else{
  
            this._cart.emitirUpdateTotalCart.emit({
              total: 0,
              qtd: 0
            });
            this._router.navigateByUrl("/");
          } 
        
        }
    
      },(err)=>{
        this.loader = false;
      });
    
    }catch(e){
      this.loader = false;
    }
    
  }
  /**
   * 
   * On Success Dados Comprador
   * 
   */
  onSuccessDadosComprador(){
    this.step = 2;
  }
  /**
   * 
   * @param response 
   * 
   * 
   */
  updateResponse(data){

    this.responsePagamento = data;

    if(this.responsePagamento.dataFile.status == 1){
      this.fileSession = this.responsePagamento.dataFile.data;
    }

  }
  /**
   * 
   * On Success Pagamento
   * 
   */
  onSuccessPagamento(response){
    
    this.carrinho.carrinho.file = response.data.file.data;
    this.carrinho.pagamento = {
      info: response.data.pagamento_info
    } 
    this._cart.emitirUpdateTotalCart.emit({
      total: 0,
      qtd: 0
    });
    this.step = 3;

  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    this.getCart();
  }

}
