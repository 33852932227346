<div class="unidade">
  <div class="titulo">
    <h4>{{unidade.nome}}</h4>
  </div> 
  <div class="content"> 
    <div class="endereco">
      <ul>
        <li>
          <figure>
            <img src="/images/icons/alltour/mapa/location.svg" alt="Localização - Alltour" />
          </figure>   
        </li>
        <li>
          <h5>
            <span class="rua">
              {{unidade.endereco}} 
            </span>
            <span class="numero" *ngIf="unidade.numero != null">
              - {{unidade.numero}} -
            </span>
            <span class="numero" *ngIf="unidade.numero == null">
              - s / nº -
            </span>
            <span class="complemento" *ngIf="unidade.complemento != null">
              {{unidade.complemento}}
            </span>
          </h5>
          <h5>
            <span class="bairro">
              {{unidade.bairro}} -
            </span> 
            <span class="cidade">
              {{unidade.cidade}}
            </span>
            <span> / </span>
            <span class="estado">
              {{unidade.estado}}
            </span>  
          </h5>
        </li>
      </ul>  
    </div> 
    <div class="contato"> 
      <div class="row">
        <div class="col-lg-4 col-md-6" *ngIf="unidade.inserir_celular">
          <div class="item">
            <h5>CELULAR</h5>
            <h6>{{unidade.celular}}</h6>
          </div>  
        </div>
        <div class="col-lg-4 col-md-6" *ngIf="unidade.inserir_telefone">
          <div class="item">
            <h5>TELEFONE</h5>
            <h6>{{unidade.telefone}}</h6>
          </div>  
        </div> 
        <div class="col-lg-4 col-md-6" *ngIf="unidade.inserir_email && !unidade.inserir_celular">
          <div class="item">
            <h5>E-MAIL</h5>
            <h6>{{unidade.email}}</h6>
          </div>
        </div> 
      </div>
      <div class="row" *ngIf="unidade.inserir_email && unidade.inserir_celular">
        <div class="col-md-12">
          <div class="item">
            <h5>E-MAIL</h5>
            <h6>{{unidade.email}}</h6>
          </div>
        </div>  
      </div>
      <div class="row" *ngIf="unidade.inserir_horario_funcionamento">
        <div class="col-md-12">
          <div class="item">
            <h5>HORÁRIO DE FUNCIONAMENTO</h5>
            <h6>{{unidade.horario_funcionamento}}</h6>
          </div>
        </div>  
      </div>  
    </div>  
  </div>  
</div>
