<div class="total-carrinho" (click)="toCart()">
  <div class="total-carrinho-content">
    <ul>
      <li>
        <div class="sacola">
          <figure>
            <img src="/images/icons/alltour/carrinho/mochila.svg" alt="Mochila de Compras" />
          </figure>  
        </div>  
      </li>
      <li> 
        <div class="total">
          <h5 *ngIf="valor1 > 0">
            <span class="cifrao">R$</span>
            <span class="valor-01">{{valor1}}</span>
            <span class="valor-02">{{valor2}}</span>
          </h5>
          <h5 *ngIf="valor1 == 0">
            <span class="valor-01">R$ 0,00</span>
          </h5>
        </div>  
        <div class="text">
          <h4>MINHAS COMPRAS</h4>
        </div>
      </li>
    </ul>     
  </div> 
</div>
