<div class="modulo modulo-servicos-oferecidos">
  <div class="modulo-title" *ngIf="data.mostrar_titulo">
    <div class="container">
      <h2>{{data.titulo}}</h2>
    </div>  
  </div>
  <div class="modulo-content">
    <div class="container">
      <article class="descricao" *ngIf="data.params.inserir_descricao" [innerHtml]="data.params.descricao | safeHtml"></article>
      <div class="lista-de-servicos">
        <div class="row">
          <div class="col-md-3" *ngFor="let s of servicos">
            <div class="item">
              <div class="icon">
                <i class="material-icons">
                  {{s.icon}}
                </i>
              </div>  
              <h3>{{s.titulo}}</h3>
              <article [innerHtml]="s.texto | safeHtml"></article>
            </div>  
          </div>
        <div>  
      </div>  
    </div>  
  </div>
</div>
  