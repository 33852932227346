import { Component, OnInit, Input, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

declare var $:any;

@Component({
  selector: 'modulo-banner-slider',
  templateUrl: './banner-slider.component.html',
  styleUrls: ['./banner-slider.component.scss']
})
export class BannerSliderComponent implements OnInit {

  @Input("data")  data  = null;
  public banners       = [];
  public bannerHtml    = "";
  public hidden        = true;
  public firstImg      = null;
  public show          = false; 
  public createdBanner = false;  
  public indexImg      = 0;
  public tela          = {
    largura: null,
    altura: null
  }
  public altura         = null;
  public timeout        = 7000;
  public fnTimeout      = null; 
  public timeNavigation = 0;
  public timeoutList    = [];

  constructor(
    private modal: MatDialog 
  ){
    this.getScreenSize();
  }
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    
    this.tela.altura  = window.innerHeight;
    this.tela.largura = window.innerWidth;
    
    if(this.tela.largura > this.tela.altura){
      let menos:any = (this.tela.altura*15)/100; 
      this.altura   = this.tela.altura-menos;
      this.altura   = this.altura+"px";
    }else if(this.tela.largura <= 480){
      this.altura = "200px";
    }else{
      this.altura = "300px";
    }
  
  }
  /**
   * 
   * Prev
   * 
   */
  prev(isNavigation=false){

    if((this.indexImg-1) < 0){
      this.indexImg = this.data.banners.length-1;
    }else{
      this.indexImg--;
    }
    if(isNavigation){  
      //clearTimeout(this.fnTimeout);
      //this.timeNavigation = 0;
      //this.countTimeNavigation();
      this.clearAllTimeouts();
      this.slider();
    }  

  }
  /**
   * 
   * Next
   * 
   */
  next(isNavigation=false){

    if((this.indexImg+1) == this.data.banners.length){
      this.indexImg = 0;
    }else{
      this.indexImg += 1;
    }
    if(this.fnTimeout != null){
     // clearTimeout(this.fnTimeout);
    }
    if(isNavigation){  
      this.clearAllTimeouts();
      this.slider();
      //clearTimeout(this.fnTimeout);
      //this.timeNavigation = 0;
      //this.countTimeNavigation();
    }  

  }
  /**
   * 
   * Slider
   * 
   */
  slider(){

    this.fnTimeout = setTimeout(() => {
      this.next();
      this.slider();
    },this.timeout);
    this.timeoutList.push(this.fnTimeout);
    
  }
  countTimeNavigation(time=1000){

    if(this.timeout == time){
      this.timeNavigation = 0;
      this.slider();
    }
    setTimeout(() => {
      this.countTimeNavigation(time+1000);
    },1000);

  }
  /**
   * 
   * Exclui todos os timeouts
   * 
   */
  clearAllTimeouts(){

    for(var i=0; i<this.timeoutList.length; i++) {
      clearTimeout(this.timeoutList[i]);
    }

  }
  changeIndexImg(index){

    this.indexImg = index;

    this.clearAllTimeouts();
    this.slider();

  }
  /**
   * 
   * Retorna o link do produto
   * 
   */
  getLinkProduto(banner){

    return "/servicos/"+banner.regiao_apelido+"/"+banner.categoria_apelido+"/"+banner.produto_apelido;

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.slider();
  }


}
