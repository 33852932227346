<div class="produto-item-banner">
  <div class="content" *ngFor="let foto of fotos;let i=index;">
    <div class="sombra" [hidden]="indexShow != i"></div>
    <div class="navigate" [hidden]="indexShow != i">
      <div class="prev" (click)="prev(i)">
        <i class="material-icons">
          chevron_left
        </i>
        <div class="bg"></div>
      </div>
      <div class="next" (click)="next(i)">
        <i class="material-icons">
          chevron_right
        </i>
        <div class="bg"></div>
      </div>
    </div>  
    <div class="item" [hidden]="indexShow != i" [ngClass]="{'fade-in-fwd':loader}">
      <figure>
        <img [src]="foto.imagem" [alt]="foto.alt" class="img-fluid" />
      </figure>  
    </div>
  </div>  
</div>
