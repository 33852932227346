import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'dm-cart-pagamento-tipo',
  templateUrl: './dm-cart-pagamento-tipo.component.html',
  styleUrls: ['./dm-cart-pagamento-tipo.component.scss']
})
export class DmCartPagamentoTipoComponent implements OnInit {

  @Input("sendPagamento") sendPagamento     = new EventEmitter();
  public _sendPagamento   = new EventEmitter();
  @Output("onSuccess") _onSuccess           = new EventEmitter();
  @Input("carrinho") carrinho               = null;
  @Input("formaPagamento") formaPagamento   = null;
  @Input("apelido") apelido                 = null;
  @Input("tipo") tipo                       = null;
  @Input("index") index                     = null;
  @Input("indexSelected") indexSelected     = null;
  @Output("updateResponse") _updateResponse = new EventEmitter();

  constructor(){}

  /**
   * 
   * Seta os dados
   * 
   */
  setData(){

    if(this.formaPagamento != null){

      this.apelido = this.formaPagamento.intermediador_apelido;
      this.tipo    = this.formaPagamento.tipo_id;

      if(typeof(this.formaPagamento.params) == "string"){
        this.formaPagamento.params = JSON.parse(this.formaPagamento.params);
      }

    }

  }
  /**
   * 
   * Ouvintes
   * 
   */
  onData(){

    this.sendPagamento.subscribe(response => {

      if(this.index == this.indexSelected){
        this._sendPagamento.emit();
      }

    }); 

  }
  /**
   * 
   * On Success
   * 
   * @param response 
   * 
   */
  onSuccess(response){

    this._onSuccess.emit(response);

  }
  /**
   * 
   * On Success
   * 
   * @param response 
   * 
   */
  updateResponse(response){

    this._updateResponse.emit(response);

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.setData();
    this.onData();
  }

}
