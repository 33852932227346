<div class="modulo modulo-horarios">
  <div class="modulo-title" *ngIf="data.mostrar_titulo">
    <h3>{{data.titulo}}</h3>
  </div>
  <div class="modulo-content"> 
    <div class="row">
      <div class="col-md-6">
        <div class="item">
          <div class="titulo">
            <h4>{{data.params.horario_titulo}}</h4>
          </div>
          <div class="content">  
            <article [innerHtml]="data.params.horario_texto"></article>
          </div>  
        </div>  
      </div>
      <div class="col-md-6">
        <div class="item">
          <div class="titulo">
            <h4>{{data.params.emergencia_titulo}}</h4>
          </div>
          <div class="content">   
            <article [innerHtml]="data.params.emergencia_texto"></article>
          </div>  
        </div> 
      </div>  
    </div>  
  </div>  
</div>
