<div class="dm-cart-passageiros-lista-informacao" *ngIf="dataItem != null">
  <form class="dm-form" autocomplete="off" autocapitalize="off">
    <div class="item" *ngFor="let p of passageiros;let index=index;">
      <div class="item-header">
        <h3>Passageiro {{index+1}}: {{p.tipo | tipoPassageiroDesc}}</h3>
        <div class="info">
          <span *ngIf="p.tipo == 'adt'">
            {{configuracaoLoja.passageiro_label_adt}}
          </span>
          <span *ngIf="p.tipo == 'snr'">
            {{configuracaoLoja.passageiro_label_snr}}
          </span>
          <span *ngIf="p.tipo == 'chd'">
            {{configuracaoLoja.passageiro_label_chd}}
          </span>
          <span *ngIf="p.tipo == 'inf'">
            {{configuracaoLoja.passageiro_label_chd_free}}
          </span>
        </div>  
      </div>  
      <div class="item-content">
        <div class="content-check-box-passageiro" *ngIf="listPaxs[p.tipo].length > 0">
          <div class="dm-check-box">
            <span class="dm-check-box-input" [ngClass]="{'active':!p.isSelect}" (click)="changeIsSelect(p,false)"></span>
            <span class="dm-check-box-value">Incluir um Novo</span>
          </div>
          <div class="dm-check-box">
            <span class="dm-check-box-input" [ngClass]="{'active':p.isSelect}" (click)="changeIsSelect(p,true)"></span>
            <span class="dm-check-box-value">Selecionar um Passageiro Existente</span>
          </div>
        </div>
        <div class="content-inputs" *ngIf="!p.isSelect">
          <ul>
            <li>
              <div class="dm-form-item">
                <input type="text" [(ngModel)]="p.nome" placeholder="Nome*" name="nome-{{index}}" (change)="_changeValue()" uppercase />
              </div>  
            </li>  
            <li>
              <div class="dm-form-item">
                <input type="text" [(ngModel)]="p.sobrenome" placeholder="Sobrenome*" name="sobrenome-{{index}}" (change)="_changeValue()" uppercase />
              </div>  
            </li>  
          </ul>
          <ul>
            <li>
              <div class="dm-form-item">
                <input type="text" [(ngModel)]="p.telefone" placeholder="Celular" mask="(99) 99999-9999" name="celular-{{index}}" (change)="_changeValue()" />
              </div>  
            </li>  
            <li>
              <div class="dm-form-item">
                <input type="text" [(ngModel)]="p.documento" placeholder="RG ou Certidão de Nascimento" name="documento-{{index}}" (change)="_changeValue()" />
              </div>  
            </li>  
          </ul>
        </div>
        <div class="content-select" *ngIf="p.isSelect">
          <div class="mat-select-container">
            <label>Selecione um Passageiro:</label>
            <mat-select [(ngModel)]="p.id" name="p.id">
              <mat-option [value]="null">
                -----
              </mat-option>
              <mat-option *ngFor="let passageiro of listPaxs[p.tipo];let y = index" [value]="passageiro.id">
                {{passageiro.nome}} {{passageiro.sobrenome}}
              </mat-option>
            </mat-select>
          </div>
        </div>  
      </div>  
    </div>  
  </form>  
</div>
<div class="alert alert-info alert-inline" [hidden]="!loader">
  <span>Buscando lista de passageiros. Aguarde!</span> 
</div>
