<div class="dm-cat-pagamento-confirmacao-email">
  <h3>Enviar confirmação por email:</h3>
  <form class="form-custom" (submit)="enviarConfirmacaoEmail()">
    <div class="row">
      <div class="col-md-8 col-7">
        <input type="text" [(ngModel)]="email" name="email" />
      </div>
      <div class="col-md-4 col-5">  
        <button type="submit" class="btn-custom-three">
          <span *ngIf="!loader">Enviar</span>
          <span *ngIf="loader">Enviando...</span>
        </button>  
      </div>
    </div>    
  </form> 
  <div class="subscribe-message" *ngIf="subscribeMessage != null" [innerHtml]="subscribeMessage"></div> 
</div>

