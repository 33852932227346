<div class="modulo modulo-form-offline">
  <div class="modulo-title">
    <h3>SOLICITE A RESERVA PARA ESSE PASSEIO</h3>
    <h4>nos envie seu contato</h4>
  </div>  
  <div class="modulo-content">
    <div class="content-form">
      <form class="form" autocomplete="off" (submit)="send()"> 
        <div class="form-group">
          <input type="text" [(ngModel)]="dataItem.nome" placeholder="Informe seu nome*" name="nome" uppercase />
        </div> 
        <div class="form-group">
          <input type="text" [(ngModel)]="dataItem.email" placeholder="Informe seu e-mail*" name="email" />
        </div> 
        <div class="form-group">
          <input type="text" [(ngModel)]="dataItem.telefone" placeholder="Informe seu telefone*" name="telefone" mask="(99) 99999-9999" />
        </div>
        <div class="quantidade-de-passageiros">  
          <div class="header">
            <h3>INFORME A QUANTIDADE DE PASSAGEIROS</h3>
          </div> 
          <div class="content"> 
            <div class="item">
              <label>Adultos</label>
              <span class="info">
                {{configuracaoLoja.passageiro_label_adt}}
              </span>
              <div class="content-input">
                <input type="text" [(ngModel)]="dataItem.qtd_adultos" name="qtd_adultos" integer readonly />
              </div>  
              <div class="content-buttons">
                <button type="button" class="menos" (click)="menos('adt')">
                  <span>-</span>
                </button> 
                <button type="button" class="mais" (click)="mais('adt')">
                  <span>+</span>
                </button>  
              </div>
            </div>
            <div class="item">
              <label>Melhor Idade</label>
              <span class="info">
                {{configuracaoLoja.passageiro_label_snr}}
              </span>
              <div class="content-input">
                <input type="text" [(ngModel)]="dataItem.qtd_melhor_idade" name="qtd_melhor_idade" integer readonly />
              </div>  
              <div class="content-buttons">
                <button type="button" class="menos" (click)="menos('snr')">
                  <span>-</span>
                </button> 
                <button type="button" class="mais" (click)="mais('snr')">
                  <span>+</span>
                </button>  
              </div>
            </div>
            <div class="item">
              <label>Crianças Pagantes</label>
              <span class="info">
                {{configuracaoLoja.passageiro_label_chd}}
              </span>
              <div class="content-input">
                <input type="text" [(ngModel)]="dataItem.qtd_criancas_pagantes" name="qtd_criancas_pagantes" integer readonly />
              </div>  
              <div class="content-buttons">
                <button type="button" class="menos" (click)="menos('chd')">
                  <span>-</span>
                </button> 
                <button type="button" class="mais" (click)="mais('chd')">
                  <span>+</span>
                </button>  
              </div>
            </div>
            <div class="item">
              <label>Crianças Cortesia</label>
              <span class="info">
                {{configuracaoLoja.passageiro_label_chd_free}}
              </span>
              <div class="content-input">
                <input type="text" [(ngModel)]="dataItem.qtd_criancas_cortesia" name="qtd_criancas_cortesia" integer readonly />
              </div>  
              <div class="content-buttons">
                <button type="button" class="menos" (click)="menos('inf')">
                  <span>-</span>
                </button> 
                <button type="button" class="mais" (click)="mais('inf')">
                  <span>+</span> 
                </button>  
              </div>
            </div>  
          </div>  
        </div> 
        <div class="content-mensagem">
          <div class="form-group">
            <textarea [(ngModel)]="dataItem.mensagem" placeholder="Escreva uma mensagem..." name="mensagem" rows="5"></textarea>
          </div>  
        </div>  
        <div class="form-group">
          <div class="form-footer">
            <button type="submit" class="button-send">
              ENVIAR SOLICITAÇÃO
            </button>  
          </div>  
        </div>  
      </form>  
    </div>
  </div>  
</div>
<modal-info [show]="message.show" [customClass]="message.class" [message]="message.data" (hide)="message.show=false"></modal-info>
<modulo-loader [message]="messageLoader" [hidden]="!loader"></modulo-loader>
