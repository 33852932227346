import { CartService } from "../../services/cart.service";
import { Component, OnInit } from '@angular/core';
import { AppService } from "../../services/app.service";
import { ApiService } from "../../services/api.service";

@Component({
  selector: 'cart-page',
  templateUrl: './cart-page.component.html',
  styleUrls: ['./cart-page.component.scss']
})
export class CartPage implements OnInit {

  public title         = "MINHAS COMPRAS"
  public loader        = false
  public total         = 0
  public messageLoader = "Buscando seu carrinho. Aguarde !"
  public response:any = {
    data: "",
    status: 0
  }
  public servicos = [];
  public file     = null;
  public usuario  = null;
  public hasItens = false;

  constructor(
    private CartService: CartService,
    private app: AppService,
    private api: ApiService
  ) { }

  updateCartEmit(id){

    this.deleteItem(id)

  }
  /**
   * 
   * Deleta um Item no Carrinho
   * 
   * 
   */
  deleteItem(id){

    for(var index = 0;index < this.servicos.length;index++) {

      if(id == this.servicos[index].id){
        this.servicos.splice(index,1)
      }
        
    }
    this.calcTotal()
  
  }
  /**
   * 
   * Busca os dados do Carrinho
   * 
   */
  getCart(){

    try{

      this.loader = true

      this.api.cart().get().subscribe(response =>{
        
        this.loader   = false

        if(response.status == 1){
          
          this.response = response

          if(this.response.data == null){
            this.CartService.emitirUpdateTotalCart.emit({
              total: 0,
              qtd: 0
            });
          }else{
            this.servicos = response.data.cart_itens;
            this.total    = response.data.total;
            this.CartService.emitirUpdateTotalCart.emit({
              total: this.total,
              qtd: this.servicos.length
            });    
          }
          
          this.file = response.fileSession;  

        }else{
          this.response = {
            data: response.data,
            status: 500
          }
        }
        
      
      },(err) =>{
        
        this.loader = false
        this.response = {
          data: err._body,
          status: 500
        }

      })

    }catch(e){

      this.loader = false
      this.response = {
        data: "Houve um erro Inesperado: "+e.message,
        status: 500
      }

    }

  }
  /**
   * 
   * Calcula o Total do Carrinho
   * 
   */
  calcTotal(){

    this.total = 0

    this.servicos.forEach(s => {

      this.total += ((s.qtd_adt*s.valor_adt)+(s.qtd_chd*s.valor_chd))+parseFloat(s.valor_servico)

    });
    this.CartService.emitirUpdateTotalCart.emit({
      total: this.total,
      qtd: this.servicos.length
    });

  }
  /**
   * 
   * Esvazia o Carrinho
   * 
   */
  esvaziar(){
    
    this.total         =  0;
    this.response.data = null; 

    this.CartService.emitirUpdateTotalCart.emit({
      total: 0,
      qtd: 0
    });
    this.app.toTop(); 
    
  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit() {

    this.CartService.emitirDeleteItem.subscribe(id=>{
      this.updateCartEmit(id);
    });

    this.getCart()
  }

}
