import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiComponentesSettings } from '../class/api-componentes-settings';

@Injectable()
export class EventoService {

  constructor(private http: HttpClient) { }

  /**
   * 
   * Retorna o projeto com base no apelido
   * 
   * @param apelido 
   * 
   */
  getProjetoByApelido(apelido):any{

    let url = ApiComponentesSettings.API_EVENTO.get_evento_by_apelido+"/"+apelido;
    
    return this.http.get(url).map(response => response);

  }
  /**
   * 
   * Retorna os evntos
   * 
   * 
   */
  getEventos(params):any{

    let url = ApiComponentesSettings.API_EVENTO.get_eventos;
    
    return this.http.get(url,{params}).map(response => response);

  }
  /**
   * 
   * Salva os eventos em session storage
   * 
   */
  setEventosLocal(eventos,total,params):any{

    let data = {
      eventos: eventos,
      total: total,
      params: params
    }

    sessionStorage.setItem("eventos",JSON.stringify(data));


  }
  /**
   * 
   * Retorna os eventos do local
   * 
   */
  getEventosLocaL():any{ 

    let data = sessionStorage.getItem("eventos");

    if(data == null){
      return null;
    }else{
      return JSON.parse(data);
    }

  }
  

}
