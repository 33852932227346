import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'evento-item',
  templateUrl: './evento-item.component.html',
  styleUrls: ['./evento-item.component.scss']
})
export class EventoItemComponent implements OnInit {

  @Input("evento") evento = null;

  constructor() { }


  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit() {
  }

}
