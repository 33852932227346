<div class="produto-form-search-two">
  <div class="titulo">
    <h3>Encontre seu passeio ideal</h3>
  </div>
  <div class="content">  
    <form class="form" autocomplete="off">
      <div class="form-group">
        <div class="content-input">
          <label>Nome do Serviço:</label> 
          <input type="text" [(ngModel)]="dataItem.nome" name="nome" class="form-control" (input)="_changeInput()" [formControl]="myControl" [matAutocomplete]="auto" />
          <figure [hidden]="dataItem.nome === ''" class="animated fadeIn">
            <img src="/images/icons/alltour/cancel.svg" class="cancel" (click)="dataItem.nome=''" />
          </figure>  
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
              {{option}}
            </mat-option>
          </mat-autocomplete>
        </div>  
      </div>
      <div class="form-group">
        <label>Destino:</label>
        <mat-select [(ngModel)]="dataItem.produto_regiao_id" name="produto_regiao_id" (change)="_changeRegiao()">
          <mat-option value="">
            ---
          </mat-option>  
          <mat-option [value]="value.id" *ngFor="let value of regioes">
            {{value.titulo}}
          </mat-option>
        </mat-select>
      </div>  
      <div class="form-group">
        <label>Categoria:</label>
        <mat-select [(ngModel)]="dataItem.produto_categoria_id" name="produto_categoria_id" (change)="_changeCategoria()">
          <mat-option value="">
            ---
          </mat-option>  
          <mat-option [value]="value.id" *ngFor="let value of categorias">
            {{value.titulo}}
          </mat-option>
        </mat-select>  
      </div> 
    </form>  
  </div>  
</div>
