<div class="page page-servico" itemscope itemtype="https://schema.org/Product">
  <div class="page-servico-banner wow fadeIn" data-wow-delay="0.5s" *ngIf="response.status == 1"> 
    <div class="foto-capa" *ngIf="produto.foto_capa_ativo">
      <figure> 
        <img itemppro="image" [src]="produto.foto_capa" [alt]="produto.nome" class="img-fluid" />
      </figure>  
    </div>  
  </div> 
  <div class="section-breadcrumbs" *ngIf="itensBreadcrumbs.length > 0"> 
    <div class="container">
      <breadcrumbs [itens]="itensBreadcrumbs"></breadcrumbs>
    </div>  
  </div>  
  <div class="container-servico">
    <div class="content-body">
      <div *ngIf="response.status == 1">
        <div class="page-title wow fadeIn" data-wow-delay="0.8s">
          <div class="container">
            <h1 itemprop="name">{{produto.nome}}</h1> 
            <div class="menor-tarifa">
              <produto-item-valor classCustom="in-servico-page" [valor]="produto.menor_tarifa"></produto-item-valor>
            </div>
          </div>  
        </div>
        <div class="page-content bg-grey">
          <section class="section section-carrinho">
            <div class="section-container">
              <div class="container">
                <div class="content">
                  <div class="row">
                    <div class="col-lg-7 col-md-7">
                      <produto-slide-show-two [fotos]="fotos"></produto-slide-show-two>
                    </div>  
                    <div class="col-lg-5 col-md-5">
                      <div class="content-venda-offline" *ngIf="produto.vender_offline">
                        <produto-venda-offline [configuracaoLoja]="configuracao_loja" [servico]="produto"></produto-venda-offline>
                      </div>  
                      <div class="content-carrinho box" *ngIf="!produto.vender_offline">
                        <div class="titulo">
                          <ul>
                            <li>
                              <img src="/images/icons/alltour/carrinho/mochila.svg" alt="Minhas Compras" width="40px" />
                            </li>
                            <li>
                              <h3>MINHAS COMPRAS</h3>
                              <h4>Adicione esse serviço às suas compras.</h4>
                            </li>  
                          </ul>
                          <span class="codigo-servico">Código: {{produto.id}}</span>
                        </div>  
                        <button type="button" class="btn-custom-three btn-add-carrinho" (click)="showAddCarrinho=true" *ngIf="!showAddCarrinho">
                          <span>Clique aqui para adicionar esse serviço ao carrinho.</span>
                        </button>
                        <div class="content-carrinho-add animated fadeIn" *ngIf="showAddCarrinho == true">  
                          <dm-cart-add [servico]="produto" [configuracaoLoja]="configuracao_loja" [macroRegiao]="macro_regiao"></dm-cart-add>
                        </div>  
                      </div> 
                    </div>  
                  </div>
                </div> 
              </div> 
            </div>  
          </section>
          <section class="section section-sobre bg-grey">
            <div class="section-content"> 
              <div class="container">
                <div class="box">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="section-title">
                        <h4>SOBRE O SERVIÇO</h4>
                      </div>  
                      <article itemprop="description" [innerHtml]="produto.descricao | safeHtml" class="descricao"></article>
                    </div> 
                    <div class="col-md-6">
                      <div class="section-title">
                        <ul>
                          <li>
                            <figure>
                              <img src="/images/icons/alltour/contato/message-contato.svg" alt="Contato" />
                            </figure>  
                          </li>
                          <li>  
                            <h4>Você está com alguma dúvida?</h4>
                            <h5>Entre em contato com nossa equipe!</h5>
                          </li>
                        </ul>    
                      </div> 
                      <produto-form-contato [servico]="produto.nome"></produto-form-contato>
                      <div class="row">
                        <div class="col-md-6">
                          <modulo-position position="contato" template="page-servico"></modulo-position>
                        </div>
                        <div class="col-md-6">
                          <modulo-position position="whatsapp" template="page-servico"></modulo-position>
                        </div>  
                      </div>  
                    </div> 
                  </div> 
                </div>
              </div>   
            </div> 
            <span style="display:none;" itemprop="sku">{{produto.id}}</span>   
          </section>  
          <section class="section section-veja-tambem" *ngIf="produtos_relacionados.length > 0">
            <div class="section-title">
              <div class="content">
                <h3>Veja também esses <strong>PASSEIOS</strong> imperdíveis.</h3>
                <div class="bordas">
                  <ul>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>  
                </div>
              </div>  
            </div> 
            <div class="section-content"> 
              <div class="container">
                <div class="row">
                  <div class="col-lg-4 col-md-6" *ngFor="let produto of produtos_relacionados;let i=index;">
                    <produto-item [data]="produto"></produto-item>
                  </div>  
                </div> 
              </div>   
            </div>  
          </section> 
        </div>  
      </div>  
      <div *ngIf="response.status == 500">
        <error-custom title="Não foi possível carregar os dados da Página" [details]="response.data" (reload)="getData()"></error-custom>
      </div>
    </div>
  </div>
</div>    
<modulo-loader [message]="messageLoader" *ngIf="loader"></modulo-loader> 
  