<div class="modulo modulo-produtos-categoria {{classCustom}}">
  <div class="modulo-title" *ngIf="data.mostrar_titulo">
    <div class="container">
      <div class="linha linha-left"></div>
      <h2>{{data.titulo}}</h2>
      <div class="linha linha-right"></div>
      <h3>{{data.params.subtitulo}}</h3>
    </div>  
  </div>
  <div class="modulo-content">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6" *ngFor="let p of produtos">
          <produto-template-one [produto]="p" [classCustom]="classCustom" [classBtn]="classBtn"></produto-template-one>
        </div>  
      </div>  
    </div>
  </div>  
</div>
