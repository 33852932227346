<section class="error-servidor">
  <div class="content">
    <figure>
      <img class="logo" src="/assets/logos/terra-da-luz/estrela.png" alt="Logo - {{name}}" />
    </figure>
    <h1>Desculpe, houve um erro. Tente mais tarde.</h1> 
    <hr>
    <div class="row">
      <div class="col-md-12">
        <button type="button" class="btn btn-success" (click)="reload()">
          Recarregar
        </button>  
      </div>  
    </div> 
  </div>  
  <div class="bg"></div>
</section>
