<div class="page page-servicos bg-grey">
  <div class="section-breadcrumbs" *ngIf="itensBreadcrumbs.length > 0"> 
    <div class="container">
      <breadcrumbs [itens]="itensBreadcrumbs"></breadcrumbs>
    </div>  
  </div> 
  <div *ngIf="response.status == 1">
    <div class="page-content"> 
      <div class="container"> 
        <div class="row">
          <div class="col-md-4">
            <div class="section section-form">
              <produto-form-search-two [produtos]="produtos" [categorias]="categorias" [regioes]="regioes" (change)="_change($event)" template="two"></produto-form-search-two>
            </div>
          </div>
          <div class="col-md-8">
            <div class="section-filtro">
              <mat-select [(ngModel)]="pageSize" name="pageSize">
                <mat-option value='3'>
                  3 por página
                </mat-option> 
                <mat-option value='6'>
                  6 por página
                </mat-option>
                <mat-option value='12'>
                  12 por página
                </mat-option>
              </mat-select>
            </div>  
            <div class="page-title" *ngIf="page != null"> 
              <div class="container">
                <h1>{{page.titulo}}</h1>
                <h2>Encontramos {{produtos.length}} serviço(s) para você</h2>
              </div> 
            </div>
            <section class="section produtos" id="produtos">
              <div class="lista-de-produtos" *ngIf="produtos.length > 0">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-6 col-md-6 animated fadeIn" *ngFor="let item of produtos | slice:((pageSize*pageNumber)-pageSize):(pageSize*pageNumber)">
                      <produto-item [data]="item"></produto-item>
                    </div>  
                  </div>  
                </div> 
              </div>
              <div class="empty-produtos" *ngIf="produtos.length == 0">
                <div class="container">
                  <p class="alert alert-info message-info">
                    Não temos serviços para mostrar.
                  </p>
                </div>  
              </div>  
            </section>
            <section class="section section-pagination"> 
              <pagination-custom (changePage)="changePage($event)" [pageNumber]="pageNumber" [pageSize]="pageSize" [total]="produtos.length"></pagination-custom>
            </section>  
          </div>
        </div> 
      </div>  
    </div> 
  </div>
  <div *ngIf="response.status == 500">
    <div class="container">
      <error-custom title="Não foi possível carregar os dados da Página" [details]="response.data" (reload)="getData()"></error-custom>
    </div>  
  </div>   
</div>  
<modulo-loader [message]="messageLoader" *ngIf="loader"></modulo-loader> 
