<div class="page page-servico-categoria">
  <div *ngIf="response.status == 1">
    <div class="page-title" *ngIf="page.mostrar_titulo">
      <div class="container">
        <h1>{{page.titulo}}</h1>
        <div class="linha"></div>
        <h2 *ngIf="page.mostrar_subtitulo">{{page.subtitulo}}</h2>
      </div>  
    </div>
    <div class="page-content">
      <div class="section section-form" *ngIf="produtosOriginal.length > 0">
        <div class="container">
          <div class="content">
            <produto-form-search [produtos]="produtosOriginal" [categorias]="categorias" (change)="_change($event)"></produto-form-search>  
          </div>  
        </div>  
      </div>
      <div class="section section-servicos" [hidden]="produtos.length == 0">
        <div class="container">
          <div class="row">
            <div class="col-md-4" *ngFor="let produto of produtos;let i=index;">
              <produto-item [data]="produto"></produto-item> 
            </div>  
          </div>  
        </div>
      </div> 
      <div class="section section-servicos" [hidden]="produtos.length > 0">
        <div class="container">
          <p class="alert alert-success">
            Não temos serviços para mostrar.
          </p>
        </div>  
      </div> 
    </div>  
  </div> 
  <div *ngIf="response.status == 500">
    <div class="container">
      <error-custom title="Não foi possível carregar os dados da Página" [details]="response.data" (reload)="getData()"></error-custom>
    </div>  
  </div>  
</div> 
<modulo-loader message="Carregando Conteúdo" [hidden]="!loader"></modulo-loader> 