import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dm-cart-item-two',
  templateUrl: './dm-cart-item-two.component.html',
  styleUrls: ['./dm-cart-item-two.component.scss']
})
export class DmCartItemTwoComponent implements OnInit {

  @Input("item") item = null;
  public imagem       = "";

  constructor() { }

  /**
   * 
   * Seta a Imagem
   * 
   */
  setImage(){

    if(this.item.imagem == null){
      this.imagem = "/images/produto/sem-foto/rrc-receptivo.jpg";
    }else{
      this.imagem = this.item.imagem;
    }

  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    this.setImage();
  }

}
