<div class="page page-pagamento bg-grey">
  <div class="page-content" *ngIf="response.status == 1">
    <div class="container">
      <div class="row">
        <div class="col-md-7">
          <div class="page-title">
            <h1>Finalizar a Compra</h1>
          </div>
          <div class="section section-steps"> 
            <dm-cart-pagamento-steps [step]="step"></dm-cart-pagamento-steps>
          </div> 
          <div class="section section-content-steps">
            <div [ngSwitch]="step">
              <div *ngSwitchCase="'1'" class="step-item">
                <dm-cart-passageiro-titular [passageiros]="carrinho.paxs"></dm-cart-passageiro-titular>
                <dm-cart-pagamento-dados-comprador [dataItem]="dadosComprador" [onSave]="onSaveDadosComprador" (onSuccess)="onSuccessDadosComprador($event)"></dm-cart-pagamento-dados-comprador>
              </div>
              <div *ngSwitchCase="'2'" class="step-item">
                <dm-cart-pagamento-formas-pagamento (updateResponse)="updateResponse($event)" (onSuccess)="onSuccessPagamento($event)" [sendPagamento]="onSendPagamento" [carrinho]="carrinho" [formasPagamento]="formasPagamento"></dm-cart-pagamento-formas-pagamento>
                <div class="message-reserva alert alert-success" *ngIf="fileSession != null && !isPagamento">
                  <div class="titulo">
                    <h3>Sua reserva foi gerada em nosso sistema.</h3>
                  </div>
                  <div class="content">
                    <p>
                      <span>Número da rederva gerada: </span><strong>{{fileSession}}</strong>. 
                      <span>Porém o pagamento ainda não foi realizado.</span>
                    </p>
                    <p>
                      <span>Caso estiver com problema no pagamento, entre em contato conosco:</span>
                    </p>
                    <div class="row">
                      <div class="col-md-7">
                        <modulo-position position="contato" template="page-pagamento"></modulo-position>
                      </div> 
                      <div class="col-md-5">
                        <modulo-position position="whatsapp" template="page-pagamento"></modulo-position>
                      </div>  
                    </div>  
                  </div>  
                </div> 
              </div>
              <div *ngSwitchCase="'3'" class="step-item">
                <dm-cart-pagamento-finalizado-sucesso [carrinho]="carrinho"></dm-cart-pagamento-finalizado-sucesso>
                <hr>
                <div class="section section-go-home">
                  <a routerLink="/" class="btn-custom-three" title="Voltar para o Início">
                    <i class="fa fa-home"></i>
                    <span>Quero voltar para o início</span>
                  </a>  
                </div> 
              </div>
            </div>
            <div class="step-footer" [hidden]="step == 3">
              <div [ngSwitch]="step" class="content">
                <div *ngSwitchCase="'1'">
                  <button type="button" class="btn-custom-three next" (click)="setStep(2)">
                    Próximo
                  </button>  
                </div>
                <div *ngSwitchCase="'2'">
                  <button *ngIf="fileSession == null" type="button" class="btn-custom-three prev" (click)="setStep(1)">
                    Voltar
                  </button>
                  <button type="button" class="btn-custom-three next" (click)="setStep(3)">
                    Finalizar Pagamento
                  </button> 
                </div>
              </div>  
            </div>  
          </div> 
        </div>  
        <div class="col-md-5">
          <dm-cart-pagamento-resumo [carrinho]="carrinho"></dm-cart-pagamento-resumo>
        </div>  
      </div> 
    </div>   
  </div>
  <div class="content-error" *ngIf="response.status == 500">
    <div class="container">
      <error-custom (reload)="getCart()"></error-custom>
    </div>  
  </div>  
</div>
<modulo-loader [message]="message" *ngIf="loader"></modulo-loader>
