<div class="modulo modulo-banner-slider">
  <div class="modulo-content">
    <div class="content-banner">
      <div class="container-banner">
        <div *ngFor="let i of data.banners;let y = index;">
          <div *ngIf="i.link_ativo">
            <div class="item" itemscope itemtype="http://schema.org/ImageObject">
              <div [ngSwitch]="i.link_tipo">
                <a [routerLink]="getLinkProduto(i)" *ngSwitchCase="'2'">
                  <figure class="animated slide-left" [hidden]="y != indexImg"> 
                    <img [src]="i.imagem" alt="i.alt" class="img-fluid" itemprop="contentUrl" />
                    <span style="display:none;" itemprop="description">{{i.alt}}</span>
                  </figure>
                </a> 
                <a [href]="i.link" [target]="i.link_target" *ngSwitchCase="'1'">
                  <figure class="animated slide-left" [hidden]="y != indexImg"> 
                    <img [src]="i.imagem" alt="i.alt" class="img-fluid" itemprop="contentUrl" />
                    <span style="display:none;" itemprop="description">{{i.alt}}</span>
                  </figure>
                </a>  
              </div> 
            </div>
          </div> 
          <div *ngIf="!i.link_ativo">
            <div class="item" itemscope itemtype="http://schema.org/ImageObject">
              <figure class="animated slide-left" [hidden]="y != indexImg"> 
                <img [src]="i.imagem" alt="i.alt" class="img-fluid" itemprop="contentUrl" />
                <span style="display:none;" itemprop="description">{{i.alt}}</span>
              </figure>  
            </div>
          </div> 
          <!--<div class="legenda" *ngIf="i.mostrar_legenda" [hidden]="y != indexImg">
            <span class="wow fadeIn" data-wow-delay="0.5s">{{i.legenda}}</span>
            <div class="saiba-mais relative" *ngIf="i.link_ativo && i.mostrar_legenda">
              <div [ngSwitch]="i.link_tipo">
                <a [routerLink]="getLinkProduto(i)" *ngSwitchCase="'2'">
                  <strong>SAIBA MAIS</strong>
                  <div class="overlay"></div> 
                </a>
                <a [href]="i.link" [target]="i.link_target" *ngSwitchCase="'1'">
                  <strong>SAIBA MAIS</strong>
                  <div class="overlay"></div> 
                </a>
              </div>
            </div>
          </div>-->
          <!--<div class="saiba-mais absolute" *ngIf="i.link_ativo && !i.mostrar_legenda" [hidden]="y != indexImg">
            <div [ngSwitch]="i.link_tipo">
              <a [routerLink]="getLinkProduto(i)" *ngSwitchCase="'2'">
                SAIBA MAIS
              </a>
              <a [href]="i.link" [target]="i.link_target" *ngSwitchCase="'1'">
                SAIBA MAIS
              </a>  
            </div>
          </div>-->  
        </div> 
        <div class="navigator-footer" *ngIf="data.banners.length > 1">
          <ul>
            <li *ngFor="let i of data.banners;let y = index;" [ngClass]="{'active': y == indexImg}">
              <span (click)="changeIndexImg(y)"></span>
            </li>
          </ul>  
        </div> 
      </div> 
    </div>
  </div>  
</div>   
 