<div class="page page-contato">
  <div *ngIf="response.status == 1">
    <div class="page-title" *ngIf="page.mostrar_titulo">
      <div class="container">
        <h1>{{page.titulo}}</h1>
        <h2 *ngIf="page.mostrar_subtitulo">
          {{page.subtitulo}}
        </h2>
      </div> 
      <div class="linha"></div> 
    </div> 
    <div class="page-content">
      <div class="container">
        <section class="section section-form">
          <form class="form" (submit)="send()" autocomplete="off">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <input type="text" [(ngModel)]="dataItem.nome" placeholder="Informe seu nome*" name="nome" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <input type="text" [(ngModel)]="dataItem.email" placeholder="Informe seu e-mail*" name="email" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group telefone-input">
                      <div class="telefone-ddd">
                        <input type="text" [(ngModel)]="dataItem.telefone_ddd" placeholder="DDD" name="telefone_ddd" mask="99" (input)="onInputDDD($event)" />
                      </div>
                      <div class="telefone-numero">
                        <input type="text" [(ngModel)]="dataItem.telefone_numero" placeholder="Telefone" name="telefone_numero" mask="99999-9999" #telefoneNumero />
                      </div>  
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <input type="text" [(ngModel)]="dataItem.assunto" placeholder="Informe o assunto*" name="assunto" />
                    </div>
                  </div>
                </div>    
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <textarea type="text" [(ngModel)]="dataItem.mensagem" placeholder="Escreva sua mensagem..." name="mensagem"></textarea>
                </div> 
              </div>  
            </div>
            <div class="linha"></div>  
            <div class="form-group">
              <button type="submit" [disabled]="loader">
                ENVIAR
              </button>  
            </div>  
          </form>  
        </section>  
      </div>
      <section class="section section-unidades" *ngIf="unidades.length > 0"> 
        <div class="section-title">
          <div class="container">
            <h3>Conheça nossas unidades</h3>
          </div>  
        </div> 
        <div class="section-content">
          <div class="container">
            <div class="row">
              <div class="col-md-6" *ngFor="let unidade of unidades;let i=index;">
                <unidade-item [unidade]="unidade"></unidade-item> 
              </div>  
            </div>  
          </div> 
        </div>  
      </section> 
      <section class="section section-horarios">
        <div class="section-content">
          <div class="container">
            <modulo-position position="horarios"></modulo-position> 
          </div>  
        </div>  
      </section>  
    </div> 
  </div> 
  <div *ngIf="response.status == 500">
    <div class="container">
      <error-custom title="Não foi possível carregar os dados da Página" [details]="response.data" (reload)="getData()"></error-custom>
    </div>  
  </div>  
</div>
<modal-info [show]="message.show" [customClass]="message.class" [message]="message.data" (hide)="message.show=false"></modal-info>
<modulo-loader [message]="messageLoader" [hidden]="!loader"></modulo-loader>
      