<div class="modulo modulo-rota">
  <div class="modulo-title" *ngIf="data.mostrar_titulo">
    <div class="linha linha-left"></div>
    <h2>{{data.titulo}}</h2>
    <div class="linha linha-right"></div>
    <h3>{{data.params.rota.subtitulo}}</h3>
  </div>  
  <div class="modulo-content">
    <div class="row">
      <div class="col-md-6 col-sm-6">
        <div class="programe-se">
          <h4>PROGRAME-SE</h4>
          <h4>COM SEU PASSEIO!!!</h4>
        </div>  
      </div>
      <div class="col-md-6 col-sm-6">
        <div class="ver-o-mapa">
          <article class="text">
            <a routerLink="rota-bustour" title="Ver o Mapa"><strong class="clique">Clique </strong></a> <span>para ver o mapa de rotas do Bustour</span>
          </article>  
          <div class="bg"></div>
        </div>  
      </div>  
    </div> 
    <figure class="img-rota">
      <img src="/images/modulos/rotas/rota.png" alt="Rotas Bustour - Rota" />
    </figure>  
  </div>  
</div>
