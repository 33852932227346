<div class="total-cart">
  <a routerLink="/carrinho" title="Ir para o Carrinho de Compras">
  <ul>
    <li>
      <span class="cart">
         <i class="material-icons">&#xE8CC;</i>
      </span>  
    </li>
    <li>
      <span class="valor">
        {{total | currency:'BRL':true}}
      </span>  
    </li>  
    <!--<li>
      <span class="qtd">
         <span class="number">
         {{qtd}}
         </span>
      </span> 
    </li>-->  
  </ul> 
  </a>
</div>
