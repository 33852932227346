<div class="modulo modulo-footer">
  <div class="modulo-titulo">
    <h3>Links</h3>
  </div>
  <div class="modulo-content" itemscope itemtype="https://schema.org/Menu">  
    <ul>
      <!--<li itemprop="hasMenuItem" itemscope itemtype="https://schema.org/MenuItem">
        <a itemprop="url" routerLink="politicas-de-cancelamento"> 
          <span itemprop="name">POLÍTICAS DE CANCELAMENTO</span>
        </a>
      </li>
      <li itemprop="hasMenuItem" itemscope itemtype="https://schema.org/MenuItem">
        <a routerLink="politicas-de-privacidade">
          <span itemprop="name">POLÍTICAS DE PRIVACIDADE</span>
        </a>
      </li>-->
      <li>
        <a routerLink="contato" title="Contato">
          <span itemprop="name">CONTATO</span>
        </a>  
      </li>
    </ul>  
  </div>  
</div>
