import { Router } from '@angular/router';
import { CartService } from './../../../services/cart.service';
import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { ApiService } from '../../../services/api.service';

declare var $:any;

@Component({
  selector: 'btn-abandonar-compra',
  templateUrl: './btn-abandonar-compra.component.html',
  styleUrls: ['./btn-abandonar-compra.component.scss']
})
export class BtnAbandonarCompraComponent implements OnInit {

  @ViewChild("modal") modal:ElementRef;
  public loader = false;
  @Input("classCustom") classCustom = "btn-one";

  constructor(
    private cart: CartService,
    private router: Router,
    private api: ApiService
  ) { } 
  
  /**
   * 
   * Abandonar a Compra
   * 
   */
  abandonar(){

    try{

      this.loader = true;

      this.api.cart().abandonar_compra().subscribe(response => {

        this.loader = false;
        this.closeModal();

        if(response.status == 1){
          this.router.navigateByUrl("/");
        }

      }, (response) => {

        this.loader = false;

      });

    }catch(e){

      this.loader = false;

    }

  }
  /**
   * 
   * Abre o modal
   * 
   */
  openModal(){

    $(this.modal.nativeElement).modal("show");

  }
  /**
   * 
   * Abre o modal
   * 
   */
  closeModal(){
    
    $(this.modal.nativeElement).modal("hide");
    
  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    
  }

}
