import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { ServicoService } from '../../../services/servico.service';
import { AppService } from '../../../services/app.service';
import { CartService } from '../../../services/cart.service';
import { StorageService } from '../../../services/storage.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'dm-cart-add',
  templateUrl: './dm-cart-add.component.html',
  styleUrls: ['./dm-cart-add.component.scss']
})
export class DmCartAdd implements OnInit,OnChanges {
  
  @Input("servico") servico                   = null; 
  @Input("macroRegiao") macroRegiao           = [];  
  @Input("configuracaoLoja") configuracaoLoja = null; 
  public ultimoServicoInserido = null;  
  public step                                 = 1;
  public loader                               = false;
  public loaderTipoServico                    = false;
  public dataTipos                            = {
    data: null,
    countTipo: null,
    contemRegular: false,
    contemPrivativo: false,
    status: null,
    success: false
  };
  public messageLoader = "";
  public dataItem:any  = {}
  public message       = {
    data: "",
    show: false,
    class: ""
  }
  public disponibilidadeArray = null;
  public messageAdd = {
    show: false 
  }
  public pedirDadosVoo = false;
  public subscribeMessage = null;

  constructor(
    private api: ApiService,
    private _servico: ServicoService,
    private app: AppService,
    private _cart: CartService,
    private storage: StorageService,
    private recaptchaV3Service: ReCaptchaV3Service
  ){
    this.ultimoServicoInserido = this.storage.getUltimolServico();
  }

  /**
   * 
   * Set step
   * 
   */
  setStep(step){
    this.step = step;
  }
  /**
   * 
   * Next Step
   * 
   */
  next(){

    let validation        = {data: "",status:true};
    this.subscribeMessage = null;

    if(this.step == 1){
      validation = this._servico.validStep1(this.dataItem);
    }
    if(this.step == 2){
      validation = this._servico.validStep2(this.dataItem);
    }
    if(this.step == 3){
      validation = this._servico.validStep3(this.dataItem);
    }
    if(this.step == 4 && this.pedirDadosVoo){
      validation = this._servico.validDadosVoo(this.dataItem);
    }
    if(this.step == 6){
      validation = this._servico.validStep4(this.dataItem);
    }
    if(this.step == 7){
      validation = this._servico.validDadosPassageiros(this.dataItem.passageiros);
    }

    if(validation.status){
      this.step++;
      if(this.step == 4 && !this.pedirDadosVoo){
        this.step++;
      }
    }else{
      //this.showModalMessage(validation.data);
      this.subscribeMessage = validation.data;
    }  

    if(this.step == 2){
      if(this.dataTipos.status == null){
        this.getTiposServico();
      }  
    }

  }
  /**
   * 
   * Prev Step
   * 
   */
  prev(){
    this.step--;
    if(this.step == 4 && !this.pedirDadosVoo){
      this.step--;
    }
  }
  /**
   * 
   * Show
   * 
   * @param message 
   * @param classCustom 
   * 
   */
  showModalMessage(message,classCustom="alert alert-info"){

    this.message.data  = message;
    this.message.class = classCustom;
    this.message.show  = true;

  }
   /**
   * 
   * Tipo de Serviço
   * 
   */
  getTiposServico(){

    try{

      this.loaderTipoServico  = true;
      this.messageLoader      = "Carregando tipos de serviço. Aguarde!";

      this.api.manage().listaTiposServicosById(this.servico.id,this.dataItem.macro_regiao_id).subscribe(response => {

        this.loaderTipoServico = false;

        if(response.status == 1){
          
          let data = this._servico.agrupaPorTipoServico(response.data);

          this.dataTipos = {
            data: data.response,
            countTipo: data.countTipo,
            contemRegular: data.contemRegular,
            contemPrivativo: data.contemPrivativo, 
            status: 1,
            success: data.response.length == 0 ? false : true 
          };
          this.checkTipoServico(); 

        }else{
          
          this.dataTipos = {
            data: "Houve um erro no carregamento.",
            countTipo: null,
            contemRegular: false,
            contemPrivativo: false,
            status: 500,
            success: false
          }
           
        }
        
      },(response) => {

        this.loaderTipoServico = false;

      });

    }catch(e){

      this.loaderTipoServico = false;

    }

  }
  /**
   * 
   * Checa os tipos de serviço
   * 
   * 
   */
  checkTipoServico(){

    if(this.dataTipos.countTipo == 1){

      this.dataItem.tipo_servico_id = this.dataTipos.data[0].idTipoServico;
      this.dataItem.tipo_servico    = this.dataTipos.data[0].tipoServico;

      if(this.dataItem.tipo_servico_id == 1){
        if(this.dataItem.hotel_id == null){
          this.setStep(3);
        }else{
          this.setStep(5);
        }
      }

    }else{

      if(this.dataTipos.contemRegular){ 
        this.dataItem.tipo_servico_id = 1;
        this.dataItem.tipo_servico    = "Regular";
      }else if(this.dataTipos.contemPrivativo){
        this.dataItem.tipo_servico_id = 2;
        this.dataItem.tipo_servico    = "Privativo";
      }
      
    }

  }
  /**
   * 
   * Iniciliza o dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      nome: this.servico.nome,
      servico_id: this.servico.id,
      tipo_servico: null,
      tipo_servico_id: null,
      tipo_transporte: null,
      tipo_transporte_id: null,
      tipo_contratacao: null, 
      parametro_servico_id: this.servico.produto_parametro_servico_id,
      macro_regiao_id: null,
      macro_regiao: null,
      zona_regiao_hotel_id: null,
      zona_regiao_hotel: null,
      cidade_id: null,
      data_utilizacao: null,
      valor_adt: 0,
      valor_snr: 0,
      valor_chd: 0,
      valor_servico: 0,
      nome_hotel: null,
      hotel_id: null,
      codigo_moeda: "",
      qtd_adt: 1,
      qtd_chd: 0,
      qtd_inf: 0,
      qtd_snr: 0,
      passageiros: [],
      voo: "",
      cia_aerea_text: "",
      cia_aerea_codigo: "",
      aeroporto: "",
      nome_cia_aerea: "",
      pedir_dados_voo: false,
      lotacao_maxima: 0
    }

    if(this.ultimoServicoInserido != null){
      
      this.dataItem.hotel_id              = this.ultimoServicoInserido.hotel_id; 
      this.dataItem.nome_hotel            = this.ultimoServicoInserido.nome_hotel; 
      this.dataItem.macro_regiao_id       = this.ultimoServicoInserido.macro_regiao_id; 
      this.dataItem.macro_regiao          = this.ultimoServicoInserido.macro_regiao;
      this.dataItem.zona_regiao_hotel     = this.ultimoServicoInserido.zona_regiao_hotel;
      this.dataItem.zona_regiao_hotel_id  = this.ultimoServicoInserido.zona_regiao_hotel_id;
      this.dataItem.cidade_id             = this.ultimoServicoInserido.cidade_id;
    
    }
    if(this.dataItem.parametro_servico_id == 1 || this.dataItem.parametro_servico_id == 2){

      this.pedirDadosVoo            = true;
      this.dataItem.pedir_dados_voo = this.pedirDadosVoo;

    }


  }
  /**
   * 
   * Change Cia Aerea
   * 
   * @param value 
   * 
   */
  changeCia(data){

    this.dataItem.cia_aerea_text   = data.text;
    this.dataItem.cia_aerea_codigo = data.codigo;
  }
  /**
   * 
   * Change Aeroporto
   * 
   * @param value 
   * 
   */
  changeAeroporto(data){

    this.dataItem.aeroporto = data.text;

  }
  /**
   * 
   * Change a Região
   * 
   */
  changeMacroRegiao(value){

    this.dataItem.macro_regiao_id = value.idMacroRegiao;
    this.dataItem.macro_regiao    = value.macroRegiao;

  }
  /**
   * 
   * Change a Região
   * 
   */
  changeHotel(value){

    this.dataItem.nome_hotel = value.hotelPousada;
    this.dataItem.hotel_id   = value.idHotelPousada;

    if(value.idZonaRegiaoHotel.length > 0){
      this.dataItem.zona_regiao_hotel_id = value.idZonaRegiaoHotel;
    }
    if(value.zonaRegiaoHotel.length > 0){
      this.dataItem.zona_regiao_hotel = value.zonaRegiaoHotel;
    }
    if(value.idCidadeMunicipio.length > 0){
      this.dataItem.cidade_id = value.idCidadeMunicipio;
    }
    if(value.idCidadeMunicipio.length > 0){
      this.dataItem.cidade_id = value.idCidadeMunicipio;
    }

  }
  /**
   * 
   * Change QTDs
   * 
   * @param value 
   * 
   */
  changeQtds(value){

    this.dataItem.qtd_adt = value.qtd_adt;
    this.dataItem.qtd_chd = value.qtd_chd;
    this.dataItem.qtd_inf = value.qtd_inf;
    this.dataItem.qtd_snr = value.qtd_snr;

  }
  /**
   * 
   * Change Lista de Passgaeiros
   * 
   * @param value 
   * 
   */
  changeListaPassageiros(passageiros){

    this.dataItem.passageiros = passageiros;

  }
  /**
   * 
   * Change Data Serviço
   * 
   */
  changeDataServico(value){
  
    this.dataItem.data_utilizacao = value.data;
    this.disponibilidadeArray     = value.disponibilidadeArray;

    let tarifas:any = this._servico.getTarifasByDate2(
      this.dataItem.data_utilizacao,
      this.dataItem.zona_regiao_hotel_id,
      this.dataItem.cidade_id,
      this.disponibilidadeArray
    );
        
    this.dataItem.valor_adt          = tarifas.valor_adt; 
    this.dataItem.valor_chd          = tarifas.valor_chd; 
    this.dataItem.valor_snr          = tarifas.valor_snr; 
    this.dataItem.valor_servico      = tarifas.valor_servico; 
    this.dataItem.codigo_moeda       = tarifas.codigo_moeda; 
    this.dataItem.tipo_contratacao   = tarifas.tipo_contratacao; 

 
  }
  /**
   * 
   * Inseri no Carrinho
   * 
   */
  addCart(){

    try{

      let validation = this._servico.validDadosPassageiros(this.dataItem.passageiros);
      
      if(!validation.status){ 
        this.subscribeMessage = validation.data;
        return false;
      }  
      
      this.loader        = true;
      this.messageLoader = "Inserindo no carrinho. Aguarde!";
      this.subscribeMessage = null;

      this.recaptchaV3Service.execute('importantAction')
        .subscribe((token) => {

          this.dataItem.token = token;  

          this.api.cart().insert(this.dataItem).subscribe(response => {

            let messages = "";
            this.loader  = false;

            switch(response.status){
              case 1:
                this.storage.setUltimoServico(this.dataItem);
                //this.messageAdd.show = true;
                this._cart.emitirUpdateTotalCart.emit(response.data.total_cart);
                this.initDataItem();
                //this.setMacroRegiao();
                this.step = 8;
              break;
              case 422:
                  
                  let messages = ""

                  if(typeof(response.data.item) != "undefined"){
                    if(response.data.item.length != 0){
                      messages += "<span>" 
                      messages += this.app.getErrors([response.data.item])
                      messages += "</span><br>"
                    } 
                  }  
      
                  messages += "<span>";
                  
                  if(typeof(response.data.paxs) != "undefined"){
                    if(response.data.paxs.length > 0){
                      messages += this.app.getErrors(response.data.paxs,true)
                    }  
                  }  
                  messages += "</span>";

                  this.subscribeMessage = messages;

              break;
              default:
                messages = "Houve um erro interno. Tente novamente.";
                this.subscribeMessage = messages;
              break;
            }

          },response => {

            this.loader = false;

          });

        },(response) => { 

          this.loader   = false;
          this.app.info("Token de validação inválido. Recarregue a página para continuar.");
  
        });
        
    }catch(e){

    }

  }
  /**
   * 
   * Seta a Macro Região
   * 
   */
  setMacroRegiao(){

    if(this.macroRegiao.length > 0){
      
      for(let index = 0; index < this.macroRegiao.length; index++) {
        
        if(this.macroRegiao[index].padrao === "true" || this.macroRegiao[index].padrao === true){

          this.dataItem.macro_regiao_id = this.macroRegiao[index].id;
          this.dataItem.macro_regiao    = this.macroRegiao[index].descricao;

        }
        
      }
      if(this.dataItem.macro_regiao_id != null){
        this.setStep(2);
        if(this.dataTipos.status == null){
          setTimeout(() => {
            this.getTiposServico();
          },500);
        }  
      }

    }

  }
  /***
   * 
   * Change Step
   * 
   */
  _changeStep(step){

    this.step = step;

  }
  /**
   * 
   * Iniciliza as Funções
   * 
   */
  ngOnInit(){
    this.initDataItem();
    this.setMacroRegiao();
  }
  /**
   * 
   * Vefifica mudanças
   * 
   * @param changes 
   * 
   */
  ngOnChanges(changes:any):void{
  }

}
