<div class="page page-destino">
  <div class="section-breadcrumbs" *ngIf="itensBreadcrumbs.length > 0"> 
    <div class="container">
      <breadcrumbs [itens]="itensBreadcrumbs"></breadcrumbs>
    </div>  
  </div> 
  <div *ngIf="response.status == 1">
    <div class="page-title">
      <div class="container">
        <h1>Os melhores serviços em <strong>{{regiao.titulo}}</strong></h1>
      </div>  
    </div>  
    <div class="page-content">
      <div class="section section-produtos" *ngIf="produtos.length > 0">
        <div class="container">
          <div class="row">
            <div class="col-md-4" *ngFor="let produto of produtos;let i=index;">
              <produto-item [data]="produto"></produto-item>
            </div>  
          </div>  
        </div>   
      </div>  
      <div class="section section-produtos" *ngIf="produtos.length == 0">
        <div class="container">
          <span class="alert alert-info alert-message">
            Não temos serviços para mostrar neste destino.
          </span>  
        </div>  
      </div>
    </div> 
  </div>  
  <div *ngIf="response.status == 500">
    <div class="container">
      <error-custom title="Não foi possível carregar os dados da Página" [details]="response.data" (reload)="getData()"></error-custom>
    </div>
  </div> 
</div>
<modulo-loader [message]="messageLoader" *ngIf="loader"></modulo-loader> 
