<a href="javascript:void()" class="btn-menu-open fadeIn {{classColor}}" (click)="open()" style="display:none;">
  <img src="/images/icons/alltour/menu/menu.svg" alt="Menu" width="55px" />
</a>  
<nav class="menu-content animated {{classAnimate}} {{classCustom}}">
  <div class="menu-content-content">
    <div class="header" style="display:none;">
      <figure class="close-icon" (click)="close()">
        <img src="/images/icons/alltour/menu/close.svg" alt="Menu" width="50px" />
      </figure>  
    </div>  
    <ul class="menu" #menu itemscope itemtype="https://schema.org/Menu">
      <li itemprop="hasMenuItem" itemscope itemtype="https://schema.org/MenuItem" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <a routerLink="">
          <span itemprop="name">HOME</span>
        </a>
      </li>
      <li itemprop="hasMenuItem" itemscope itemtype="https://schema.org/MenuItem" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <a routerLink="sobre">
          <span itemprop="name">ALLTOUR</span>
        </a>
      </li> 
      <li *ngFor="let menu of regiao_menu" [ngClass]="{'has-sub-itens': menu.produtos.length > 0}" itemprop="hasMenuItem" itemscope itemtype="https://schema.org/MenuItem" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <a class="a-first" href="javascript:void();" *ngIf="menu.produtos.length > 0">
          <span itemprop="name">{{menu.titulo}}</span>
          <img src="/images/icons/alltour/menu/arrow.svg" alt="Arrow" class="arrow" />
        </a>
        <a routerLink="servicos/{{menu.apelido}}" *ngIf="menu.produtos.length == 0">
          <span itemprop="name">{{menu.titulo}}</span>
        </a>
        <ul class="sub-itens animated scale-up-ver-center" *ngIf="menu.produtos.length > 0"> 
          <li *ngFor="let item of menu.produtos;let i=index;">
            <a routerLink="servicos/{{menu.apelido}}/{{item.apelido_categoria}}/{{item.apelido}}" [title]="item.nome">
              {{item.nome}}
            </a>  
          </li>
        </ul> 
      </li>
      <!--<li itemprop="hasMenuItem" itemscope itemtype="https://schema.org/MenuItem" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <a routerLink="sobre">
          <span itemprop="name">LITORAL NORTE</span>
        </a>
      </li>
      <li itemprop="hasMenuItem" itemscope itemtype="https://schema.org/MenuItem" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <a routerLink="sobre">
          <span itemprop="name">LITORAL SUL</span>
        </a>
      </li>
      <li itemprop="hasMenuItem" itemscope itemtype="https://schema.org/MenuItem" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <a routerLink="sobre">
          <span itemprop="name">CHAPADA DIAMANTINA</span>
        </a>
      </li>-->
      <!--<li class="has-sub-itens" itemprop="hasMenuItem" itemscope itemtype="https://schema.org/MenuItem" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <a href="javascript:void();">
          <span itemprop="name">SERVIÇOS</span>
          <img src="/images/icons/alltour/menu/arrow.svg" alt="Arrow" class="arrow" />
        </a>
        <ul class="sub-itens animated scale-up-ver-center"> 
          <li *ngFor="let item of categorias;let i=index;">
            <a routerLink="servicos/{{item.apelido}}" [title]="item.titulo">
              {{item.titulo}}
            </a>  
          </li>
        </ul> 
      </li>-->
      <li itemprop="hasMenuItem" itemscope itemtype="https://schema.org/MenuItem" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <a routerLink="consultar-reserva">
          <span itemprop="name">MINHAS RESERVAS</span>
        </a>
      </li>
      <li class="li-minhas-compras" itemprop="hasMenuItem" itemscope itemtype="https://schema.org/MenuItem" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"> 
        <a routerLink="carrinho">
          <span itemprop="name">MINHAS COMPRAS</span>
        </a>
      </li>
      <li itemprop="hasMenuItem" itemscope itemtype="https://schema.org/MenuItem" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <a routerLink="contato">
          <span itemprop="name">CONTATO</span>
        </a>
      </li>
    </ul>
  </div>  
</nav>