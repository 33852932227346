import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatAutocomplete } from '@angular/material/autocomplete';
import {map,startWith} from 'rxjs/operators';

@Component({
  selector: 'produto-form-search-two',
  templateUrl: './produto-form-search-two.component.html',
  styleUrls: ['./produto-form-search-two.component.scss']
})
export class ProdutoFormSearchTwoComponent implements OnInit, OnChanges {
  
  public dataItem:any  = {
    nome: "",
    produto_categoria_id: "",
    produto_regiao_id: ""
  };
  @Input("produtos") produtos:any = []; 
  @Input("categorias") categorias:any = [];  
  @Input("regioes") regioes:any   = [];
  @Input("template")     template = "one"; 
  @Output("change") _change:any       = new EventEmitter();
  public myControl                = new UntypedFormControl();
  public options:any        = [];
  public filteredOptions:Observable<string[]>;
  @ViewChild("auto") auto:MatAutocomplete;
  
  constructor(){ 
    this.initDataItem();
  }

  /**
   * 
   * Inicializa o dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      nome: "",
      produto_categoria_id: "",
      produto_regiao_id: ""
    }

  }
  /**
   * 
   * Filter
   * 
   * @param value 
   * 
   */
  private _filter(value:any){
    
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  
  }
  /***
   * 
   * Set Options
   * 
   */
  setOptions(id=null){

    this.options = [];

    for(let index = 0; index < this.produtos.length; index++) {
      
      if(id == null){
        this.options.push(this.produtos[index].nome); 
      }else if(id == this.produtos[index].produto_categoria_id){
        this.options.push(this.produtos[index].nome); 
      }  
      
    }
      

  }
  _changeInput(){

    this._change.emit(this.dataItem); 

  }
  /**
   * 
   * Categoria
   * 
   */
  _changeCategoria(){

    this.dataItem.nome = "";
    this._change.emit(this.dataItem); 

  }
  /**
   * 
   * Categoria
   * 
   */
  _changeRegiao(){

    this.dataItem.nome = "";
    this._change.emit(this.dataItem); 

  }
  /**
   * 
   * Filtered
   * 
   */
  _filtered(){
    
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
    );
    
  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    
    this.setOptions();

    this.myControl.valueChanges.subscribe(data => {
      this._changeInput();
    });
    this._filtered();
         
  }
  /**
   * 
   * On Changes
   * 
   * @param changes  
   * 
   */
  ngOnChanges(changes:any): void {
   
    if(changes.produtos){
      this.produtos = changes.produtos.currentValue;
      this._filtered();
    }

  }

}
