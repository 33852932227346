import { ConteudoService } from './services/conteudo.service';
import { PhotoswipeService } from './services/photoswipe.service';
import { ProjetoService } from './services/projeto.service';
import { AppService } from './services/app.service';
import { ModulosService } from './services/modulos.service';
import { DataService } from './services/data.service';
import { ModulosResolver } from './resolvers/modulos.resolver';  
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; 
import { HomePageComponent } from './pages/home-page/home-page.component';
import { MenuComponent } from './components/menu/menu.component';
import { HeaderComponent } from './components/header/header.component';
import { LogoComponent } from './components/modulos/logo/logo.component';
import { ModuloComponent } from './components/modulos/modulo/modulo.component';
import { LoaderComponent } from './components/loader/loader.component';
import { SobreComponent } from './components/modulos/sobre/sobre.component';
import { ContatoComponent } from './components/modulos/contato/contato.component';
import { ClearUrlPipe } from './pipes/clear-url.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { PswpTemplateComponent } from './components/pswp-template/pswp-template.component'; 
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { ErrorServidorComponent } from './components/errors/error-servidor/error-servidor.component';
import { SiteDesabilitadoPageComponent } from './pages/site-desabilitado-page/site-desabilitado-page.component';
import { FooterComponent } from './components/footer/footer.component';
import { EnderecoComponent } from './components/modulos/endereco/endereco.component';
import { BannerSliderComponent } from './components/modulos/banner-slider/banner-slider.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ContatoPageComponent } from './pages/contato-page/contato-page.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { NewsletterComponent } from './components/modulos/newsletter/newsletter.component';
import { SeloTripComponent } from './components/modulos/selo-trip/selo-trip.component';
import { TelefoneDddDirective } from './directives/telefone-ddd.directive';
import { MaskDirective } from './directives/mask.directive';
import { MapGoogleComponent } from './components/mapas/map-google/map-google.component';
import { RotasComponent } from './components/modulos/rotas/rotas.component';
import { ProdutosCategoriaComponent } from './components/modulos/produtos-categoria/produtos-categoria.component';
import { ProdutoTemplateOneComponent } from './components/produtos/produto-template-one/produto-template-one.component';
import { ProdutoFotoComponent } from './components/produtos/produto-foto/produto-foto.component';
import { ProdutoValorPipe } from './pipes/produto-valor.pipe';
import { ErrorCustomComponent } from './components/errors/error-custom/error-custom.component';
import { PaginationCustomComponent } from './components/pagination/pagination-custom/pagination-custom.component';
import { FooterContatoComponent } from './components/footer-contato/footer-contato.component';
import { SobrePageComponent } from './pages/sobre-page/sobre-page.component';
import { GaleriaOneComponent } from './components/galeria/galeria-one/galeria-one.component';
import { EventoItemComponent } from './components/evento/evento-item/evento-item.component';
import { EventoDateFormatPipe } from './pipes/evento-date-format.pipe';
import { DateService } from './services/date.service';
import { EventoService } from './services/evento.service';
import { EventoSearchComponent } from './components/evento/evento-search/evento-search.component';
import {MatSelectModule} from '@angular/material/select';
import {MatDialogModule} from '@angular/material/dialog';
import {MatRadioModule} from '@angular/material/radio';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';  
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { ServicosOferecidosComponent } from './components/modulos/servicos-oferecidos/servicos-oferecidos.component';
import { RedeSocialComponent } from './components/modulos/rede-social/rede-social.component';
import { ProdutoListaCategoriasComponent } from './components/modulos/produto-lista-categorias/produto-lista-categorias.component';
import { ServicosPage } from './pages/servico/servicos-page/servicos-page.component';
import { ApiService } from './services/api.service';
import { ProdutoItemComponent } from './components/produtos/produto-item/produto-item.component';
import { ServicoPage } from './pages/servico/servico-page/servico-page.component';
import { CartService } from './services/cart.service'; 
import { UppercaseDirective } from './directives/uppercase.directive';
import { CartPage } from './pages/cart-page/cart-page.component';
import { ErrorOneComponent } from './components/errors/error-one/error-one.component';
import { CalcValorTotalPipe } from './pipes/calc-valor-total.pipe';
import { CalcValorServicoPipe } from './pipes/calc-valor-servico.pipe';
import { TipoPaxsDescPipe } from './pipes/tipo-paxs-desc.pipe';
import { BtnDeleteCartItemComponent } from './components/cart/btn-delete-cart-item/btn-delete-cart-item.component';
import { ModalConfirmDeleteComponent } from './components/cart/modal-confirm-delete/modal-confirm-delete.component';
import { BtnAbandonarCompraComponent } from './components/cart/btn-abandonar-compra/btn-abandonar-compra.component';
import { BtnEsvaziarCartComponent } from './components/cart/btn-esvaziar-cart/btn-esvaziar-cart.component';
import { ModalConfirmEsvaziarCartComponent } from './components/cart/modal-confirm-esvaziar-cart/modal-confirm-esvaziar-cart.component';
import { TotalCartComponent } from './components/cart/total-cart/total-cart.component';
import { AtividadeService } from './services/atividade.service';
import { CartDadosFormUpdateComponent } from './components/cart/cart-dados-form-update/cart-dados-form-update.component';
import { ComboParcelasCarneComponent } from './components/combos/combo-parcelas-carne/combo-parcelas-carne.component';
import { ComboParcelasCartaoComponent } from './components/combos/combo-parcelas-cartao/combo-parcelas-cartao.component';
import { ComboValidadeAnoComponent } from './components/combos/combo-validade-ano/combo-validade-ano.component';
import { ComboValidadeMesComponent } from './components/combos/combo-validade-mes/combo-validade-mes.component';
import { BtnConsultarReservaComponent } from './components/btns/btn-consultar-reserva/btn-consultar-reserva.component';
import { BtnVisualizarBoletoComponent } from './components/btns/btn-visualizar-boleto/btn-visualizar-boleto.component';
import { BtnVisualizarBoletoTaxaComponent } from './components/btns/btn-visualizar-boleto-taxa/btn-visualizar-boleto-taxa.component';
import { BtnVisualizarCarneComponent } from './components/btns/btn-visualizar-carne/btn-visualizar-carne.component';
import { BtnVoltarInicioComponent } from './components/btns/btn-voltar-inicio/btn-voltar-inicio.component';
import { BtnVisualizarVoucherComponent } from './components/btns/btn-visualizar-voucher/btn-visualizar-voucher.component';
import { IntegerDirective } from './directives/integer.directive';
import { MatModalInfoDialogComponent } from './components/modais/mat-modal-info-dialog/mat-modal-info-dialog.component';
import { EmptyPipe } from './pipes/empty.pipe';
import { ModalConfirmComponent } from './components/modais/modal-confirm/modal-confirm.component';
import { ModalInfoComponent } from './components/modais/modal-info/modal-info.component';
import { WhatsappComponent } from './components/modulos/whatsapp/whatsapp.component';
import { MenuFooterComponent } from './components/menu-footer/menu-footer.component';
import { TripAdvisorComponent } from './components/modulos/trip-advisor/trip-advisor.component';
import { ProdutoDestaquesComponent } from './components/modulos/produto-destaques/produto-destaques.component';
import { ProdutoItemBannerComponent } from './components/produtos/produto-item-banner/produto-item-banner.component';
import { TotalCarrinhoComponent } from './components/carrinho/total-carrinho/total-carrinho.component';
import { ConsultarReservaPage } from './pages/consultar-reserva-page/consultar-reserva-page.component';
import { MapaGoogleComponent } from './components/mapa/mapa-google/mapa-google.component';
import { ProdutoItemValorComponent } from './components/produtos/produto-item-valor/produto-item-valor.component';
import { MaxPipe } from './pipes/max.pipe';
import { TrasladosDestaquesComponent } from './components/modulos/traslados-destaques/traslados-destaques.component';
import { DmCartAdd } from './components/carrinho/dm-cart-add/dm-cart-add.component';
import { DmCartDataServicoComponent } from './components/carrinho/dm-cart-data-servico/dm-cart-data-servico.component';
import { StorageService } from './services/storage.service';
import { ServicoService } from './services/servico.service';
import { GetTarifaMinimaListaPipe } from './pipes/get-tarifa-minima-lista.pipe';
import { FormatValorManagerPipe } from './pipes/format-valor-manager.pipe';
import { ComboManagerMacroRegiaoComponent } from './components/combos/combo-manager-macro-regiao/combo-manager-macro-regiao.component';
import { DmCartTarifasResumoComponent } from './components/carrinho/dm-cart-tarifas-resumo/dm-cart-tarifas-resumo.component';
import { ComboManagerHotelComponent } from './components/combos/combo-manager-hotel/combo-manager-hotel.component';
import { MatSelectSearchComponent } from './components/combos/mat-select-search/mat-select-search.component';
import { DmCartResumoStepsComponent } from './components/carrinho/dm-cart-resumo-steps/dm-cart-resumo-steps.component';
import { DmCartPassageirosQtdComponent } from './components/carrinho/dm-cart-passageiros-qtd/dm-cart-passageiros-qtd.component';
import { DmCartPassageiroListaInformacaoComponent } from './components/carrinho/dm-cart-passageiro-lista-informacao/dm-cart-passageiro-lista-informacao.component';
import { TipoPassageiroDescPipe } from './pipes/tipo-passageiro-desc.pipe';
import { ValidatorService } from './services/validator.service';
import { DmCartItemComponent } from './components/carrinho/dm-cart-item/dm-cart-item.component';
import { TipoServicoPipe } from './pipes/tipo-servico.pipe';
import { DmCartAddSuccessModalComponent } from './components/carrinho/modal/dm-cart-add-success-modal/dm-cart-add-success-modal.component';
import { PagamentoOnePage } from './pages/pagamento-one-page/pagamento-one-page.component';
import { DmCartPagamentoStepsComponent } from './components/carrinho/pagamento/dm-cart-pagamento-steps/dm-cart-pagamento-steps.component';
import { DmCartPagamentoDadosCompradorComponent } from './components/carrinho/pagamento/dm-cart-pagamento-dados-comprador/dm-cart-pagamento-dados-comprador.component';
import { DmCartPagamentoResumoComponent } from './components/carrinho/pagamento/dm-cart-pagamento-resumo/dm-cart-pagamento-resumo.component';
import { ClearCifraoPipe } from './pipes/clear-cifrao.pipe';
import { DmCartPagamentoFormasPagamentoComponent } from './components/carrinho/dm-cart-pagamento-formas-pagamento/dm-cart-pagamento-formas-pagamento.component';
import { DmCartPagamentoTipoComponent } from './components/carrinho/pagamento/tipo/dm-cart-pagamento-tipo/dm-cart-pagamento-tipo.component';
import { DmCartPagamentoRedePayComponent } from './components/carrinho/pagamento/tipo/dm-cart-pagamento-rede-pay/dm-cart-pagamento-rede-pay.component';
import { ServicoCategoriaPage } from './pages/servico-categoria-page/servico-categoria-page.component';
import { ProdutoCategoriaItemComponent } from './components/produtos/produto-categoria-item/produto-categoria-item.component';
import { DmCartItemTwoComponent } from './components/carrinho/dm-cart-item-two/dm-cart-item-two.component';
import { DmCartPagamentoFinalizadoSucessoComponent } from './components/carrinho/pagamento/dm-cart-pagamento-finalizado-sucesso/dm-cart-pagamento-finalizado-sucesso.component';
import { DmCartPagamentoConfirmacaoEmailComponent } from './components/carrinho/pagamento/dm-cart-pagamento-confirmacao-email/dm-cart-pagamento-confirmacao-email.component';
import { DmBreadcrumbsComponent } from './components/breadcrumbs/dm-breadcrumbs/dm-breadcrumbs.component';
import { DmCartPagamentoGerenciaNetBoletoComponent } from './components/carrinho/pagamento/tipo/dm-cart-pagamento-gerencia-net-boleto/dm-cart-pagamento-gerencia-net-boleto.component';
import { DmCartPagamentoCieloComponent } from './components/carrinho/pagamento/tipo/dm-cart-pagamento-cielo/dm-cart-pagamento-cielo.component';
import { ProdutoCategoriaDestaqueComponent } from './components/modulos/produto-categoria-destaque/produto-categoria-destaque.component';
import { DmCartPagamentoIpagComponent } from './components/carrinho/pagamento/tipo/dm-cart-pagamento-ipag/dm-cart-pagamento-ipag.component';
import { DmCartDadosVooComponent } from './components/carrinho/dm-cart-dados-voo/dm-cart-dados-voo.component';
import { ComboManagerAeroportoComponent } from './components/combos/combo-manager-aeroporto/combo-manager-aeroporto.component';
import { ComboManagerCiaComponent } from './components/combos/combo-manager-cia/combo-manager-cia.component';
import { ComboCiaAereaComponent } from './components/combos/combo-cia-aerea/combo-cia-aerea.component';
import { ComboAeroportoComponent } from './components/combos/combo-aeroporto/combo-aeroporto.component';
import { DmCartPagamentoConfirmacaoImprimirComponent } from './components/carrinho/pagamento/dm-cart-pagamento-confirmacao-imprimir/dm-cart-pagamento-confirmacao-imprimir.component';
import { DmCartPagamentoConfirmacaoDownloadComponent } from './components/carrinho/pagamento/dm-cart-pagamento-confirmacao-download/dm-cart-pagamento-confirmacao-download.component';
import { PoliticaDeCancelamentoPage } from './pages/politica-de-cancelamento-page/politica-de-cancelamento-page.component';
import { PoliticaDePrivacidadePage} from './pages/politica-de-privacidade-page/politica-de-privacidade-page.component';
import { ParceirosComponent } from './components/modulos/parceiros/parceiros.component';
import { DestinosComponent } from './components/modulos/destinos/destinos.component';
import { DestinoServicosPage } from './pages/destinos/destino-servicos-page/destino-servicos-page.component';
import { GaleriaTwoComponent } from './components/galeria/galeria-two/galeria-two.component';
import { ProdutoFormContatoComponent } from './components/produtos/produto-form-contato/produto-form-contato.component';
import { MeiosPagamentoComponent } from './components/meios-pagamento/meios-pagamento.component'; 
import { ProdutoFormSearchComponent } from './components/produtos/produto-form-search/produto-form-search.component';
import { ProdutoVendaOfflineComponent } from './components/produtos/produto-venda-offline/produto-venda-offline.component';
import { DmCartPassageiroTitularComponent } from './components/carrinho/dm-cart-passageiro-titular/dm-cart-passageiro-titular.component';
import { MenuOQueFazemosComponent } from './components/menu-o-que-fazemos/menu-o-que-fazemos.component';
import { ViagensCorporativasPage } from './pages/o-que-fazemos/viagens-corporativas-page/viagens-corporativas-page.component';
import { GruposEEventosPage } from './pages/o-que-fazemos/grupos-e-eventos-page/grupos-e-eventos-page.component';
import { ReceptivoPage } from './pages/o-que-fazemos/receptivo-page/receptivo-page.component';
import { ServicosAeroportuariosPage } from './pages/o-que-fazemos/servicos-aeroportuarios-page/servicos-aeroportuarios-page.component';
import { ProdutoSlideShowComponent } from './components/produtos/produto-slide-show/produto-slide-show.component';
import { UnidadeItemComponent } from './components/unidades/unidade-item/unidade-item.component';
import { HorariosModulo } from './components/modulos/horarios-modulo/horarios-modulo.component';
import { FormasDePagamentoComponent } from './components/formas-de-pagamento/formas-de-pagamento.component';
import { ProdutoRegiaoDestaqueComponent } from './components/modulos/produto-regiao-destaque/produto-regiao-destaque.component';
import { ProdutoRegiaoItemComponent } from './components/produtos/produto-regiao-item/produto-regiao-item.component';
import { ProdutoSlideShowTwoComponent } from './components/produtos/produto-slide-show-two/produto-slide-show-two.component';
import { DeviceDetectorModule } from 'ngx-device-detector'; 
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ProdutoFormSearchTwoComponent } from './components/produtos/produto-form-search-two/produto-form-search-two.component'; //@3.4.2
import { HttpClientModule } from '@angular/common/http';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

@NgModule({
    declarations: [
        AppComponent,
        HomePageComponent,
        MenuComponent,
        HeaderComponent,
        LogoComponent,
        ModuloComponent,
        LoaderComponent,
        SobreComponent,
        ContatoComponent,
        ClearUrlPipe,
        FilterPipe,
        PswpTemplateComponent,
        ErrorServidorComponent,
        SiteDesabilitadoPageComponent,
        FooterComponent,
        EnderecoComponent,
        BannerSliderComponent,
        SafeHtmlPipe,
        ContatoPageComponent,
        BreadcrumbsComponent,
        SobrePageComponent,
        NewsletterComponent,
        SeloTripComponent,
        TelefoneDddDirective,
        MaskDirective,
        MapGoogleComponent,
        RotasComponent,
        ProdutosCategoriaComponent,
        ProdutoTemplateOneComponent,
        ProdutoFotoComponent,
        ProdutoValorPipe,
        ErrorCustomComponent,
        PaginationCustomComponent,
        FooterContatoComponent,
        GaleriaOneComponent,
        EventoItemComponent,
        EventoDateFormatPipe,
        EventoSearchComponent,
        ServicosOferecidosComponent,
        RedeSocialComponent,
        ProdutoListaCategoriasComponent,
        ServicosPage,
        ProdutoItemComponent,
        ServicoPage,
        UppercaseDirective,
        CartPage,
        ErrorOneComponent,
        CalcValorTotalPipe,
        CalcValorServicoPipe,
        TipoPaxsDescPipe,
        BtnDeleteCartItemComponent,
        ModalConfirmDeleteComponent,
        BtnAbandonarCompraComponent,
        BtnEsvaziarCartComponent,
        ModalConfirmEsvaziarCartComponent,
        TotalCartComponent,
        CartDadosFormUpdateComponent,
        ComboParcelasCarneComponent,
        ComboParcelasCartaoComponent,
        ComboValidadeAnoComponent,
        ComboValidadeMesComponent,
        BtnConsultarReservaComponent,
        BtnVisualizarBoletoComponent,
        BtnVisualizarBoletoTaxaComponent,
        BtnVisualizarCarneComponent,
        BtnVoltarInicioComponent,
        BtnVisualizarVoucherComponent,
        IntegerDirective,
        MatModalInfoDialogComponent,
        EmptyPipe,
        ModalConfirmComponent,
        ModalInfoComponent,
        WhatsappComponent,
        MenuFooterComponent,
        TripAdvisorComponent,
        ProdutoDestaquesComponent,
        ProdutoItemBannerComponent,
        TotalCarrinhoComponent,
        ConsultarReservaPage,
        MapaGoogleComponent,
        ProdutoItemValorComponent,
        MaxPipe,
        TrasladosDestaquesComponent,
        DmCartAdd,
        DmCartDataServicoComponent,
        GetTarifaMinimaListaPipe,
        FormatValorManagerPipe,
        ComboManagerMacroRegiaoComponent,
        DmCartTarifasResumoComponent,
        ComboManagerHotelComponent,
        MatSelectSearchComponent,
        DmCartResumoStepsComponent,
        DmCartPassageirosQtdComponent,
        DmCartPassageiroListaInformacaoComponent,
        TipoPassageiroDescPipe,
        DmCartItemComponent,
        TipoServicoPipe,
        DmCartAddSuccessModalComponent,
        PagamentoOnePage,
        DmCartPagamentoStepsComponent,
        DmCartPagamentoDadosCompradorComponent,
        DmCartPagamentoResumoComponent,
        ClearCifraoPipe,
        DmCartPagamentoFormasPagamentoComponent,
        DmCartPagamentoTipoComponent,
        DmCartPagamentoRedePayComponent,
        ServicoCategoriaPage,
        ProdutoCategoriaItemComponent,
        DmCartItemTwoComponent,
        DmCartPagamentoFinalizadoSucessoComponent,
        DmCartPagamentoConfirmacaoEmailComponent,
        DmBreadcrumbsComponent,
        DmCartPagamentoGerenciaNetBoletoComponent,
        DmCartPagamentoCieloComponent,
        ProdutoCategoriaDestaqueComponent,
        DmCartPagamentoIpagComponent,
        DmCartDadosVooComponent,
        ComboManagerAeroportoComponent,
        ComboManagerCiaComponent,
        ComboCiaAereaComponent,
        ComboAeroportoComponent,
        DmCartPagamentoConfirmacaoImprimirComponent,
        DmCartPagamentoConfirmacaoDownloadComponent,
        PoliticaDeCancelamentoPage,
        PoliticaDePrivacidadePage,
        ParceirosComponent,
        DestinosComponent,
        DestinoServicosPage,
        GaleriaTwoComponent,
        ProdutoFormContatoComponent,
        MeiosPagamentoComponent,
        ProdutoFormSearchComponent,
        ProdutoVendaOfflineComponent,
        DmCartPassageiroTitularComponent,
        MenuOQueFazemosComponent,
        ViagensCorporativasPage,
        GruposEEventosPage,
        ReceptivoPage,
        ServicosAeroportuariosPage,
        ProdutoSlideShowComponent,
        UnidadeItemComponent,
        HorariosModulo,
        FormasDePagamentoComponent,
        ProdutoRegiaoDestaqueComponent,
        ProdutoRegiaoItemComponent,
        ProdutoSlideShowTwoComponent,
        ProdutoFormSearchTwoComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatButtonModule,
        MatRadioModule,
        MatDialogModule,
        MatIconModule,
        MatAutocompleteModule,
        DeviceDetectorModule.forRoot(),
        LazyLoadImageModule,
        RecaptchaV3Module
    ],
    providers: [
        ModulosResolver,
        DataService,
        ModulosService,
        AppService,
        ProjetoService,
        PhotoswipeService,
        ConteudoService,
        DateService,
        EventoService,
        ApiService,
        CartService,
        DataService,
        AtividadeService,
        StorageService,
        ServicoService,
        ValidatorService,
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LdahgQiAAAAAFSAeaNFpahmXpxtnNAtdmKZSxkt' }
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule { }
