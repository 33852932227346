<div class="produto-slide-show" *ngIf="fotos.length > 0">
  <div class="content">
    <div class="navigation" *ngIf="fotos.length > 1">
      <div class="prev" (click)="prev()">
        <figure>
          <img src="/images/icons/alltour/banner/servico/prev.svg" alt="Prev" />
        </figure>  
      </div>
      <div class="next" (click)="next()">
        <figure>
          <img src="/images/icons/alltour/banner/servico/next.svg" alt="Next" />
        </figure>  
      </div>  
    </div>
    <figure class="foto animated fadeIn" *ngFor="let foto of fotos;let i=index;" [hidden]="i != indexSelected">
      <img [src]="fotos[indexSelected].imagem" [alt]="fotos[indexSelected].alt" class="img-fluid" />
    </figure>  
  </div>
  <div class="footer" *ngIf="fotos.length > 1">  
    <ul>
      <li *ngFor="let foto of fotos;let i=index;" (click)="setIndex(i)">
        <figure>
          <img [src]="foto.imagem" [alt]="foto.alt" />
        </figure>  
      </li>
    </ul>  
  </div>  
</div>
<div class="produto-slide-show" *ngIf="fotos.length == 0">
  <div class="content">
    <figure>
      <img src="/images/produto/sem-foto/alltour.jpg" alt="All Tour" class="img-fluid" />
    </figure>  
  </div>  
</div>
