import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'produto-item',
  templateUrl: './produto-item.component.html',
  styleUrls: ['./produto-item.component.scss']
})
export class ProdutoItemComponent implements OnInit {

  @Input("data") produto:any            = {};
  @Input("categoria") categoria:any     = null;
  @Input("classCustom") classCustom:any = "";
  public link = null;

  constructor(){ 

  }
  /**
   * 
   * Seta o link para o detalhe do produto
   * 
   */
  setLink(){

    if(this.categoria ==  null){
      this.link = "/servicos/"+this.produto.regiao_apelido+"/"+this.produto.categoria_apelido+"/"+this.produto.apelido;
    }else{
      this.link = "/servicos/"+this.produto.regiao_apelido+"/"+this.categoria.apelido+"/"+this.produto.apelido;
    }  

  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    this.setLink();
  }

}
