<div [ngSwitch]="template">
  <section class="modulo modulo-contato" *ngSwitchCase="null">
    <div class="container">
      <div class="modulo-title" *ngIf="data.mostrar_titulo">
        <div class="linha linha-left">
        </div>
        <h2>{{data.titulo}}</h2>
        <div class="linha linha-right">
        </div>
      </div> 
      <div class="modulo-content">
         <h3 class="descricao" *ngIf="data.params.descricao.length > 0">{{data.params.descricao}}</h3>
         <div class="row">
            <div class="col-lg-5 col-md-6">
              <div class="item-contato">
                <figure>
                  <img src="/images/modulos/contato/email.png" alt="Email - Bustour" />
                  <span class="bg"></span>
                </figure>
                <span class="text">
                  {{data.params.email}}
                </span>  
              </div> 
              <div class="content-form">
                <form class="form" (submit)="send()">
                  <div class="form-group">
                    <input type="text" [(ngModel)]="dataItem.nome" placeholder="Nome completo" name="nome" />
                  </div>
                  <div class="form-group telefone-input">
                    <div class="telefone-ddd">
                      <input type="text" [(ngModel)]="dataItem.telefone_ddd" placeholder="DDD" name="telefone_ddd" mask="99" (input)="onInputDDD($event)" />
                    </div>
                    <div class="telefone-numero">
                      <input type="text" [(ngModel)]="dataItem.telefone_numero" placeholder="Telefone" name="telefone_numero" mask="99999-9999" #telefoneNumero />
                    </div>  
                  </div> 
                  <div class="form-group">
                    <input type="text" [(ngModel)]="dataItem.email" placeholder="E-mail" name="email" />
                  </div> 
                  <div class="form-group">
                    <textarea type="text" [(ngModel)]="dataItem.mensagem" placeholder="Mensagem" name="mensagem" rows="7"></textarea>
                  </div> 
                  <div class="form-group">
                    <button type="submit" class="degrade-light-blue btn-custom" [disabled]="loader">
                      <span *ngIf="!loader">ENVIAR</span>
                      <span *ngIf="loader">ENVIANDO...</span>
                    </button>  
                  </div>  
                </form> 
                <modal-info [show]="message.show" [customClass]="message.class" [message]="message.data" (hide)="message.show=false"></modal-info>
              </div>  
            </div>  
            <div class="col-lg-4 col-md-6">
              <div class="item-contato">
                <figure>
                  <img src="/images/modulos/contato/location.png" alt="Localização - Bustour" />
                  <span class="bg"></span>
                </figure>
                <span class="text">
                  LOJA BUSTOUR
                </span>  
              </div>
              <modulo-position class="mapa" position="endereco" template="contato"></modulo-position>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="item-contato">
                <figure>
                  <img src="/images/modulos/contato/telefone-two.png" alt="Telefone - Bustour" />
                  <span class="bg"></span>
                </figure>
                <span class="text">
                  {{data.params.telefone}}
                </span>  
              </div>
            </div>  
         </div> 
      </div>  
    </div>  
  </section> 
  <section *ngSwitchCase="'telefone'" class="modulo modulo-telefone">
    <span class="icon"><i class="material-icons">phone</i></span>
    <span>{{data.params.telefone}}</span>
  </section> 
  <section *ngSwitchCase="'whatsapp'" class="modulo modulo-whatsapp">
    <img src="/images/modulos/contato/whatsapp-one.png" alt="Telefone" />
    <h4>CHAME AGORA</h4>
    <h5>{{data.params.telefone_whatsapp}}</h5>
  </section> 
  <section *ngSwitchCase="'footer-contato'" class="modulo modulo-footer-contato">
    <ul>
      <li>
        <a routerLink="contato" title="Contato">
          <h3>Precisa de Ajuda?</h3>
          <h4>Entre em Contato</h4>
        </a>  
      </li>
      <li>
        <div class="item">
          <div class="content-one">
            <figure>
              <img src="/assets/images/telefones/telefone-azul.png" alt="Telefone Brocker Eventos" />
            </figure>  
          </div>
          <div class="content-two">
            <div class="telefone">
              <span>+55 {{data.params.telefone}}</span>
            </div>
            <!--<div class="telefone">
              <span>+55 {{data.params.celular}}</span>
            </div>-->  
          </div>  
        </div>  
      </li>
      <li>
        <div class="item">
          <div class="content-one">
            <figure>
              <img src="/assets/images/telefones/whatsapp.png" alt="Whatsapp Brocker Eventos" />
            </figure>  
          </div>
          <div class="content-two">
            <div class="telefone">
              <span>{{data.params.telefone_whatsapp}}</span>
            </div>
          </div>  
        </div> 
      </li>
    </ul>  
  </section>  
  <section *ngSwitchCase="'footer-contato-redes-sociais'" class="modulo modulo-footer-contato-redes-sociais">
    <ul>
      <li>
        <h3>REDES SOCIAIS</h3>
        <h4>Siga-nos</h4>
      </li>
      <li>
        <ul class="redes-sociais">
          <li>
            <a [href]="data.params.facebook" title="Facebook" target="_blank">
              <img src="/images/modulos/redes-sociais/facebook.png" alt="Facebook" />
            </a>  
          </li>
          <li>
            <a [href]="data.params.instagram" title="Instagram" target="_blank">
              <img src="/images/modulos/redes-sociais/instagram.png" alt="Instagram" />
            </a>  
          </li> 
          <li>
            <a [href]="data.params.youtube" title="Youtube" target="_blank">
              <img src="/images/modulos/redes-sociais/youtube.png" alt="Youtube" />
            </a>  
          </li>  
        </ul>  
      </li>  
    </ul>  
  </section>
  <section *ngSwitchCase="'page-contato'" class="modulo modulo-footer-contato pg-contato">
    <div class="modulo-content">
      <h4>
        <img src="/images/icons/aextour/telefone.svg" alt="Telefone" />
        {{data.params.telefone}}
      </h4>
      <h4>
        <img src="/images/icons/aextour/telefone.svg" alt="Telefone" />
        {{data.params.celular}}
      </h4>
      <h4> 
        <img src="/images/icons/aextour/email.svg" alt="Email" />
        {{data.params.email}}
      </h4>
    </div> 
  </section> 
  <section *ngSwitchCase="'topo'" class="modulo modulo-contato-topo" itemscope itemtype="https://schema.org/Organization">
    <ul itemprop itemtype="https://schema.org/ContactPoint">   
      <li>
        <span itemprop="telephone">+55 {{data.params.celular}}</span>
      </li>
      <li class="divisor">
        <span class="linha"></span>
      </li>   
      <li>
        <span class="email" itemprop="email">{{data.params.email}}</span>
      </li>
    </ul>  
  </section> 
  <section *ngSwitchCase="'contato-home'" class="modulo modulo-contato-home" itemscope itemtype="https://schema.org/Organization">
    <div class="modulo-title">
      <h3>NOSSOS CONTATOS</h3>  
      <h4>com o melhor atendimento</h4> 
    </div>  
    <div class="modulo-content" itemprop itemtype="https://schema.org/ContactPoint">
      <h5> 
        <img src="/images/icons/sao-paulo-ecoturismo/email.svg" alt="E-mail - São Paulo Ecoturismo" />
        <span class="email" itemprop="email">{{data.params.email}}</span>
      </h5>
      <ul class="lista-telefones"> 
        <li>
          <h5>
            <img src="/images/icons/sao-paulo-ecoturismo/telefone.svg" alt="Telefone - São Paulo Ecoturismo" />
            <span class="telefone" itemprop="telephone">+55 {{data.params.telefone}}</span>
          </h5>
          <h5> 
            <img src="/images/icons/sao-paulo-ecoturismo/telefone.svg" alt="Telefone - São Paulo Ecoturismo" />
            <span class="telefone" itemprop="telephone">+55 {{data.params.celular}}</span>
          </h5>
        </li>
        <li>
          <h5>
            <img src="/images/icons/sao-paulo-ecoturismo/telefone.svg" alt="Telefone - São Paulo Ecoturismo" />
            <span class="telefone" itemprop="telephone">+55 {{data.params.telefone_secundario}}</span>
          </h5>
          <h5> 
            <img src="/images/icons/sao-paulo-ecoturismo/whatsapp-three.svg" alt="Telefone Whatsapp - São Paulo Ecoturismo" />
            <span class="telefone" itemprop="telephone">+55 {{data.params.whatsapp}}</span>
          </h5>
        </li>  
      </ul>    
    </div> 
  </section>
  <section *ngSwitchCase="'footer'" class="modulo modulo-contato-footer" itemscope itemtype="https://schema.org/Organization">
    <div class="modulo-titulo">
      <h3>{{data.titulo}}</h3>
      <h4 *ngIf="data.mostrar_subtitulo">{{data.subtitulo}}</h4>
    </div>
    <div class="modulo-content" itemprop itemtype="https://schema.org/ContactPoint">
      <div class="item item-email">
        <ul>
          <li>
            <h5>E-MAIL</h5>
            <h6 itemprop="email">{{data.params.email}}</h6> 
          </li>  
        </ul>  
      </div>
      <div class="item item-telefone">
        <ul>
          <li>
            <h5>TELEFONE</h5>
            <h6 itemprop="telephone">+55 {{data.params.telefone}}</h6> 
          </li>  
        </ul>  
      </div> 
      <div class="item item-whatsapp"> 
        <modulo-position position="whatsapp" template="footer"></modulo-position> 
      </div>  
    </div> 
  </section>
  <section *ngSwitchCase="'page-servico'" class="modulo modulo-page-servico" itemscope itemtype="https://schema.org/Organization">
    <div class="modulo-content" itemprop itemtype="https://schema.org/ContactPoint">
      <div class="item">
        <ul>
          <li>
            <figure>
              <img src="/images/icons/alltour/contato/telefone-two.svg" alt="Telefone - Alltour" />
            </figure>  
          </li>
          <li>
            <h5>TELEFONE</h5>
            <h4 itemprop="telephone">+55 {{data.params.telefone}}</h4>
          </li>
        </ul>  
      </div>
      <div class="item">
        <ul>
          <li>
            <figure>
              <img src="/images/icons/alltour/contato/email-two.svg" alt="E-mail - Alltour" />
            </figure>  
          </li>
          <li>
            <h5>E-MAIL</h5>
            <h4 itemprop="email">{{data.params.email}}</h4>
          </li>
        </ul>  
      </div>      
    </div> 
  </section>
  <section *ngSwitchCase="'page-pagamento'" class="modulo modulo-page-pagamento" itemscope itemtype="https://schema.org/Organization">
    <div class="content" itemprop itemtype="https://schema.org/ContactPoint">
      <h4>
        <img src="/images/icons/sao-paulo-ecoturismo/telefone.svg" alt="Telefone - São Paulo Ecoturismo" width="15px" />
        <span itemprop="telephone">{{data.params.telefone}}</span>
      </h4>
      <h4>
        <img src="/images/icons/sao-paulo-ecoturismo/telefone.svg" alt="Telefone - São Paulo Ecoturismo" width="15px" />
        <span itemprop="telephone">{{data.params.celular}}</span>
      </h4>
      <h4>
        <img itemprop="email" src="/images/icons/sao-paulo-ecoturismo/email.svg" alt="Email - São Paulo Ecoturismo" width="15px" />
        <span>{{data.params.email}}</span>
      </h4>
    </div>  
  </section> 
</div>  

