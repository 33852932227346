<h4 mat-dialog-title>
  Atenção! 
</h4>
<mat-dialog-content>
  <article class="alert alert-warning">
    Você está preste a excluir a vivência da mochila ! Deseja concluir?
  </article>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="btn-ten" (click)="esvaziar()">
    Sim
  </button>
  <button class="btn-eleven" type="button"  mat-dialog-close>
    Não
  </button>
</mat-dialog-actions>
<modulo-loader data-message="Esvaziando o Carrinho" *ngIf="loader"></modulo-loader>