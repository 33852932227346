import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { AppService } from '../../../services/app.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'produto-form-contato',
  templateUrl: './produto-form-contato.component.html',
  styleUrls: ['./produto-form-contato.component.scss']
})
export class ProdutoFormContatoComponent implements OnInit {

  @Input("servico") servico = null;
  public dataItem:any       = {};
  public loader             = false;
  public messageLoader      = "Enviando seu contato. Aguarde!"; 
  public message            = {
    data: "",
    class: "",
    show: false
  };

  constructor(
    private api: ApiService,
    private app: AppService,
    private recaptchaV3Service: ReCaptchaV3Service
  ){}

  /***
   * 
   * Envia a mensagem por email
   * 
   * 
   */
  send(){

    try{

      this.loader = true;
      let message = "";

      this.recaptchaV3Service.execute('importantAction')
        .subscribe((token) => {

          this.dataItem.token = token;

          this.api.produto().send_contato(this.dataItem).subscribe(response => {

            this.loader = false;

            switch(response.status){ 
              case 1:
                message  = "Seus dados foram enviados com sucesso.";
                this.app.info(message,"success");
                this.initDataItem();
              break;
              case 0:
                
                let errors = this.app.getErrors([response.data]);
                this.app.info(errors,"danger");

              break;
              default:
                message  = "Houve um erro interno. Tente mais tarde ou informe nossa equipe.";
                this.app.info(message,"danger");
              break;
            }


          },(response) => {

            this.loader        = false;
            let error          = this.app.formatError(response);
            this.app.info(error.message,"danger");

          });

        },(response) => { 

          this.loader   = false;
          this.app.info("Token de validação inválido. Recarregue a página para continuar.");
  
        });

    }catch(e){

      this.loader = false;

    }

  }
  /**
   * 
   * Incializa o dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      nome: "",
      email: "",
      celular: "",
      mensagem: "",
      servico: this.servico 
    }

  }
  /***
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.initDataItem();
  }

}
