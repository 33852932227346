<div class="modulo modulo-rede-social">
  <div [ngSwitch]="template">
    <div *ngSwitchCase="null">
      <div class="modulo-titulo" *ngIf="data.mostrar_titulo">
        <h3>{{data.titulo}}</h3>
      </div>
      <div class="modulo-content modulo-content-footer">
        <ul>
          <li>
            <a href="{{data.params.instagram}}" target="__blank">
              <img defaultImage="/images/pixel/bg.png" lazyLoad="/images/icons/alltour/redes-sociais/instagram.svg" alt="Instagram" />
            </a>  
          </li>
          <li>
            <a href="{{data.params.facebook}}" target="__blank">
              <img defaultImage="/images/pixel/bg.png" lazyLoad="/images/icons/alltour/redes-sociais/facebook.svg" alt="Facebook" />
            </a> 
          </li>  
        </ul>  
      </div>  
    </div> 
    <div *ngSwitchCase="'topo'" class="rede-social-topo">
      <div class="modulo-content">
        <ul>
          <li>
            <a href="{{data.params.facebook}}" target="__blank">
              <img src="/images/icons/sao-paulo-ecoturismo/redes-sociais/facebook.svg" alt="Facebook" />
            </a> 
          </li>
          <li>
            <a href="{{data.params.instagram}}" target="__blank">
              <img src="/images/icons/sao-paulo-ecoturismo/redes-sociais/instagram.svg" alt="Instagram" />
            </a>  
          </li>
        </ul>  
      </div>  
    </div> 
    <div *ngSwitchCase="'page-contato'" class="rede-social-page-contato">
      <div class="modulo-content">
        <h4>NOS ACOMPANHE</h4>
        <ul>
          <li>
            <a href="{{data.params.instagram}}" target="__blank">
              <img src="/images/icons/aextour/redes-sociais/instagram.svg" alt="Instagram" />
            </a>  
          </li>
          <li>
            <a href="{{data.params.facebook}}" target="__blank">
              <img src="/images/icons/aextour/redes-sociais/facebook.svg" alt="Facebook" />
            </a> 
          </li>
        </ul>   
      </div>  
    </div>
    <div *ngSwitchCase="'footer'" class="rede-social-footer modulo-contato-home">
      <div class="modulo-content">
        <ul>
          <li>
            <a href="{{data.params.instagram}}" target="__blank">
              <img defaultImage="/images/pixel/bg.png" lazyLoad="/images/icons/aextour/redes-sociais/instagram-two.svg" alt="Instagram - Aextour" />
            </a>
          </li> 
          <li>
            <a href="{{data.params.facebook}}" target="__blank">
              <img defaultImage="/images/pixel/bg.png" lazyLoad="/images/icons/aextour/redes-sociais/facebook-two.svg" alt="Facebook - Aextour" />
            </a>
          </li>
        </ul>  
      </div>    
    </div>  
    <div *ngSwitchCase="'contato-home'" class="modulo-contato-home">
      <div class="modulo-content">
        <h3>NOS SIGA</h3>
        <h4>nas redes sociais</h4>
        <ul>
          <li>
            <a href="{{data.params.facebook}}" target="__blank">
              <img src="/images/icons/sao-paulo-ecoturismo/redes-sociais/facebook-two.svg" alt="Facebook - São Paulo Ecoturismo" />
            </a>
          </li>
          <li>
            <a href="{{data.params.instagram}}" target="__blank">
              <img src="/images/icons/sao-paulo-ecoturismo/redes-sociais/instagram-two.svg" alt="Instagram - São Paulo Ecoturismo" />
            </a>
          </li> 
          <li>
            <a href="{{data.params.twitter}}" target="__blank" *ngIf="data.params.twitter !== '' && data.params.twitter !== null">
              <img src="/images/icons/ssao-paulo-ecoturismo/redes-sociais/twitter-two.svg" alt="Twitter - São Paulo Ecoturismo" />
            </a>
          </li>   
        </ul>  
      </div>  
    </div>
  </div>  
</div>
