<section class="page page-cart">
    <modulo-loader [message]="messageLoader" *ngIf="loader"></modulo-loader>
    <div class="container-cart">
      <div class="content">
        <div class="page-title" *ngIf="response.status == 1">  
          <div class="page-title-content">
            <div class="container">
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="page-title">
                    <ul>
                      <li>
                        <figure>
                          <img src="/images/icons/alltour/carrinho/mochila.svg" alt="Mochila de Compras - All Tour" />
                        </figure>  
                      </li>
                      <li>  
                        <h1>
                          {{title}} 
                        </h1>
                      </li>
                    </ul>    
                  </div>
                </div>  
              </div>
            </div>
            <div class="linha"></div>  
          </div>
        </div>  
        <section *ngIf="response.status == 1">
          <div class="page-content">
            <div class="container">
              <div class="clearfix"></div>
              <div class="content-cart" *ngIf="response.data != null">
                <div class="servicos" *ngIf="servicos.length > 0">
                  <div class="row">
                    <div class="col-md-12" *ngFor="let servico of servicos">
                      <div class="item">
                        <dm-cart-item-two [item]="servico"></dm-cart-item-two>  
                      </div> 
                    </div>  
                  </div>  
                </div>
                <div class="servicos" *ngIf="servicos.length == 0">
                  <span>Você não possui nenhum serviço no seu carrinho.</span>
                </div>
              </div>
              <div class="content-cart" *ngIf="response.data == null">
                <span>Você não possui nenhum serviço no seu carrinho.</span>
              </div>
              <div class="clearfix"></div>
              <div *ngIf="file != null">
                <div class="content-have-sale">
                  <span>Você tem uma compra que não foi finalizada ainda.</span>
                  <hr>
                  <ul class="list-links">
                    <li>
                      <a routerLink="/carrinho/finalizar-compra" class="btn-custom-two" title="Finalizar a Compra">
                        <span class="text">Finalizar Compra</span>
                      </a>
                    </li>
                    <li> 
                      <btn-abandonar-compra classCustom="btn-custom-two btn-page-cart"></btn-abandonar-compra>
                    </li>  
                  </ul>
                </div>  
              </div>
              <div class="footer">
                <a routerLink="/" class="btn-custom-two btn-continuar float-left" title="Continuar Comprando">
                  <span class="text">Continuar Comprando</span>
                </a>
                <a *ngIf="response.data != null && servicos.length > 0" routerLink="/carrinho/finalizar-compra" class="btn-custom-two btn-finalizar float-right" title="Finalizar a Compra">
                  <span class="text">Finalizar a Compra</span>
                </a>
              </div> 
            </div>  
          </div>   
        </section>
      </div>  
    </div>  
    <section *ngIf="response.status == 500">
      <error-one [message]="response.data" (emitError)="getCart()"></error-one>
    </section> 
</section>
