<div class="modulo modulo-regiao-destaque">
  <div class="modulo-title" *ngIf="data.mostrar_titulo">
    <div class="container">
      <h2>{{data.titulo}}</h2>
    </div>  
  </div> 
  <div class="modulo-content">
    <div class="container">
      <div class="row">
        <div class="col-md-3" *ngFor="let regiao of data.regioes;let i=index;">
          <produto-regiao-item [data]="regiao"></produto-regiao-item>
        </div>  
      </div>  
    </div>
  </div>  
</div>
