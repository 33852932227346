<div class="page page-consultar-reserva">
  <div *ngIf="response.status == 1">
    <div class="container">
      <div class="page-title" *ngIf="page.mostrar_titulo">
        <h1>{{page.titulo}}</h1>
        <h2 *ngIf="page.mostrar_subtitulo">{{page.subtitulo}}</h2>
      </div>
    </div>  
    <div class="page-content">
      <section class="section descricao" *ngIf="page.mostrar_texto">
        <div class="container">
          <article [innerHTML]="page.texto | safeHtml"></article>
        </div>  
      </section> 
      <section class="section form">
        <div class="container">
          <form class="form dm-form" (submit)="getDataFile()" autocomplete="off">
            <ul class="ul-inline">
              <li>
                <label>INFORME O NÚMERO DA RESERVA</label>
                <input type="text" [(ngModel)]="dataItem.file" name="file"  integer  />
              </li> 
              <li>
                <label>INFORME O SEU ÚLTIMO NOME</label>
                <input type="text" [(ngModel)]="dataItem.ultimonome" name="ultimonome"  uppercase  />
              </li>
              <li>
                <button type="submit" class="btn-one">
                  BUSCAR
                </button>  
              </li>  
            </ul>
          </form>  
        </div>   
      </section> 
      <section class="section section-file" *ngIf="dataFile != null"> 
        <div class="container">
          <div class="section-title">
            <h2>DADOS DA RESERVA</h2> 
          </div>  
          <div class="section-content">
            <div class="row row-item">
              <div class="col-md-2 col-6">
                <span>CÓDIGO DA RESERVA</span>
              </div>
              <div class="col-md-10 col-6">
                <strong>{{dataResponse.file.idFile}}</strong>
              </div>  
            </div> 
            <div class="row row-item">
              <div class="col-md-2 col-6">
                <span>TITULAR DA RESERVA</span>
              </div>
              <div class="col-md-10 col-6">
                <strong>{{dataResponse.file.paxTitular}}</strong>
              </div>  
            </div> 
            <div class="row row-item">
              <div class="col-md-2 col-6">
                <span>DATA INICIAL</span>
              </div>
              <div class="col-md-10 col-6">
                <strong>{{dataResponse.file.dataInicio}}</strong>
              </div>  
            </div> 
            <div class="row row-item">
              <div class="col-md-2 col-6">
                <span>DATA FINAL</span>
              </div>
              <div class="col-md-10 col-6">
                <strong>{{dataResponse.file.dataFim}}</strong>
              </div>  
            </div>
            <div class="row row-item">
              <div class="col-md-2 col-6">
                <span>HOTEL</span>
              </div>
              <div class="col-md-10 col-6">
                <strong>{{dataResponse.file.hotel}}</strong>
              </div>  
            </div>
            <div class="row row-item">
              <div class="col-md-2 col-6">
                <span>CIDADE</span>
              </div>
              <div class="col-md-10 col-6">
                <strong>{{dataResponse.file.cidade}}</strong>
              </div>  
            </div>  
          </div>  
        </div> 
      </section> 
      <section class="section section-passageiros" *ngIf="dataFile != null">
        <div class="container">
          <div class="section-title">
            <h2>PASSAGEIROS</h2> 
          </div> 
          <div class="section-content">
              <div class="passageiros-cards" *ngIf="dataResponse.passageiros.length > 0">
                  <div class="row">
                    <div class="col-lg-4 col-md-6" *ngFor="let passageiro of dataResponse.passageiros;let i=index;">
                      <div class="card">
                        <div class="card-title">
                          <div class="card-title-content">
                            <h3>PASSAGEIRO {{i+1}}</h3>
                            <div class="linha"></div>
                          </div>  
                        </div>
                        <div class="card-content">
                          <ul class="ul-inline">
                            <li>
                              <div class="item">
                                <span class="item-text">NOME</span>
                                <span class="item-value">{{passageiro.Pax}}</span> 
                              </div>   
                            </li>  
                          </ul>
                          <ul class="ul-inline">
                            <li>
                              <div class="item">
                                <span class="item-text">TIPO</span>
                                <span class="item-value">{{passageiro.tipoPax}}</span> 
                              </div>   
                            </li>
                            <li>
                              <div class="item">
                                <span class="item-text">DATA NASCIMENTO</span>
                                <span class="item-value">{{passageiro.dataNascimento | empty:"---"}}</span> 
                              </div>   
                            </li> 
                            <li>
                              <div class="item">
                                <span class="item-text">TELEFONE</span>
                                <span class="item-value">{{passageiro.telefoneCelular | empty:"---"}}</span> 
                              </div>   
                            </li>  
                          </ul> 
                          <ul class="ul-inline">
                            <li>
                              <div class="item">
                                <span class="item-text">CPF</span>
                                <span class="item-value">{{passageiro.CPF | empty:"---"}}</span> 
                              </div>   
                            </li>
                            <li>
                              <div class="item">
                                <span class="item-text">DOCUMENTO</span>
                                <span class="item-value">{{passageiro.documento | empty:"---"}}</span> 
                              </div>   
                            </li> 
                          </ul>  
                        </div>  
                      </div>  
                    </div> 
                  </div>  
                </div>  
                <p class="alert alert-info" *ngIf="dataResponse.passageiros.length == 0">
                  Não tem informações dos passageiros para mostrar.
                </p>
          </div>  
        </div>  
      </section>  
      <section class="section section-servicos" *ngIf="dataFile != null">
        <div class="container">
          <div class="section-title">
            <h2>MEUS SERVIÇOS</h2>
          </div>
          <div class="section-content"> 
            <div class="servico-item" *ngFor="let servico of dataResponse.programacao;let i=index;"> 
              <div class="servico-item-titulo">
                <h3>{{servico.nomeServico}}</h3>
              </div>
              <div class="servico-item-content">  
                <ul>
                  <li class="item-value">
                    <span>DATA DO SERVIÇO</span>
                    <strong>{{servico.dataServico}}</strong>
                  </li>
                  <li class="item-value">
                    <span>HORÁRIO</span>
                    <strong>{{servico.horaPickup}}</strong>
                  </li>
                  <li class="item-value">
                    <span>CATEGORIA</span>
                    <strong>{{servico.categoriaServico}}</strong>
                  </li>
                  <li class="item-value">
                    <span>HOTEL / POUSADA</span>
                    <strong>{{servico.localHotelPousada | empty: "---"}}</strong>
                  </li>
                </ul> 
                <ul>
                  <li class="item-value">
                    <span>GUIA</span>
                    <strong>{{servico.Guia | empty: "---"}}</strong>
                  </li>
                  <li class="item-value">
                    <span>MOTORISTA</span>
                    <strong>{{servico.Motorista | empty: "---"}}</strong>
                  </li>
                  <li class="item-value">
                    <span>TIPO DE TRANSPORTE</span>
                    <strong>{{servico.tipoTransporte | empty: "---"}}</strong>
                  </li>
                  <li class="item-value">
                    <span>VEÍCULO</span>
                    <strong>{{servico.Veiculo | empty: "---"}}</strong>
                  </li>
                </ul>
                <div class="servico-passageiros">
                  <div class="servico-passageiros-titulo">
                    <h4>PASSAGEIROS</h4>
                    <button type="button" class="btn-custom-two ver" (click)="showPassageiros(servico)">
                      VER
                    </button>  
                  </div>  
                  <div class="servico-passageiros-content" *ngIf="servico.show_passageiros">
                    <div class="passageiro-item" *ngFor="let passageiro of getPassageirosServico(servico);let i=index;">
                      <span>{{passageiro.Pax}}</span>
                    </div>
                  </div>  
                </div>  
              </div>
            </div>
          </div>  
        </div>  
      </section>  
    </div>  
  </div> 
  <div *ngIf="response.status == 500">
    <div class="container">
      <error-custom title="Não foi possível carregar os dados da Página" [details]="response.data" (reload)="getData()"></error-custom>
    </div>  
  </div>  
</div>
<modulo-loader [message]="messageLoader" [hidden]="!loader"></modulo-loader> 
<modal-info [show]="message.show" [customClass]="message.class" [message]="message.data" (hide)="message.show=false"></modal-info>