<div class="produto-slide-show" *ngIf="fotos.length > 0">
  <div class="produto-slide-show-slider" #slider>
    <div *ngFor="let i of fotos;let y = index;" class="item" itemscope itemtype="http://schema.org/ImageObject">
      <figure> 
        <img [src]="i.imagem" alt="i.alt" class="img-fluid" itemprop="contentUrl" />
        <span style="display:none;" itemprop="description">{{i.alt}}</span>
      </figure>
    </div> 
  </div>
  <div class="swiper-button-prev sw-btn" (click)="prevSlide()">
    <i class="fal fa-long-arrow-left"></i>
  </div>
  <div class="swiper-button-next sw-btn" (click)="nextSlide()">
    <i class="fal fa-long-arrow-right"></i>
  </div>  
  <div class="produto-slide-show-nav" #sliderNav>
    <div *ngFor="let i of fotos;let y = index;" class="item" itemscope itemtype="http://schema.org/ImageObject">
      <figure> 
        <img [src]="i.imagem" alt="i.alt" class="img-fluid" itemprop="contentUrl" />
        <span style="display:none;" itemprop="description">{{i.alt}}</span>
      </figure>
    </div>
  </div>  
</div>
<div class="produto-slide-show" *ngIf="fotos.length == 0">
  <div class="content">
    <figure>
      <img src="/images/produto/sem-foto/alltour.jpg" alt="All Tour" class="img-fluid" />
    </figure>  
  </div> 
</div>
